import React, { Suspense, useContext } from 'react';
import { IonAlert, IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Login from './pages/Login';
import Home from './pages/Home';
import Scan from './pages/Service/Scan';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import CreateComp from './pages/Component/CreateComp';
import CreateMainten1 from './pages/Maintenance/CreateMainten1';
import CompDetails from './pages/Component/CompDetails';
import ServiceDetail from './pages/Service/ServiceDetail';
import CompCatalogue from './pages/Component/CompCatalogue';
import Assets from './pages/Assets/HomeAsset';
import Profile from './pages/Profile';
import MaintenanceDetail from './pages/Maintenance/MaintenanceDetail';
import CreateAsset from './pages/Assets/CreateAsset';
import NoRecFound from './pages/NoRecFound';
import MaintenaceHistory from './pages/Maintenance/MaintenanceHistory';
import MaintenanceHome from './pages/Maintenance/MaintenanceHome';
import Offline from './pages/Offline';
import ServiceHome from './pages/Service/ServiceHome';
import ServiceHistory from './pages/Service/ServiceHistory';
import PrivateRoute from './components/PrivateRoute';
import AssetDet from './pages/Assets/ViewAsset';
import AllAssets from './pages/Assets/ShowAsset';
import MenuItems from './components/MenuItem';
import usePWA from 'react-pwa-install-prompt';
import ComponentDetail from './pages/Component/componentDetail';
import SmartScan from './pages/SmartScan';
import Survey from './pages/Survey/CreateSurvey';
import SurveyHome from './pages/Survey/HomeSurvey';
import ShowSurvey from './pages/Survey/ShowSurvey';
import ViewSurvey from './pages/Survey/ViewSurvey';
import CreateProject from './pages/Project/CreateProject';
import HomeProject from './pages/Project/HomeProject';
import ViewAllProjects from './pages/Project/ViewAllProjects';

import NotFound from './components/NotFound';
import { Redirect, Route } from 'react-router';
import Loading from './components/Loading';
import { AppContext } from './providers/AppContext';
import { useTranslation } from 'react-i18next';
import AppUrlListener from './AppUrlListener';
import ViewProject from './pages/Project/ViewProject';
import CreateTask from './pages/Project/CreateTask';
import ViewlAllTask from './pages/Project/ViewAllTask';
import ViewTask from './pages/Project/ViewTask';
// import CacheBuster from './CashBuster';
import ModelHome from './pages/Model937/home';
import ModelHistory from './pages/Model937/history';
import ModelDetail from './pages/Model937/detail';
import ModelCreate from './pages/Model937/create';
import ResetPassword from './pages/ResetPassword';
import ServiceCreate from './pages/Service/ServiceManagement';

const App: React.FC = () => {
  // enable for mobile view only
  const deviceInfo = async () => {
    // const info = await Device.getInfo();
    // if (info.operatingSystem === 'unknown') {
    // }
    // if (info.operatingSystem === 'android') {
      // const currentV = await AppUpdate.getAppUpdateInfo();
      // const availableV = await AppUpdate.getAppUpdateInfo();
      // await AppUpdate.openAppStore();
      // const result = await AppUpdate.getAppUpdateInfo();
    // }
  };
  // deviceInfo();

  const { dispatch, state } = useContext(AppContext);
  const { t } = useTranslation();

  // show pwa prompt
  const { isStandalone, isInstallPromptSupported, promptInstall } = usePWA();
  const onClickInstall = async () => {
    await promptInstall();
  };
  if (isInstallPromptSupported && isStandalone) {
    onClickInstall();
  }

  return (
    <IonApp>
      <IonReactRouter>
          return (
             <Suspense fallback={Loading}>
          <MenuItems />
          <IonAlert
            isOpen={state.showOfflineSaveMessage}
            onDidDismiss={() => dispatch({ type: 'toggleOfflineSave', action: {} })}
            header={t('Utils.offline_save_header')}
            message={t('Utils.offline_save_message')}
            buttons={[t('Utils.okay')]}
          />
          <AppUrlListener></AppUrlListener>
          <IonRouterOutlet id="main">
            <PrivateRoute component={ServiceCreate} path="/serviceCreate" exact />
            <PrivateRoute component={MaintenaceHistory} path="/maintenanceHistory" exact />
            <PrivateRoute component={MaintenanceHome} path="/maintenanceHome" exact />
            <PrivateRoute component={Offline} path="/offline" exact />
            <PrivateRoute component={CreateAsset} path="/createAsset" exact />
            <PrivateRoute component={NoRecFound} path="/noRecFound" exact />
            <PrivateRoute component={MaintenanceDetail} path="/maintenanceDetail" exact />
            <PrivateRoute component={Home} path="/home" exact />
            <PrivateRoute component={Assets} path="/assets" exact />
            <PrivateRoute component={AllAssets} path="/all" exact />
            <PrivateRoute component={AssetDet} path="/assetDetail" />
            <PrivateRoute component={CreateComp} path="/createComp" exact />
            <PrivateRoute component={ComponentDetail} path="/comDetail" exact />
            <PrivateRoute component={CompCatalogue} path="/compCatalogue" exact />
            <PrivateRoute component={CreateMainten1} path="/createMainten1" exact />
            <PrivateRoute component={Profile} path="/profile" exact />
            <PrivateRoute component={CompDetails} path="/compDetails" exact />
            <PrivateRoute component={ServiceDetail} path="/serviceDetail" exact />
            <PrivateRoute component={ServiceHome} path="/servicehome" exact />
            <PrivateRoute component={Scan} path="/scan" exact />
            <PrivateRoute component={ServiceHistory} path="/serviceHistory" exact />
            <PrivateRoute component={MaintenanceDetail} path="/maintainanceDetail" exact />
            <PrivateRoute component={SmartScan} path="/smartScan" exact />
            <PrivateRoute component={Survey} path="/create-survey" exact />
            <PrivateRoute component={ShowSurvey} path="/show-survey" exact />
            <PrivateRoute component={SurveyHome} path="/survey-home" exact />
            <PrivateRoute component={ViewSurvey} path="/view-survey" exact />
            <PrivateRoute component={CreateProject} path="/create-project" exact />
            <PrivateRoute component={HomeProject} path="/project" exact />
            <PrivateRoute component={ViewAllProjects} path="/project-list" exact />
            <PrivateRoute component={ViewProject} path="/view-project" exact />
            <PrivateRoute component={CreateTask} path="/create-task" exact />
            <PrivateRoute component={ViewlAllTask} path="/view-all-task" exact />
            <PrivateRoute component={ViewTask} path="/view-task" exact />
            <PrivateRoute component={ModelHome} path="/model-home" exact />
            <PrivateRoute component={ModelHistory} path="/model-history" exact />
            <PrivateRoute component={ModelDetail} path="/model-detail" exact />
            <PrivateRoute component={ModelCreate} path="/create-model" exact />

            <Route
              exact
              path="/login"
              render={() => {
                return state.isLoggedIn ? <Redirect to="/" /> : <Route component={Login} path="/login" />;
              }}
            />
             <Route
              component={ResetPassword}
              exact
              path="/reset-password"
            />
            <Redirect
              exact
              from="/"
              to={state.isLoggedIn ? (state.permissions.home ? '/home' : '/survey-home') : '/login'}
            />
            <Route component={NotFound} />
          </IonRouterOutlet>
        </Suspense>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
