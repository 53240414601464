/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from 'react';
import {
  IonContent,
  IonPage,
  IonItem,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonSkeletonText,
  IonRow,
  IonCardContent,
} from '@ionic/react';
import Header from '../../components/Header';
import AssetButton from '../../components/ScanButton';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import Red from '../../assets/Red.svg';
import { addCircleOutline, bagAddOutline, calendarOutline, extensionPuzzleOutline, location, shapesOutline } from 'ionicons/icons';
import NoRecords from '../../components/NoRecords';

const HomeProject: React.FC = () => {
  const { t } = useTranslation();

  const [highlights, setHighlights] = useState([]);
  const [loader, setLoader] = useState(false);


  const getData = async () => {
    let url = '/method/preflet_asset.api.get_assigned_projects'
    var config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    try {
      setLoader(true);
      const res = await axios(config)
      setHighlights(res.data.message);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <IonPage>
      <Header enableBackButton="" title="" />
      <IonContent>
        <div style={{ display: 'flex', margin: 'auto', paddingTop: '2rem', marginLeft: '12vw', marginRight: '12vw' }}>
          <Link to="/create-project" style={{ textDecoration: 'none' }}>
            <section
              style={{
                width: '23vw',
                height: '6rem',
                marginLeft: '5px',
                marginRight: '5px',
                textAlign: 'center',
                boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
              }}
            >
              <IonIcon src={bagAddOutline} style={{ paddingTop: '1rem', fontSize: '2rem' }} />
              <p style={{ margin: 'auto', color: 'rgba(29, 80, 141, 1)', marginTop: '2px', fontSize: '11px' }}>
                {t('Project.createProject')}
              </p>
            </section>
          </Link>
          <Link to="/create-task" style={{ textDecoration: 'none' }}>
            <section
              style={{
                width: '23vw',
                height: '6rem',
                marginLeft: '5px',
                marginRight: '5px',
                textAlign: 'center',
                boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
              }}
            >
              <IonIcon src={addCircleOutline} style={{ paddingTop: '1rem', fontSize: '2rem' }} />
              <p style={{ margin: 'auto', color: 'rgba(29, 80, 141, 1)', marginTop: '12px', fontSize: '11px' }}>
                {t('Project.createTask')}
              </p>
            </section>
          </Link>

          <Link to="/view-all-task" style={{ textDecoration: 'none' }}>
            <section
              style={{
                width: '23vw',
                height: '6rem',
                marginLeft: '5px',
                marginRight: '5px',
                textAlign: 'center',
                boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
              }}
            >
              <IonIcon src={extensionPuzzleOutline} style={{ paddingTop: '1rem', fontSize: '2rem' }} />
              <p style={{ margin: 'auto', color: 'rgba(29, 80, 141, 1)', marginTop: '12px', fontSize: '11px' }}>
                {t('Project.viewTasks')}
              </p>
            </section>
          </Link>

        </div>
        <div className="flex justify-between w-50 data-list" style={{ marginTop: '1rem' }}>
          <IonItem lines="none" className="heading">
            <p>{t('Project.projects')}</p>
          </IonItem>
          <Link to="/project-list" style={{ textDecoration: 'underline' }}>
            <IonItem lines="none" className="heading">
              <p style={{ color: 'rgba(29, 80, 141, 1)' }}> {t('Project.viewAll')}</p>
            </IonItem>
          </Link>
        </div>
        {loader &&
          [1, 2, 3, 4, 5, 6, 7, 8, 9].map((element) => {
            return (
              <div
                key={element}
                id="shimmer-body"
                style={{ padding: '1rem' }}
                className="w-50 data-list shimmer-container"
              >
                <div className="flex justify-between">
                  <IonSkeletonText animated style={{ width: '50%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
                <IonSkeletonText animated style={{ width: '30%' }} />
              </div>
            );
          })}

        <div className="w-50 data-list">
          {highlights &&
            highlights.map((element: any, key: number) => {
              return (
                <IonCard id="card-container" key={key}>
                  <IonCardHeader>
                    <IonCardTitle className="card-heading">
                      <div className="flex justify-between items-center">
                        <Link className="underline cursor-pointer" to={{ pathname: '/view-project', state: element.name }}>
                          <span>{element.project_name}</span>
                        </Link>
                      </div>
                    </IonCardTitle>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
                        {element.status === 'Open' ? <img src={Red} alt="Red" style={{ marginRight: '5px' }} />
                          : <div style={{ marginRight: '5px', height: '0.64rem', width: '0.64rem', backgroundColor: 'green', borderRadius: '999px' }}></div>}
                        <span>{t(`Project.Status.${element.status}`)}</span>
                      </div>
                      {element.location && <span style={{ display: 'flex', alignItems: 'center' }}> <IonIcon style={{ marginLeft: '2px' }} src={location} />{element.location}</span>}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                      {element.expected_start_date || element.expected_end_date ? <IonIcon src={calendarOutline} style={{ marginRight: '5px' }} /> : null}
                      {element.expected_start_date && <p style={{ marginRight: '5px' }}> Start: {element.expected_start_date} |   </p>}
                      {element.expected_end_date && <p> End: {element.expected_end_date}</p>}
                    </div>
                  </IonCardHeader>
                  <IonCardContent>
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                      <IonIcon style={{ marginRight: '4px' }} src={shapesOutline} />
                      <p>Task : {element.tasks}</p>
                    </div>
                  </IonCardContent>
                </IonCard>
              );
            })}
          {highlights.length > 0 ? <a style={{margin:'2rem',display: 'block'}} className="text-center underline pointer" href="/project-list">{t('Utils.viewAll')}</a> : ''}
        </div>
        {!loader && highlights.length === 0 ? (
          <IonRow>
            <NoRecords width="30%" smallText={true} />
          </IonRow>
        ) : (
          <div></div>
        )}
        <AssetButton />
      </IonContent>
    </IonPage>
  );
};

export default HomeProject;
