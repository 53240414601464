export const createModel937 = [
    {
        title: 'informação_básica_section',
    },
    {
        key: 'nome',
        type: 'text',
        value: '',
        required: true,
        disabled: false
    },
    {
        key: 'asset',
        type: 'select',
        value: null,
        options: [],
    },
    {
        key: 'project',
        type: 'select',
        value: null,
        options: [],
    },
    {
        key: 'period',
        type: 'select',
        value: null,
        options: ['Anual'],
    },
    {
        title: '_blank',
    },
    {
        key: 'regional_entity',
        type: 'text',
        value: '',
    }, {
        key: 'checkbox1',
        type: 'select',
        value: false,
        options: ['Sim', 'Não']
    }, {
        key: 'checkbox2',
        type: 'select',
        value: false,
        options: ['Sim', 'Não']
    }, {
        key: 'checkbox3',
        type: 'select',
        value: false,
        options: ['Sim', 'Não']
    }, {
        key: 'target_start_period',
        type: 'date',
        value: '',
    }, {
        key: 'target_end_period',
        type: 'date',
        value: '',
    }, {
        key: 'referências_1',
        type: 'text',
        value: '',
    }, {
        key: 'referências_2',
        type: 'text',
        value: '',
    }, {
        key: 'referências_3',
        type: 'text',
        value: '',
    }, {
        key: 'referências_4',
        type: 'text',
        value: '',
    }, {
        key: 'energy_entity_id',
        type: 'text',
        value: '',
    }, {
        title: 'inspecções_efectuadas_section',
    }, {
        key: 'legal_provision',
        type: 'text',
        value: '',
    }, {
        key: 'actual_start_period',
        type: 'date',
        value: '',
    }, {
        key: 'actual_end_period',
        type: 'date',
        value: '',
    }, {
        title: 'subestações_postos_de_transformação_e_de_corte_section',
    }, {
        title: 'ensaios_de_medições_section',
    },
    {
        key: 'resistência_da_terra_de_serviço__ohm_ω',
        type: 'number',
        value: '',
    },
    {
        key: 'impedância_do_circuito_de_defeito__ohm_ω',
        type: 'number',
        value: '',
    },
    {
        key: 'resistência_de_isolamento',
        type: 'text',
        value: '',
    },
    {
        key: 'acidity_and_stiffness_of_oils',
        type: 'text',
        value: '',
    },
    {
        key: 'factor_de_potência_cos_φ',
        type: 'number',
        value: '',
    },
    {
        key: 'outros_ensaios_e_medições',
        type: 'text',
        value: '',
    },
    {
        title: 'checks_by_observations_of_the_installation',
    },
    {
        title: '1.2.1',
    },
    {
        key: 'o_nível121',
        type: 'select',
        value: null,
        options: ['Sim', 'Não'],
    },
    {
        key: 'tendo_detectado121',
        type: 'select',
        value: null,
        options: ['quaisquer', 'outro'],
    },
    {
        key: 'deficiência121',
        type: 'text',
        value: '',
    },
    {
        title: '1.2.2'
    },
    {
        key: 'o_estad_dos122',
        type: 'select',
        value: null,
        options: ['Sim', 'Não'],
    },
    {
        key: 'tendo_detectado122',
        type: 'select',
        value: null,
        options: ['quaisquer', 'outro'],
    },
    {
        key: 'deficiência122',
        type: 'text',
        value: '',
    },
    {
        title: '1.2.3',
    },
    {
        key: 'os_circuitos_de_terra123',
        type: 'select',
        value: null,
        options: ['Sim', 'Não'],
    },
    {
        key: 'tendo_detectado123',
        type: 'select',
        value: null,
        options: ['quaisquer', 'outro'],
    },
    {
        key: 'deficiência123',
        type: 'text',
        value: '',
    },
    {
        title: '1.2.4',
    },
    {
        key: 'o_estado_de_conservação_dos124',
        type: 'select',
        value: null,
        options: ['Sim', 'Não'],
    },
    {
        key: 'tendo_detectado124',
        type: 'select',
        value: null,
        options: ['quaisquer', 'outro'],
    },
    {
        key: 'deficiência124',
        type: 'text',
        value: '',
    },
    {
        title: 'heading125',
    },
    {
        key: 'a_carga_do_transformador',
        type: 'select',
        value: null,
        options: ['Sim', 'Não'],
    },
    {
        key: 'tendo_detectado125',
        type: 'select',
        value: null,
        options: ['quaisquer', 'outro'],
    },
    {
        key: 'deficiência125',
        type: 'text',
        value: '',
    },
    {
        title: 'heading126',
    },
    {
        key: 'o_estado_de_funcionamento126',
        type: 'select',
        value: null,
        options: ['Sim', 'Não'],
    },
    {
        key: 'tendo_detectado126',
        type: 'select',
        value: null,
        options: ['quaisquer', 'outro'],
    },
    {
        key: 'deficiência126',
        type: 'text',
        value: '',
    },
    {
        title: 'heading127',
    },
    {
        key: 'outras_verificações127',
        type: 'select',
        value: null,
        options: ['Sim', 'Não'],
    },
    {
        title: 'heading2',
    },
    {
        key: 'installations_for_use2',
        type: 'select',
        value: null,
        options: ['TT', 'TN', 'IT'],
    },
    {
        title: 'ensaios_de_medições21',
    },
    {
        key: 'resistência_da_terra_de211',
        type: 'number',
        value: '',
    },
    {
        key: 'impedância_do_circuito212',
        type: 'number',
        value: '',
    },
    {
        key: 'resistência_de_isolamento213',
        type: 'number',
        value: '',
    },
    {
        key: 'protecções_contra_contactos214',
        type: 'text',
        value: '',
    },
    {
        key: 'outros_ensaios_e_medições215',
        type: 'text',
        value: '',
    },
    {
        title: 'heading62',
    },
    {
        title: 'heading221',
    },
    {
        key: 'os_aparelhos_de_protecção_contra_sobreintensidade221',
        type: 'select',
        value: null,
        options: ['Sim', 'Não'],
    },
    {
        key: 'tendo_detectado221',
        type: 'select',
        value: null,
        options: ['quaisquer', 'outro'],
    },
    {
        key: 'deficiência221',
        type: 'text',
        value: '',
    },
    {
        title: 'heading222',
    },
    {
        key: 'a_eficácia_das_protecções_contra_contactos_indirecto222',
        type: 'select',
        value: null,
        options: ['Sim', 'Não'],
    },
    {
        key: 'tendo_detectado222',
        type: 'select',
        value: null,
        options: ['quaisquer', 'outro'],
    },
    {
        key: 'deficiência222',
        type: 'text',
        value: '',
    },
    {
        title: 'heading223',
    },
    {
        key: 'o_aquecimento_e_o_estado223',
        type: 'select',
        value: null,
        options: ['Sim', 'Não'],
    },
    {
        key: 'tendo_detectado223',
        type: 'select',
        value: null,
        options: ['quaisquer', 'outro'],
    },
    {
        key: 'deficiência223',
        type: 'text',
        value: '',
    },
    {
        title: 'heading224',
    },
    {
        key: 'o_estado_dos_aparelhos_de_corte_e_de_comando224',
        type: 'select',
        value: null,
        options: ['Sim', 'Não'],
    },
    {
        key: 'tendo_detectado224',
        type: 'select',
        value: null,
        options: ['quaisquer', 'outro'],
    },
    {

        key: 'deficiência224',
        type: 'text',
        value: '',
    },
    {
        title: 'heading225',

    },
    {
        key: 'o_estado_dos_aparelhos_de_utilização225',
        type: 'select',
        value: null,
        options: ['Sim', 'Não'],
    },
    {
        key: 'tendo_detectado225',
        type: 'select',
        value: null,
        options: ['quaisquer', 'outro'],

    },
    {
        key: 'deficiência225',
        type: 'text',
        value: '',
    },
    {
        title: 'instalações_de_emergência226',
    },
    {
        title: 'heading2_2_6_1',
    }, {
        key: 'as_condições_de2261',
        type: 'select',
        value: null,
        options: ['Sim', 'Não'],
    },
    {
        key: 'tendo_detectado2261',
        type: 'select',
        value: null,
        options: ['quaisquer', 'outro'],
    },
    {
        key: 'deficiência2261',
        type: 'text',
        value: '',
    },
    {

        title: 'heading2262',
    },
    {
        key: 'o_estado_das_baterias_nomeadamente_o_seu_electrólito2262',
        type: 'select',
        value: null,
        options: ['Sim', 'Não'],
    },
    {
        key: 'tendo_detectado2262',
        type: 'select',
        value: null,

        options: ['quaisquer', 'outro'],
    },
    {
        key: 'deficiência2262',
        type: 'text',
        value: '',
    },
    {
        title: 'heading2263',
    },
    {
        key: 'o_estado_de_funcionamento_dos_blocos_autónomos2263',
        type: 'select',
        value: null,
        options: ['Sim', 'Não'],
    },
    {
        key: 'tendo_detectado2263',
        type: 'select',
        value: null,
        options: ['quaisquer', 'outro'],
    },
    {
        key: 'deficiência2263',
        type: 'text',
        value: '',
    },
    {
        title: 'heading227',
    },
    {
        title: 'heading2_2_7_1',
    },
    {
        key: 'durante_inspection227',
        type: 'text',
        value: '',
    },
    {
        title: 'heading228',
    },
    {
        key: 'apercebi_me_das228',
        type: 'text',
        value: '',
    },
    {
        title: 'heading229',
    },
    {
        key: 'a_inexistência_dos229',
        type: 'text',
        value: '',
    },
    {
        title: 'heading2210',
    },
    {
        key: 'a_existência_de2210',
        type: 'text',
        value: '',
    },
    {
        title: 'heading2_2_11',
    },
    {
        title: 'heading2_2_11_1',
    },
    {
        key: 'devido_and_utilize2_2_11',
        type: 'text',
        value: '',
    },
    {
        title: 'heading2_2_11_2',
    },
    {
        title: '2_2_12_1',
    },
    {
        key: 'data2_2_12',
        type: 'text',
        value: '',
    },
    {
        title: 'heading2_2_13',
    },
    {
        key: 'outros_factos2_2_13',
        type: 'text',
        value: '',
    },
    {
        title: 'heading3',
    },
    {
        key: 'outras_instalações3',
        type: 'text',
        value: '',
    },
    {
        title: 'heading4',
    },
    {
        title: 'heading4_1',
    },
    {
        title: 'heading4_2'
    },
    {
        key: 'data_137',
        type: 'text',
        value: '',
    },
    {
        title: 'heading5',
    },
    {
        key: 'modificações_e_ampliações5',
        type: 'text',
        value: '',
    },
    {
        title: 'heading6',
    },
    {
        key: 'anexos6',
        type: 'text',
        value: '',
    },
    {
        title: 'heading7',
    },
    {
        key: 'data7',
        type: 'date',
        value: '',
    },
    {
        title: 'heading8',
    },
    {
        key: 'o_técnico_responsáve',
        type: 'signature',
        value: '',
    }
];