import { IonContent, IonPage, useIonToast, useIonViewWillEnter, IonLoading, useIonRouter, IonIcon, IonModal, IonHeader, IonToolbar, IonButtons, IonButton } from '@ionic/react';
import { useState, useEffect, useContext } from 'react';
import Header from '../../components/Header';
import ScanAsset from '../../assets/ScanAsset.svg';
import axios from '../../axios';
import { useTranslation } from 'react-i18next';
import { Network } from '@capacitor/network';
import Scan from '../../components/Scan';
import { createAssetomponent } from '../../models/createAssetComponent';
import { MenuItem, Select } from '@material-ui/core';
import {
  createFormJsonData,
  getSetComponentCategory,
  getSetComponentType,
  offlineSaveToTable,
  resetForm,
} from '../../utils';
import { AppContext } from '../../providers/AppContext';
import { barcodeOutline, informationCircle } from 'ionicons/icons';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';

const CreateComp: React.FC = () => {

  const [isScan, setIsScan] = useState(false);

  const [showLoading, setShowLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(Boolean);

  const [data, setData] = useState(createAssetomponent);

  const { dispatch } = useContext(AppContext);

  const handleChange = (event: React.ChangeEvent<{ value: unknown; id: any }>) => {
    var formentry: any = [...data];
    const f_index = formentry.findIndex((v: any) => v.field === event.target.id);
    var change_form_object = formentry[f_index];
    change_form_object.value = event.target.value;
    formentry[f_index] = change_form_object;
    setData(formentry);
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    var formentry: any = [...data];
    // @ts-ignore
    const select = event.target.name.replaceAll('-', '_');
    const f_index = formentry.findIndex((v: any) => v.field === select);
    formentry[f_index].value = event.target.value;
    setData(formentry);
  };

  const router = useIonRouter();
  const [present] = useIonToast();

  const { t } = useTranslation();
  const [scanToggle, setScanToggle] = useState(false);


  const url = '/resource/Asset Components';

  useIonViewWillEnter(async () => {
    // get component category
    var Data = [...data];
    const ccat = Data.findIndex((d) => d.field === 'component_category');
    // @ts-ignore
    Data[ccat].options = await getSetComponentCategory();

    // get component type
    const ctype = Data.findIndex((d) => d.field === 'component_type');
    Data[ctype].options = await getSetComponentType();

    setData(Data);
  });

  useEffect(() => {
    (async () => {
      const internetStatus = await Network.getStatus();
      setIsOnline(internetStatus.connected);

      Network.addListener('networkStatusChange', (status) => {
        setIsOnline(status.connected);
      });
    })();
  }, []);

  // Mapping fields after scan
  //  const callComponentById = () => {
  //   axios({
  //     method: 'get',
  //     url: '/resource/Asset Components/PROJ-0001-TASK-2021-00004-00010_Poste betão_00125',
  //     headers: {
  //       Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
  //     },
  //   })
  //     .then((res) => {
  //       const temp = res.data.data
  //       for(const x in createAssetomponent){
  //         const f = createAssetomponent[x].field
  //         if(f === 'component_name'){
  //           createAssetomponent[x].value = temp['component_category']
  //         } else if(f){
  //           createAssetomponent[x].value = temp[f]
  //         }
  //       }
  //       console.log(createAssetomponent)
  //       setData(createAssetomponent)
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  const Submit = (e: any) => {
    e.preventDefault();
    let valid = true;
    for (const x in data) {
      if (data[x].required && !data[x].value) {
        console.log(data[x].requiredText, data[x].value);
        present({
          message: data[x].requiredText,
          color: 'danger',
          duration: 2000,
        });
        valid = false;
      }
    }
    if (valid) {
      const api_data = createFormJsonData(data);
      //@ts-ignore
      api_data.external_component = parseInt(api_data.external_component);
      var config: any = {
        method: 'post',
        url,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
        },
        data: api_data,
      };

      setShowLoading(true);

      if (isOnline) {
        axios(config)
          .then((res) => {
            if (res.status === 200) {
              present({
                message: t('CreateAC.createAc'),
                duration: 2000,
              });
              setData(resetForm(data));
              router.goBack();
            }
          })
          .catch(function (error) {
            console.log(error.response.data);
            const x = error.response.data._server_messages;
            console.log(x.slice(18).slice(0, x.slice(18).indexOf('\\')));
            present({
              message: x.slice(18).slice(0, x.slice(18).indexOf('\\')),
              color: 'danger',
              duration: 2000,
            });
          })
          .finally(() => {
            setShowLoading(false);
          });
      } else {
        // save data in storage
        offlineSaveToTable(api_data, url, 'Component');
        dispatch({ type: 'toggleOfflineSave', payload: {} });
        setData(resetForm(data));
        router.goBack();
      }
    }
  };

  const getScan = () => {
    setScanToggle(true);
  };

  const handleScan = (code: any) => {
    if (code) {
      try {
        var formentry: any = [...data];
        const f_index = formentry.findIndex((v: any) => v.field === 'barcode_id');
        var change_form_object = formentry[f_index];
        change_form_object.value = code;
        formentry[f_index] = change_form_object;
        setData(formentry);
      } catch (error) {
        console.log(error);
      }
      finally {
        setScanToggle(false);
      }
    }
  }



  return (
    <IonPage>
      <Header enableBackButton='goBack' title={t('Assets.CreateA.assetC')} />
      <IonContent>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={t('Utils.pleasewait')}
          duration={5000}
        />

        <div style={{ display: 'flex', margin: 'auto', paddingTop: '2rem' }}>
          <button
            onClick={() => setIsScan(true)}
            style={{
              margin: 'auto',
              background: 'transparent',
              boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
              borderRadius: '1rem',
            }}
          >
            <img src={ScanAsset} alt="scan" style={{ paddingTop: '1rem' }} />
            <p style={{ margin: 'auto', color: 'rgba(29, 80, 141, 1)', marginTop: '2px', fontSize: '11px' }}>
              {t('Assets.Scan')}
            </p>
          </button>
          {isScan ? <Scan setIsScan={setIsScan} /> : null}
        </div>

        <p
          style={{
            margin: 'auto',
            color: 'rgba(36, 54, 86, 1)',
            marginTop: '2rem',
            fontSize: '11px',
            opacity: '0.5',
            textAlign: 'center',
          }}
        >
          {t('Assets.CreateA.Fill')}.
        </p>

        <form className="ion-margin-top" style={{ textAlign: 'center' }} onSubmit={(e) => Submit(e)}>
          {data.map((form_field, index) => {
            if (form_field.title) {
              return (
                <p className="form-title" key={`${form_field.title}-${index}`}>
                  {t(form_field.title)}
                </p>
              );
            } else if (form_field.type === 'text') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(form_field.label)}
                    {form_field.required ? '*' : null}
                  </label>
                  <input
                    required={form_field.required}
                    className="basic-input"
                    id={form_field.field}
                    placeholder={t(form_field.label)}
                    onChange={handleChange}
                    value={form_field.value}
                  />
                </div>
              );
            } else if (form_field.type === 'textarea') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(form_field.label)}
                    {form_field.required ? '*' : null}
                  </label>
                  <textarea
                    className="textarea"
                    required={form_field.required}
                    id={form_field.field}
                    placeholder={t(form_field.label)}
                    onChange={handleChange}
                    value={form_field.value}
                  />
                </div>
              );
            } else if (form_field.type === 'select') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(form_field.label)}
                    {form_field.required ? '*' : null}{' '}
                  </label>
                  {form_field.info ? (
                    <div className="tooltip">
                      <IonIcon style={{ marginBottom: '-4px' }} src={informationCircle} />
                      <span className="tooltiptext">{t(`Utils.${form_field.info}`)}</span>
                    </div>
                  ) : null}
                  <Select
                    className="custom-select"
                    required={form_field.required}
                    id={`${form_field.field}_choice`}
                    name={form_field.field}
                    onChange={handleSelectChange}
                    value={form_field.value}
                  >
                    {form_field.options &&
                      form_field.options.map(function (opt, indexSelect) {
                        return (
                          <MenuItem
                            value={`${opt}`}
                            key={`${form_field.field}-${indexSelect}`}
                            id={`${opt}_item_${indexSelect}`}
                          >
                            <section style={{ display: 'flex', height: '1rem' }}>
                              <p
                                style={{
                                  color: 'rgba(31, 87, 148, 1)',
                                  marginLeft: '0.2rem',
                                  fontSize: '12px',
                                  marginTop: '0px',
                                }}
                              >
                                {t(opt)}
                              </p>
                            </section>
                          </MenuItem>
                        );
                      })}
                  </Select>
                </div>
              );
            } else if (form_field.type === 'date') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(form_field.label)}
                    {form_field.required ? '*' : null}
                  </label>
                  <input
                    required={form_field.required}
                    className="date"
                    id={form_field.field}
                    placeholder={t(form_field.label)}
                    onChange={handleChange}
                    value={form_field.value}
                    type="date"
                  />
                </div>
              );
            }
            else if (form_field.type === 'barcode') {
              return (
                <div
                  id={`${form_field.field}-${index}`}
                  className="input-container"
                  key={`${form_field.field}-${index}`}
                >
                  <label id={`label-${form_field.field}`} style={{ marginTop: '1rem' }} className="label">
                    {t(`Assets.CreateA.${form_field.field}`)}
                    {form_field.required ? '*' : null}{' '}
                  </label>
                  <div className="flex items-center justify-between basic-input">
                    <input
                      required={form_field.required}
                      className="gps-input"
                      id={form_field.field}
                      placeholder={t(`Assets.CreateA.${form_field.field}`)}
                      value={form_field.value}
                      disabled={true}
                      style={{width: '80%'}}
                    />
                    <IonIcon onClick={() => getScan()} className="gps-icon" src={barcodeOutline} />
                  </div>
                  <IonModal isOpen={scanToggle} onDidDismiss={() => setScanToggle(false)}>
                    <IonHeader>
                      <IonToolbar color="primary">
                        <IonButtons slot="end">
                          <IonButton
                            className="line-button"
                            onClick={() => {
                              setScanToggle(false);
                            }}
                          >
                            <IonIcon name="close" slot="icon-only"></IonIcon>
                          </IonButton>
                        </IonButtons>
                      </IonToolbar>
                    </IonHeader>
                    <BarcodeScannerComponent
                      torch={true}
                      onUpdate={(err, result) => {
                        //@ts-ignore
                        if (result && result.text) handleScan(result.text);
                      }}
                    />
                    <div className="submit-button" style={{ textAlign: 'center' }} onClick={() => setScanToggle(false)}>
                      {t('Utils.cancel')}
                    </div>
                  </IonModal>
                </div>
              );
            }

            else {
              return '';
            }
          })}
          <button className="submit-button" type="submit">
            {t('CreateAC.CAC')}
          </button>
        </form>
        <div style={{ marginBottom: '1rem' }}></div>
      </IonContent>
    </IonPage>
  );
};

export default CreateComp;
