export const createAssetFields = [
  {
    title: 'Maintenance.CreateM.PrDet',
  },
  {
    field: 'asset_name',
    required: true,
    type: 'text',
    value: '',
    requiredText: 'Asset Name is required',
  },
  {
    field: 'status',
    required: true,
    type: 'select',
    options: [
      'Installed',
      'Operational',
      'Installed But Not Operational',
      'In Progress',
      'Need Replacement',
      'Need Repairing',
      'Replaced',
      'Need Installation',
      'Need Maintenance And Repair',
      'Inactive'
    ],
    value: '',
    requiredText: 'Please select Status',
    translate: true,
  },
  {
    field: 'asset_category',
    required: false,
    type: 'select',
    options: [],
    value: '',
    requiredText: 'Please select Asset Category',
    info: 'compInfo',
    translate: false,
  },
  {
    field: 'asset_type',
    required: false,
    type: 'select',
    options: [],
    value: '',
    requiredText: 'Please select Asset Type',
    info: 'compInfo',
    translate: false,
  },

  {
    field: 'installation_datetime',
    required: false,
    type: 'datetime-local',
    value: '',
    requiredText: 'Please specify Installation Date',
  },
  {
    field: 'asset_owner',
    required: true,
    type: 'select',
    options: ['Company', 'Supplier', 'Customer'],
    value: '',
    requiredText: 'Please select Asset Owner required',
    translate: true,
  },
  {
    field: 'barcode_id',
    required: false,
    type: 'barcode',
    value: '',
  },
  {
    field: 'equipment_number',
    required: false,
    type: 'text',
    value: '',
    requiredText: 'Equipment Number is required',
  },
  {
    field: 'asset_state',
    required: false,
    type: 'select',
    options: ['Good', 'Bad', 'Reasonable'],
    value: '',
    requiredText: '',
    translate: true,
  },
  {
    field: 'asset_component',
    required: false,
    type: 'multi-select',
    options: [],
    value: [],
    requiredText: '',
    translate: false,
  },
  {
    field: 'asset_description',
    required: false,
    type: 'textarea',
    value: '',
  },
  {
    field: 'remarks',
    required: false,
    type: 'text',
    value: '',
  },
  {
    field: 'amount',
    required: false,
    type: 'text',
    value: '',
  },
  {
    field: 'brand',
    required: false,
    type: 'text',
    value: '',
  },
  {
    field: 'model',
    required: false,
    type: 'text',
    value: '',
  },
  {
    field: 'year',
    required: false,
    type: 'text',
    value: '',
  },
  {
    title: 'Assets.CreateA.title1',
  },
  {
    field: 'project',
    required: true,
    type: 'select',
    options: [],
    value: '',
    requiredText: 'Please select Project name',
    disabled: false,
    translate: false,
  },
  {
    title: 'Assets.CreateA.title2',
  },
  {
    field: 'geo_act_id',
    required: false,
    type: 'text',
    value: '',
  },
  {
    field: 'unit_number',
    required: false,
    type: 'text',
    value: '',
  },
  {
    field: 'control_unit',
    required: true,
    type: 'select',
    options: ['Yes', 'No'],
    value: '',
    requiredText: 'Please select Control Unit',
    translate: true,
  },
  {
    field: 'control_location',
    required: true,
    type: 'textarea',
    value: '',
    requiredText: 'Control Location required',
  },
  {
    field: 'northing_easting_point',
    required: true,
    type: 'text',
    value: '',
    requiredText: 'Northing Easting Point required',
  },
  {
    field: 'northing_easting_address',
    required: true,
    type: 'text',
    value: '',
    requiredText: 'Northing Easting Address required',
  },
  {
    field: 'attachment',
    required: false,
    type: 'file',
    value: '',
    src: '',
    format: '',
  },
  {
    field: 'health_index',
    required: false,
    type: 'readonly',
    value: '',
  },
  {
    field: 'risk_index',
    required: false,
    type: 'readonly',
    value: '',
  }
];


export const quickAssetUpdateFields = [
  {
    field: 'barcode_id',
    required: false,
    type: 'barcode',
    value: '',
  },
  {
    field: 'status',
    required: true,
    type: 'select',
    options: [
      'Installed',
      'Operational',
      'Installed But Not Operational',
      'In Progress',
      'Need Replacement',
      'Replaced',
      'Need Installation',
    ],
    value: '',
    requiredText: 'Please select Status',
    translate: true,
  },
  {
    field: 'asset_state',
    required: false,
    type: 'select',
    options: ['Good', 'Bad', 'Reasonable'],
    value: '',
    requiredText: '',
    translate: true,
  },
  {
    field: 'brand',
    required: false,
    type: 'text',
    value: '',
  },
  {
    field: 'model',
    required: false,
    type: 'text',
    value: '',
  },
  {
    field: 'year',
    required: false,
    type: 'text',
    value: '',
  },
  {
    field: 'comment',
    required: false,
    type: 'textarea',
    value: '',
  },
  {
    field: 'asset_component',
    required: false,
    type: 'barcode',
    value: '',
    requiredText: '',
    translate: false,
  },
  {
    field: 'attachment',
    required: false,
    type: 'file',
    value: '',
    src: '',
    format: '',
  }
]