/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonContent,
  IonPage,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  useIonToast,
  IonLoading,
  IonLabel,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonCheckbox,
  IonItem,
  IonList,
  IonSkeletonText,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createModel937 } from '../../models/model937.js';
import axios from '../../axios';
import { getSetAssetNameWithNoModelo, isFormDataEmpty } from '../../utils';
import { createOutline, fingerPrint } from 'ionicons/icons';
import { MenuItem, Select } from '@material-ui/core';
import ReactSignatureCanvas from 'react-signature-canvas';

const ModelDetail: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  let history = useHistory();
  const [data, setData] = useState({
    name: '',
    nome: ''
  });

  const [present] = useIonToast();

  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [_data, set_Data] = useState(createModel937);
  const [signaturePadShow, setSignaturePadShow] = useState(false);
  const [canvas, setCanvas] = useState(null);
  const [primaryData, setPrimaryData] = useState({});

  const [currentPage, setCurrentPage] = useState(0);
  const [assets, setAssets] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [assetModalToggle, setSetAssetModalToggle] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState();
  const [shimmer, setShimmer] = useState(false);


  const getAssetIndividual = async () => {
    var res: any = history?.location?.state;
    const config: any = {
      method: 'get',
      url: `/resource/Model 937/${res}`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    try {
      const res = await axios(config);
      setData(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (history?.location?.state)
      getAssetIndividual();
  }, [history.location.state]);

  const edit = () => {
    setShowLoading(true);
    setShowModal(true);
    callAssetById();
  };


  const handleChange = (event: React.ChangeEvent<{ value: unknown; id: any }>) => {
    var formentry: any = [..._data];
    const f_index = formentry.findIndex((v: any) => v.key === event.target.id);
    var change_form_object = formentry[f_index];
    change_form_object.value = event.target.value;
    formentry[f_index] = change_form_object;
    set_Data(formentry);
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    var formentry: any = [..._data];
    // @ts-ignore
    const select = event?.target?.name?.replaceAll('-', '_');
    const f_index = formentry.findIndex((v: any) => v.key === select);
    formentry[f_index].value = event.target.value;
    set_Data(formentry);
  };

  // Mapping keys after scan
  const callAssetById = async () => {
    const assetOptions = await getSetAssetNameWithNoModelo(currentPage, searchText);
    setAssets(assetOptions);

    axios({
      method: 'get',
      url: `/resource/Model 937/${data.name}`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    })
      .then((res) => {
        const temp = res?.data?.data;
        setPrimaryData(temp);
        for (const x in _data) {
          const f = _data[x].key;
          if (f === 'asset') {
            _data[x].value = temp[f];
            setSelectedAsset(temp[f]);
            _data[x].options = assetOptions;
          }
          if (f === 'project') {
            _data[x].value = temp[f];
            _data[x].disabled = true;
            _data[x].type = 'text';
          } else if (f) {
            _data[x].value = temp[f];
          }
        }
        set_Data(_data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setShowLoading(false);
        set_Data(createModel937);
      });
  };

  const Submit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    const apiData: { [key: string]: any } = {};

    for (const item in _data) {
      const field = _data[item]?.key;
      const value = _data[item]?.value;
      // @ts-ignore
      if (field === 'asset' && primaryData[field] !== data['asset']) {
        // @ts-ignore
        apiData[field] = data['asset'];
      } else if (typeof field === 'string' && typeof value === 'string' && value !== '' && value !== undefined && value !== null && value !== 'null' && value !== 'undefined' && value !== 'NaN' && value !== 'NaN-NaN-NaN') {
        // @ts-ignore
        if (field && (primaryData[field] !== value))
          apiData[field] = value;
      }
    }
    if (Object.keys(apiData).length) {
      formData.append('data', JSON.stringify(apiData));
    }


    var config: any = {
      method: 'put',
      url: `/resource/Model 937/${data.name}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: formData,
    };
    if (!isFormDataEmpty(formData)) {
      setShowLoading(true);
      axios(config)
        .then(() => {
          present({
            message: t('Model937.updateModel937'),
            duration: 2000,
          });
          setShowModal(false);
          reset();
          getAssetIndividual();
        })
        .catch(function (error) {
          console.log(error?.response?.data);
          const x = error.response.data._server_messages;
          console.log(x.slice(18).slice(0, x.slice(18).indexOf('\\')));
          present({
            message: x.slice(18).slice(0, x.slice(18).indexOf('\\')),
            color: 'danger',
            duration: 2000,
          });
        })
        .finally(() => {
          setShowLoading(false);
        });
    } else {
      setShowModal(false);
    }
  };

  const uploadSignature = () => {
    setSignaturePadShow(true);
  };
  const getSignature = () => {
    // @ts-ignore
    uploadSign(canvas?.toDataURL())
  }

  const uploadSign = async (base64: any) => {
    if (base64) {
      var Data = [..._data];
      const sign = Data.findIndex((d) => d.type === 'signature');
      Data[sign].value = base64;
      set_Data(Data);
    }
    setSignaturePadShow(false);
  };

  const reset = () => {
    for (const x in _data) {
      _data[x].value = '';
    }
  };

  const closeModal = () => {
    setShowModal(false);
    for (const x in _data) {
      const f = _data[x].key;
      if (f === 'asset' || f === 'project') {
        _data[x].value = '';
        _data[x].options = [];
      }
    }
  };

  async function searchNext($event: CustomEvent<void>) {
    const res = await getSetAssetNameWithNoModelo(currentPage + 1, searchText);
    if (res.length) {
      //@ts-ignore  
      setAssets([...assets, ...res]);
    }
    setCurrentPage(currentPage + 1);
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  const handleSearch = async (text: any) => {
    setSearchText(text);
    const res = await getSetAssetNameWithNoModelo(1, text);
    if (res.length) {
      setAssets(res);
    } else {
      setAssets([])
    }
    setCurrentPage(1);
  }

  const setAssetInValue = (asset: any) => {
    // @ts-ignore
    data['asset'] = asset;
    setSelectedAsset(asset);
  }


  const Shimmer = () => {
    return (
      <div>
        {
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((element) => {
            return (
              <div
                key={element}
                id="shimmer-body"
                style={{ padding: '1rem' }}
                className="w-50 data-list shimmer-container"
              >
                <div className="flex justify-between">
                  <IonSkeletonText animated style={{ width: '50%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };
  return (
    <IonPage>
      <Header enableBackButton={location.search ? '/' + location.search.replace("?back=", "") : '/model-history'} title={t('Model937.details')} />
      <IonModal isOpen={showModal} onDidDismiss={() => closeModal()}>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
        />
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>{t('Model937.edit')} {data.nome} </IonTitle>
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <IonIcon name="close" slot="icon-only"></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <form onSubmit={(e) => Submit(e)}>
            {_data.map((form_field, index) => {
              if (form_field.title) {
                return (
                  <p className="form-title" key={`${form_field.title}-${index}`}>
                    {t(`Model937.` + form_field.title)}
                  </p>
                );
              } else if (form_field.type === 'text' || form_field.type === 'number') {
                return (
                  <div className="input-container" key={`${form_field.key}-${index}`}>
                    <label className="label">
                      {t(`Model937.` + form_field.key)}
                    </label>
                    <input
                      type={form_field.type}
                      className="basic-input"
                      id={form_field.key}
                      placeholder={t(`Model937.` + form_field.key)}
                      onChange={handleChange}
                      value={form_field.value as string | number | readonly string[] | undefined}
                      required={form_field.required}
                      disabled={form_field.disabled}
                      
                    />
                  </div>
                );
              } else if (form_field.key === 'asset') {
                return (
                  <div className="input-container" key={`${form_field.key}-${index}`}>
                    <label className="label">
                      {t(`Model937.` + form_field.key)}
                      {form_field.required ? '*' : null}
                    </label>
                    <p onClick={() => setSetAssetModalToggle(!assetModalToggle)} className='basic-input' style={{ marginTop: '0px' }}>{
                      // @ts-ignore
                      data['asset'] || <span style={{ color: 'gray' }}>Select Asset</span>}</p>
                  </div>)
              }

              else if (form_field.type === 'select') {
                return (
                  <div className="input-container" key={`${form_field.key}-${index}`}>
                    <label className="label">
                      {t(`Model937.` + form_field.key)}
                    </label>
                    <Select
                      className="custom-select"
                      id={`${form_field.key}_choice`}
                      name={form_field.key}
                      onChange={handleSelectChange}
                      value={form_field.value}
                    >
                      {form_field.options &&
                        form_field.options.map(function (opt, optIndex) {
                          return (
                            <MenuItem
                              value={`${opt}`}
                              key={`${form_field.key}-${optIndex}`}
                              id={`${opt}_item_${optIndex}`}
                            >
                              <section style={{ display: 'flex', height: '1rem' }}>
                                {/* <img src={Man} alt="Users" style={{ width: '15px' }} /> */}
                                <p
                                  style={{
                                    color: 'rgba(31, 87, 148, 1)',
                                    marginLeft: '0.2rem',
                                    fontSize: '12px',
                                    marginTop: '0px',
                                  }}
                                >
                                  {t(opt)}
                                </p>
                              </section>
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </div>
                );
              } else if (form_field.type === 'date') {
                return (
                  <div className="input-container" key={`${form_field.key}-${index}`}>
                    <label className="label">
                      {t(`Model937.` + form_field.key)}
                    </label>
                    <input
                      className="date"
                      type="date"
                      id={form_field.key}
                      placeholder={t(`Model937.` + form_field.key)}
                      onChange={handleChange}
                      value={form_field.value as string}
                    />
                  </div>
                );
              } else if (form_field.type === 'signature') {
                return (
                  <div className="input-container" key={`${form_field.key}-${index}`}>
                    <label className="label">
                      {t(`Model937.` + form_field.key)}
                    </label>
                    <div className='flex justify-center'>
                      {form_field.value ? <div>
                        <img src={form_field.value as string} alt="signature" style={{ width: '100px', margin: 'auto', display: 'block', marginBottom: '1rem' }} />
                        <p className="underline" style={{ cursor: 'pointer' }} onClick={() => uploadSignature()}>{t(`Model937.replaceSignature`)}</p>
                      </div>
                        :
                        <IonButton color="medium" className='center' onClick={() => uploadSignature()}>
                          <IonLabel>{t(`Model937.uploadSignature`)}</IonLabel>
                          <IonIcon slot="end" src={fingerPrint} className='pointer' style={{ width: '25px' }} />
                        </IonButton>
                      }
                    </div>
                  </div>)
              } else {
                return '';
              }
            })}
            <button className="submit-button" type="submit">
              {t(`Service.ServiceCreate.save`)}
            </button>
          </form>
        </IonContent>
      </IonModal>
      <IonModal
        isOpen={assetModalToggle}
        onDidDismiss={() => setSetAssetModalToggle(false)}
      ><IonHeader>
          <IonToolbar>
            <IonTitle>Assets</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setSetAssetModalToggle(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSearchbar
            className="data-list"
            value={searchText}
            // @ts-ignore
            onIonChange={(e) => handleSearch(e.detail.value!)}
          ></IonSearchbar>
          {shimmer && <Shimmer />}
          <IonList>
            {assets && assets.length ? assets.map((asset, index) => (
              <IonItem
                key={index}
                onClick={() => {
                  setSetAssetModalToggle(false);
                  setAssetInValue(asset)
                }}
              >
                <IonLabel>{asset}</IonLabel>
                <IonCheckbox
                  slot="end"
                  value={asset}
                  checked={asset === selectedAsset} />
              </IonItem>
            )) :
              <div style={{ margin: 'auto', display: 'block' }}>
                <p className='text-center' style={{ color: 'gray' }}>No Asset found</p>
              </div>}
          </IonList>

          <IonInfiniteScroll onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Loading more data..."
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>
      </IonModal>
      <IonContent>
        <div className="preview-header">
          <p className="preview-title">{data.nome}</p>
          {<div className="flex">
            <IonIcon
              className="action-icon"
              onClick={() => {
                edit();
              }}
              src={createOutline}
            />
          </div>}
        </div>

        {createModel937.map((item: any, index) => {
          if (item.title) {
            return (
              <p key={index} className="model-title">
                {t(`Model937.` + item.title)}
              </p>
            );
          }
          return (
            <div key={index} className="model-card">
              {item.type === 'signature' ? (
                <div>
                  <p className="model-preview-key">{t(`Model937.${item.key}`)}</p>
                  <img
                    src={ // @ts-ignore
                      data[item?.key]}
                    alt=""
                    style={{
                      width: '80%',
                      margin: 'auto',
                      display: 'block',
                      borderRadius: '12px',
                      marginBottom: '1rem',
                      padding: '1rem',
                      backgroundColor: 'white'
                    }}
                  />
                </div>
              ) :
                <div>
                  <p className="model-preview-key">{t(`Model937.${item.key}`)}</p>
                  <p className="model-preview-value">
                    {
                      // @ts-ignore
                      data[item.key] ? data[item.key] : '-'
                    }
                  </p>
                </div>
              }
            </div>
          );
        })
        }
      </IonContent>
      <IonModal isOpen={signaturePadShow}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{t(`Model937.signaturePad`)}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setSignaturePadShow(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div style={{ marginTop: '2rem', border: '1px solid lightgray', margin: '5px', borderRadius: '5px' }}>
          <ReactSignatureCanvas penColor='#1d508d'
            backgroundColor='rgba(255, 255, 255, 0)'
            ref={(ref: any) => { setCanvas(ref) }}
            dotSize={5}
            canvasProps={{ width: 500, height: 400, className: 'sigCanvas' }} />
        </div>
        <button className="submit-button" onClick={() => getSignature()}>
          {t(`Utils.addSignautre`)}
        </button>
      </IonModal>
    </IonPage>
  );
};

export default ModelDetail;
