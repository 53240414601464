import React from 'react';
import landbutton from '../assets/landbutton.png';
import { IonFab, IonFabButton } from '@ionic/react';
import { Link } from 'react-router-dom';
// import bulb from '../assets/icons/bulb.svg';
// import calender from '../assets/calender.svg';
// import toolkit from '../assets/toolkit.svg';

const ScanButton: React.FC = () => {
  return (
    <>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton>
          <Link to="/smartScan">
            <img src={landbutton} alt="Land Button" />
          </Link>
        </IonFabButton>
      </IonFab>
    </>
  );
};

export default ScanButton;
