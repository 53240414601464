import { IonContent,  useIonToast, IonPage, withIonLifeCycle } from '@ionic/react';
import { useEffect } from 'react';
import { Device } from '@capacitor/device';

import BarcodeScannerComponent from "react-qr-barcode-scanner";

import Close from '../assets/Close.svg';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Scan = (props: any) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [present] = useIonToast();

  useEffect(() => {
    (async () => {
      const info = await Device.getInfo();

      if (info.operatingSystem === 'unknown') {
        alert('App is not supported!');
      }
    })();
  }, []);

  const back = () => {
    history.goBack();
  };

  const handleScan = (data: any) => {
    if (data) {
      props.setIsScan(false);
      try {
        props.sendIdBackToPage(data);
      } catch {
        present({
          message: t('Utils.fetchFail'),
          color: 'danger',
          duration: 4000,
        });
        console.log('failed sending id to the parent');
      }
    }
  };

  return (
    <IonPage>
      <IonContent>
        {/* {platform === 'web' ? ( */}
        <div id="qr-scanner">
          <BarcodeScannerComponent
            torch={true}
            onUpdate={(err, result) => {
              //@ts-ignore
              if (result && result.text) handleScan(result.text);
              }}
            />
          <img
            onClick={back}
            src={Close}
            alt="sync"
            style={{ position: 'absolute', top: '20px', right: '25px', width: '20px', zIndex: 999 }}
          />
        </div>
        <div className="submit-button" style={{ textAlign: 'center' }} onClick={() => back}>
          {t('Utils.cancel')}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default withIonLifeCycle(Scan);
