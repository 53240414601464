/* eslint-disable array-callback-return */
import { IonContent, IonPage, useIonToast, IonLoading, useIonViewWillEnter, useIonRouter } from '@ionic/react';
import { useState, useEffect } from 'react';
import Header from '../../components/Header';
import axios from '../../axios';
import { useTranslation } from 'react-i18next';
import { Network } from '@capacitor/network';

import { MenuItem, Select } from '@material-ui/core';
import {
  getProjectStatus,
  getProjectPriority,
  getProjectLocation,
} from '../../utils';
import { createProjectFields } from '../../models/createProjectFields';

const CreateProject: React.FC = () => {
  const router = useIonRouter();
  const [present] = useIonToast();

  const { t } = useTranslation();

  const [showLoading, setShowLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(Boolean);

  const [data, setData] = useState(createProjectFields);


  const handleChange = (event: React.ChangeEvent<{ value: unknown; id: any }>) => {
    var formentry: any = [...data];
    const f_index = formentry.findIndex((v: any) => v.field === event.target.id);
    var change_form_object = formentry[f_index];
    change_form_object.value = event.target.value;
    formentry[f_index] = change_form_object;
    setData(formentry);
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    var formentry: any = [...data];
    // @ts-ignore
    const select = event.target.name.replaceAll('-', '_');
    const f_index = formentry.findIndex((v: any) => v.field === select);
    formentry[f_index].value = event.target.value;
    setData(formentry);
  };

  const url = '/resource/Project';

  useIonViewWillEnter(async () => {
    var Data = [...data];
    /* Get Project Status */
    const sname = Data.findIndex((d) => d.field === 'status');
    const projectStatus = await getProjectStatus();
    Data[sname].options = projectStatus;

    const pname = Data.findIndex((d) => d.field === 'priority');
    const priority = await getProjectPriority();
    Data[pname].options = priority;

    const lname = Data.findIndex((d) => d.field === 'location');
    const location = await getProjectLocation();
    Data[lname].options = location;

    setData(Data);
  });

  useEffect(() => {
    (async () => {
      const internetStatus = await Network.getStatus();
      setIsOnline(internetStatus.connected);

      Network.addListener('networkStatusChange', (status) => {
        console.log('Network status changed', status.connected);
        setIsOnline(status.connected);
      });
    })();
  }, [isOnline]);

  const Submit = (e: any) => {
    e.preventDefault();
    hitCreateAsset();
  };


  const hitCreateAsset = () => {
    const api_data: any = {};
    data.map((_entry: any) => {
      api_data[_entry.field] = _entry.value;
      return null;
    });

    var config: any = {
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: api_data,
    };
    setShowLoading(true);
    axios(config)
      .then((res) => {
        present({
          message: t(`Project.successCreate`),
          duration: 2000,
        });
        reset();
        router.goBack();
      })
      .catch(function (error) {
        console.log(error?.response?.data);
        const raw = JSON.parse(error?.response?.data?._server_messages);
        console.log(JSON.parse(raw[0])?.message);
        present({
          message: JSON.parse(raw[0]).message,
          color: 'danger',
          duration: 2000,
        });
      })
      .finally(() => {
        setShowLoading(false);
      });
  }

  const reset = () => {
    for (const x in data) {
      data[x].value = '';
    }
  };

  return (
    <IonPage>
      <Header enableBackButton='goBack' title={t('Project.create')} />
      <IonContent>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          duration={5000}
        />

        <form onSubmit={(e) => Submit(e)}>
          {data.map((form_field, index) => {
            if (form_field.title) {
              return (
                <p className="form-title" key={`${form_field.title}-${index}`}>
                  {t(form_field.title)}
                </p>
              );
            } else if (form_field.type === 'text') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(`Project.${form_field.field}`)}
                    {form_field.required ? '*' : null}
                  </label>
                  <input
                    required={form_field.required}
                    className="basic-input"
                    id={form_field.field}
                    placeholder={t(`Project.${form_field.field}`)}
                    onChange={handleChange}
                    value={form_field.value}
                    style={{
                      background: form_field.disabled ? '#F1F7F5' : ''
                      }}
                  />
                </div>
              );
            } else if (form_field.type === 'textarea') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(`Project.${form_field.field}`)}
                    {form_field.required ? '*' : null}
                  </label>
                  <textarea
                    className="textarea"
                    required={form_field.required}
                    id={form_field.field}
                    placeholder={t(`Project.${form_field.field}`)}
                    onChange={handleChange}
                    value={form_field.value}
                  />
                </div>
              );
            } else if (form_field.type === 'select') {
              return (
                <div
                  id={`${form_field.field}-${index}`}
                  className="input-container"
                  key={`${form_field.field}-${index}`}
                >
                  <label id={`label-${form_field.field}`} className="label">
                    {t(`Project.${form_field.field}`)}
                    {form_field.required ? '*' : null}{' '}
                  </label>
                  <Select
                    className="custom-select"
                    required={form_field.required}
                    id={`${form_field.field}_choice`}
                    name={form_field.field}
                    onChange={handleSelectChange}
                    value={
                      form_field.field === 'asset_component' && form_field.value.length
                        ? //@ts-ignore
                        form_field.value[0]['asset_component']
                        : form_field.value
                    }
                    // @ts-ignore
                    disabled={form_field.disabled || false}
                  >
                    {form_field.options &&
                      form_field.options.map(function (opt, indexSelect) {
                        return (
                          <MenuItem
                            value={`${opt}`}
                            key={`${form_field.field}-${indexSelect}`}
                            id={`${opt}_item_${indexSelect}`}
                          >
                            <section
                              id={`section-${form_field.field}-${index}`}
                              style={{ display: 'flex', height: '1rem' }}
                            >
                              <p
                                id={`value-${form_field.field}-${index}`}
                                style={{
                                  color: 'rgba(31, 87, 148, 1)',
                                  marginLeft: '0.2rem',
                                  fontSize: '12px',
                                  marginTop: '0px',
                                }}
                              >
                                {/* // @ts-ignore */}
                                {opt}
                                {/* {form_field.translate ? t(`Project.${opt}`) : opt} */}
                              </p>
                            </section>
                          </MenuItem>
                        );
                      })}
                  </Select>
                </div>
              );
            } else if (form_field.type === 'date') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(`Project.${form_field.field}`)}
                    {form_field.required ? '*' : null}
                  </label>
                  <input
                    required={form_field.required}
                    className="date"
                    id={form_field.field}
                    placeholder={t(`Project.${form_field.field}`)}
                    onChange={handleChange}
                    value={form_field.value}
                    type="date"
                  />
                </div>
              );
            } else {
              return '';
            }
          })}
          <button className="submit-button" type="submit">
            {t('Project.create')}
          </button>
        </form>
        <div style={{ marginBottom: '1rem' }}></div>
      </IonContent>
    </IonPage>
  );
};

export default CreateProject;
