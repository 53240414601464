/* eslint-disable array-callback-return */
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSkeletonText,
  useIonToast,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import axios from '../../axios';

import { createProjectFields } from '../../models/createProjectFields';
import { analyticsOutline, calendarOutline, colorFilterOutline, planetOutline, shapesOutline, timerOutline } from 'ionicons/icons';

const ViewProject: React.FC = () => {
  const [data, setData] = useState(createProjectFields);
  const [task, setTask] = useState([]);
  const [status, setStatus] = useState(false);
  const [present] = useIonToast();
  const [loader, setLoader] = useState(false);

  const options = ["Open",
    "Working",
    "Pending Review",
    "Overdue",
    "Template",
    "Completed",
    "Cancelled"];
  const { t } = useTranslation();

  const history = useHistory();
  const name = history.location.state;
  const getData = async () => {
    var res: any = history.location.state;
    try {
      const response = await axios({
        method: 'get',
        url: `/resource/Project/${res}`,
        headers: {
          Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
        },
      })
      const temp = response.data.data;
      setData(temp);
    } catch (error) {
      console.log(error);
    }

    try {
      setLoader(true);
      const temp = await axios({
        method: 'get',
        url: `/method/preflet_asset.api.get_task_list?id=${res}`,
        headers: {
          Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
        },
      })
      setTask(temp.data.message);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterByStatus = async (status: any) => {
    try {
      const temp = await axios({
        method: 'get',
        url: `/method/preflet_asset.api.get_task_list?id=${name}&status=${status}`,
        headers: {
          Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
        },
      })
      setTask(temp.data.message);
    } catch (error) {
      console.log(error);
    }
  }

  const updateStatus = async (status: any, item: any) => {
    setStatus(status);
    // const url = `/method/preflet_asset.api.get_task_list/${item.name}`;
    const url = `/resource/Task/${item.name}`;

    var config: any = {
      method: 'put',
      url,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: {
        status
      },
    };
    try {
      await axios(config)
      present({
        message: 'Status updated!',
        duration: 2000,
      })
      getData();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <IonPage>
      <Header enableBackButton='goBack' title={t('Project.details')} />
      <IonContent>
        <div className="preview-header">
          <p className="preview-title" style={{ width: '90%' }}>{
            // @ts-ignore
            data['project_name']}</p>
        </div>
        {createProjectFields.map((item: any, index) => {
          if (item.title) {
            return (
              <p key={index} className="form-title">
                {t(item.title)}
              </p>
            );
          }
          return (
            <div key={index} className="data-list">
              {item.field ? (
                <div className="preview-container">
                  <p className="preview-key">{t(`Project.${item.field}`)}</p>
                  <p className="preview-value">
                    {
                      // @ts-ignore
                      data[item.field] ? data[item.field] : '-'
                    }
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
          );
        })}

        <div className="preview-header">
          <p className="preview-title">
            <IonIcon style={{ marginRight: '4px' }} src={shapesOutline} />
            Tasks</p>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <IonIcon src={colorFilterOutline} />
            <IonSelect placeholder="Group by Status" onIonChange={(e) => filterByStatus(e.detail.value)}>
              {options.map((option) => {
                return (
                  <IonSelectOption key={option} value={option}>
                    {option}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </div>
        </div>
        {loader &&
          [1, 2, 3, 4, 5].map((element) => {
            return (
              <IonCard id="card-shimmer" key={element}>
                <IonCardHeader>
                  <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '2rem' }}>
                    <IonSkeletonText animated style={{ width: '60%' }} />
                    <IonSkeletonText animated style={{ width: '19%' }} />
                  </div>
                  <IonSkeletonText animated style={{ width: '30%' }} />
                  <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '2rem' }}>
                    <IonSkeletonText animated style={{ width: '40%' }} />
                    <IonSkeletonText animated style={{ width: '10%' }} />
                  </div>
                </IonCardHeader>
              </IonCard>)
          })}
        {task.map((item: any, key) => {
          return (
            <IonCard id="card-container" key={key}>
              <IonCardHeader>
                <IonCardTitle className="card-heading">
                  <div className="flex justify-between items-center">
                    <Link className="underline" to={{ pathname: '/view-task', state: item.name }} key={key}>
                      <p className="flex items-center underline pointer"  >
                        <IonIcon src={planetOutline} style={{ marginRight: '5px' }} />
                        {item.subject}</p>
                    </Link>
                    <div className="flex items-center">
                      <IonSelect onIonChange={(e) => updateStatus(e.detail.value, item)} value={status || item.status}>
                        {options.map((option) => {
                          return (
                            <IonSelectOption key={option} value={option}>
                              {option}
                            </IonSelectOption>
                          );
                        })}
                      </IonSelect>
                    </div>
                  </div>
                </IonCardTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                </div>
                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  {item.exp_start_date || item.exp_end_date ? <IonIcon src={calendarOutline} style={{ marginRight: '5px' }} /> : null}
                  {item.exp_start_date && <p style={{ marginRight: '5px' }}> Start: {item.exp_start_date} |   </p>}
                  {item.exp_end_date && <p> End: {item.exp_end_date}</p>}
                </div>
                {item.expected_time !== 0 && <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <IonIcon src={timerOutline} style={{ marginRight: '5px' }} />
                  <p> {item.expected_time} Hours</p>
                </div>}
                {item.progress !== 0 && <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <IonIcon src={analyticsOutline} style={{ marginRight: '5px' }} />
                  <p> {item.progress} %</p>
                </div>}
              </IonCardHeader>
              <IonCardContent>
                <div style={{ display: 'flex', flex: 'felx-wrap', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {item.assign_user.length !== 0 && item.assign_user.map((element: any, index: number) => {
                    return (
                      <div key={index} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <img style={{ borderRadius: '999px', width: '2rem', height: '2rem', objectFit: 'cover', marginRight: '5px' }} src={`${localStorage.getItem('baseUrl')?.replace('/api', '')}${element.user_image}`} alt="profile" />
                      </div>
                    )
                  })}
                </div></IonCardContent>
            </IonCard>
          );
        })}

        <div style={{ marginTop: '4rem' }}> </div>
      </IonContent>
    </IonPage>
  );
};

export default ViewProject;
