import {
  IonContent,
  IonPage,
  IonToolbar,
  IonSkeletonText,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
  IonSearchbar,
  // IonSearchbar,
} from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import { chevronDownCircleOutline } from 'ionicons/icons';
import Header from '../../components/Header';

import { useState, useEffect } from 'react';
import axios from '../../axios';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../providers/AppContext';
import NoRecords from '../../components/NoRecords';

const ServiceHistory: React.FC = () => {
  const [data, setData] = useState({
    dataSet: [],
  });

  const { state } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);

  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');

  const getData = (context: any) => {
    let url = `/method/preflet_asset.api.get_assigned_repair_requests?limit=${state.maxLength}&start=${context.isFiltered ? 0 : currentPage * 5 + 0
      }`;

    // add search data if any
    if ('search' in context || searchText) {
      setSearchText(context.search || '');
      if (context.search) url += `&search=${context.search || searchText}`;
    }

    var config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    axios(config)
      .then((res: any) => {
        if (res && res?.data && res.data?.message && res.data.message?.repair_request_list?.length > 0) {
          const newDataSet = res.data.message?.repair_request_list.map((repairRequest: any) => (
            <div key={repairRequest.id}>{repairRequest.name}</div>
          ));
          if (context.isFiltered) {
            const rawData = { dataSet: [], loading: false };
            rawData.dataSet = res.data.message?.repair_request_list;
            setData(rawData);
          } else {
            var Data = { ...data };
            // @ts-ignore
            Data.dataSet.push(...res.data.message?.repair_request_list);
            setData(Data);
          }
          setDisableInfiniteScroll(res.data.message?.repair_request_list.length < state.maxLength);
        } else {
          const value = { ...data };
          setData(value);
          setDisableInfiniteScroll(true);
        }
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
        setCurrentPage(currentPage + 1);
      });
  };

  useEffect(() => {
    getData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    getData({});
    setTimeout(() => {
      event.detail.complete();
    }, 2000);
  }

  function searchNext($event: CustomEvent<void>) {
    getData({});
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }


  const { t } = useTranslation();

  const handleSearch = (value: any) => {
    getData({ search: value, isFiltered: true });
  }


  return (
    <IonPage>
      <Header enableBackButton='/servicehome' title={t('Service.History')} />
      <IonContent>
        <IonSearchbar
          className="data-list"
          value={searchText}
          // @ts-ignore
          onIonChange={(e) => handleSearch(e.detail.value!)}
        ></IonSearchbar>
        {isLoading &&
          [1, 2, 3, 4, 5, 6, 7, 8].map((element, key) => {
            return (
              <div key={key} style={{ padding: '1rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '60%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '30%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
              </div>
            );
          })}
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={t('Utils.pullRefresh')}
            refreshingSpinner="circles"
            refreshingText={t('Utils.refreshing')}
          ></IonRefresherContent>
        </IonRefresher>

        {data.dataSet &&
          data.dataSet.map((item: any, key) => {
            return (
              <div
                key={key}
                className="ion-margin data-list"
                style={{ boxShadow: '2px 8px 48px -16px rgba(21, 70, 160, 0.1)', borderRadius: '20px' }}
              >
                <section style={{ display: 'flex' }}>
                  <section style={{ flex: 'column' }}>
                    <h4
                      style={{
                        marginLeft: '0.4rem',
                        marginTop: '1.3rem',
                        color: 'rgba(29, 80, 141, 1)',
                        fontSize: 'large',
                      }}
                    >
                      {item.name}
                    </h4>
                    <p
                      style={{
                        marginLeft: '0.5rem',
                        color: 'rgba(146, 155, 171, 1)',
                        marginTop: '-0.5rem',
                        fontSize: '11px',
                        maxWidth: '200px',
                        wordWrap: 'break-word',
                      }}
                    >
                      {t('Assets.Details.AID')} : {item.asset}
                    </p>
                  </section>
                </section>

                <section style={{ flex: 'column', paddingBottom: '10px' }}>
                  <Link to={{ pathname: '/serviceDetail', state: item.name }}>
                    <p
                      style={{
                        color: 'rgba(29, 80, 141, 1)',
                        float: 'right',
                        fontSize: '13px',
                        flex: 'column',
                        marginTop: '-2.9rem',
                        paddingRight: '1rem',
                        textTransform: 'capitalize',
                        textDecoration: 'underline'
                      }}
                    >
                      {t('Assets.Details.Details')}
                    </p>
                  </Link>
                  <section style={{ display: 'flex', float: 'right' }}>
                    {/* <img src={Red} alt="Red" style={{ marginTop: '-2.2rem', marginRight: '5px' }} /> */}
                    <p
                      style={{
                        color: 'rgba(146, 155, 171, 1)',
                        float: 'right',
                        marginTop: '-1.5rem',
                        fontSize: '10px',
                        paddingRight: '1rem',
                      }}
                    >
                      {item.men_status}
                    </p>
                  </section>
                  {item.target_date && (new Date(item.target_date).toLocaleDateString() < new Date().toLocaleDateString() ?
                    <div className='overdue'>overdue</div> : '')}
                </section>
              </div>
            );
          })}

        <IonInfiniteScroll onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
          <IonInfiniteScrollContent
            loadingSpinner="crescent"
            loadingText="Loading more data..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>

        {!isLoading && data.dataSet.length === 0 ? (
          <IonRow>
            <NoRecords width="60%" smallText={false} />
          </IonRow>
        ) : (
          <div></div>
        )}

        <IonToolbar></IonToolbar>
      </IonContent>
    </IonPage>
  );
};

export default ServiceHistory;
