import React, { useState, useEffect } from 'react';
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonCol,
  IonGrid,
  IonRow,
  IonCard,
  IonLabel,
  IonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonIcon,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonLoading,
  useIonToast,
  IonSearchbar,
  IonSkeletonText,
  IonSelectOption,
  IonChip,
  IonSelect,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import { RefresherEventDetail } from '@ionic/core';
import { chevronDownCircleOutline, closeCircle, constructOutline } from 'ionicons/icons';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../providers/AppContext';
import NoRecords from '../../components/NoRecords';
import { MenuItem, Select } from '@material-ui/core';
import { createAssetFields } from '../../models/createAssetFields';
import { checkIfArraysAreSame, getAssetComponentByLabel, getStatusColor, isFormDataEmpty } from '../../utils';

const AllAssets: React.FC = () => {
  const [data, setData] = useState({
    dataSet: [],
    loading: false,
  });
  const [present] = useIonToast();
  const { state } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);

  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState<string>();
  const { t } = useTranslation();

  const [status, setStatus] = useState('');
  const [activef, setActivef] = useState({
    status: [],
  });

  const [update, setUpdate] = useState({
    status: '',
    asset_state: '',
    brand: '',
    model: '',
    year: '',
    comment: '',
    asset_component: []
  });
  // const [showModal,setShowModal] = useState(false)
  const [fabModal, setFabModal] = useState(false);
  const [loaderM, setLoaderM] = useState(false);
  const [_data, set_Data] = useState(createAssetFields);
  const [pname, setPname] = useState('');

  const [primaryData, setPrimaryData] = useState({});

  const handleUpdateStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    var formentry: any = { ...update };
    //@ts-ignore
    if (event.target.name in formentry) {
      //@ts-ignore
      formentry[event.target.name] = event.target.value;
    }
    setUpdate(formentry);
  };

  const handleMultiSelectChange = (event: string) => {
    var formentry: any = { ...update };
    formentry['asset_component'] = event
    setUpdate(formentry);
  };

  const handleChangeQuickUpdate = (event: React.ChangeEvent<{ value: unknown; id: any }>) => {
    var formentry: any = { ...update };
    if (event.target.id in formentry) {
      formentry[event.target.id] = event.target.value;
    }
    setUpdate(formentry);
  };

  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);

  const getData = (context: any) => {
    let url = `method/preflet_asset.api.get_assets_active_tasks?limit_page_length=${state.maxLength}&limit_start=${context.isFiltered ? 0 : currentPage * 10 + 0
      }&fields=["*"]`;

    // add search data if any
    if ('search' in context || searchText) {
      setSearchText(context.search || '');
      if (context.search) url += `&search=${context.search || searchText}`;
    }

    if ('status' in context) {
      setStatus(context.status);
      url += context?.status ? `&status=${context.status}` : '';
    }
    setIsLoading(true);

    var config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    axios(config)
      .then((res: any) => {
        if (res && res?.data?.message && res.data.message?.length > 0) {
          if (context.isFiltered) {
            const rawData = { dataSet: [], loading: false };
            rawData.dataSet = res.data.message;
            setData(rawData);
          } else {
            const rawData = { ...data };
            // @ts-ignore
            rawData.dataSet.push(...res.data.message);
            setData(rawData);
          }
          setDisableInfiniteScroll(res.data.message.length < state.maxLength);
        } else {
          // @ts-ignore
          if (context?.status) {
            present({
              message: `${t('No data found for')} ${context?.status}`,
              duration: 3000,
            });
            const rawData = { dataSet: [], loading: false };
            rawData.dataSet = res.data.message;
            setData(rawData);
          } else {
            const value = { ...data };
            setData(value);
          }
          setDisableInfiniteScroll(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
        setCurrentPage(currentPage + 1);
      });
  };

  useEffect(() => {
    getData({});
  }, []);

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    getData({});
    setTimeout(() => {
      event.detail.complete();
    }, 2000);
  }

  async function searchNext($event: CustomEvent<void>) {
    await getData({});

    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }



  const handleSearch = (value: any) => {
    getData({ search: value, isFiltered: true });
  }

  const filterFunc = async (keyname: any, value: any) => {
    var temp = { ...activef };
    if (keyname === 'status') {
      temp.status = [];
      // @ts-ignore
      temp.status.push(value);
    }
    setActivef(temp);
  };

  const clearFilter = async (keyname: any, item: any) => {
    var temp = { ...activef };
    if (keyname === 'status') {
      try {
        temp.status = temp.status.filter((e) => e !== item);
        setStatus('');
      } catch (error) { }
    }
    setActivef(temp);
    getData({ isFiltered: true });
  };

  const SubmitStatusUpdate = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    const temp = { ...update }
    for (const item in temp) {
      //@ts-ignore
      if (item === 'attachment' && temp['attachment']?.src) {
        try {
          //@ts-ignore
          const response = await fetch(temp['attachment']?.src?.toString());
          const blob = await response.blob();
          formData.append(
            'attachment',
            // @ts-ignore
            new File([blob], `${pname}-attachment.${temp['attachment'].format}`, {
              // @ts-ignore
              type: `image/${temp['attachment'].format}`,
            })
          );
        } catch (e) {
          console.log(e)
        }
      } else if (item === 'asset_component') {
        if (temp[item].length > 0) {
          var assComp = [] as any;
          for (const element in temp['asset_component']) {
            assComp.push({
              asset_component: temp['asset_component'][element]
            })
          }
          // @ts-ignore
          if (!checkIfArraysAreSame(primaryData[item], assComp))
            formData.append('asset_component', JSON.stringify(assComp));
        }
        // @ts-ignore
      } else if (item === 'attachment' && (primaryData[item] !== temp[item].value)) {
        // @ts-ignore
        formData.append('attachment', temp[item].value);
      }
      // @ts-ignore
      else if (temp[item] !== '' && temp[item] !== null && temp[item] !== undefined && temp[item] !== 'undefined') {
        // @ts-ignore
        if ((primaryData[item] !== temp[item]) && item !== 'attachment')
          // @ts-ignore
          formData.append(item, temp[item]);
      }
    }

    var config: any = {
      method: 'put',
      url: `method/preflet_asset.api.update_asset/${pname}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: formData,
    };
    if (!isFormDataEmpty(formData)) {
      // Update status api
      try {
        setLoaderM(true);
        await axios(config);
      } catch (error) {
        console.log(error);
      }

      // Update comment
      try {
        setLoaderM(true);
        var config_comment: any = {
          method: 'post',
          url: 'resource/Comment',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
          },
          data: {
            comment_type: 'Comment',
            reference_doctype: 'Asset',
            reference_name: pname,
            content: update.comment,
          },
        };

        await axios(config_comment);
      } catch (error) {
        console.log(error);
      } finally {
        setLoaderM(false);
        setFabModal(false);
        present({
          message: t('Assets.CreateA.assetUpdateSuccesss'),
          duration: 2000,
        });
      }
      getData({});
      setUpdate({
        status: '',
        asset_state: '',
        brand: '',
        model: '',
        year: '',
        comment: '',  // comment 
        asset_component: [],
      });
    } else {
      setFabModal(false);
    }
   
  };

  const modalFunc = async (param: any) => {
    setFabModal(true);
    var Data = [..._data];
    /* Get Asset Component to link Asset */
    const acname = Data.findIndex((d) => d.field === 'asset_component');
    Data[acname].options = await getAssetComponentByLabel();
    set_Data(Data);

    var config: any = {
      method: 'get',
      url: `resource/Asset/${param}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    var assetComponent: { asset_component: any; }[] = []
    try {
      const statusData = await axios(config)
      statusData?.data?.data?.asset_component.map((item: any) => {
        assetComponent.push(item?.asset_component)
      })

      setPrimaryData(statusData?.data?.data);

      for (const x in data.dataSet) {
        // @ts-ignore
        if (data.dataSet[x].name === param) {
          var formentry: any = { ...update };
          formentry.status = statusData?.data?.data?.status
          formentry.asset_state = statusData?.data?.data?.asset_state
          formentry.brand = statusData?.data?.data?.brand
          formentry.model = statusData?.data?.data?.model
          formentry.year = statusData?.data?.data?.year
          formentry.comment = statusData?.data?.data?.comment
          formentry.asset_component = assetComponent
          setUpdate(formentry);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }



  return (
    <IonPage>
      <Header enableBackButton='/assets' title={t('Assets.Assets')} />
      <IonSearchbar
        className="data-list"
        value={searchText}
        // @ts-ignore
        onIonChange={(e) => handleSearch(e.detail.value!)}
      ></IonSearchbar>

      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={t('Utils.pullRefresh')}
            refreshingSpinner="circles"
            refreshingText={t('Utils.refreshing')}
          ></IonRefresherContent>
        </IonRefresher>
        <div className="filter-container">
          <div></div>
          <IonSelect
            value={status}
            placeholder={t('Utils.filter')}
            onIonChange={(e) => {
              getData({ status: e.detail.value, isFiltered: true });
              setStatus(e.detail.value);
              filterFunc('status', e.detail.value);
            }}
            interface="popover"
            key={'satus-filter'}
          >
            <IonSelectOption value="Installed">{t('Utils.Installed')}</IonSelectOption>
            <IonSelectOption value="Operational">{t('Utils.Operational')}</IonSelectOption>
            <IonSelectOption value="Installed But Not Operational">{t('Utils.Installed But Not Operational')}</IonSelectOption>
            <IonSelectOption value="Need Repairing">{t('Utils.Need Repairing')}</IonSelectOption>
            <IonSelectOption value="In Progress">{t('Utils.In Progress')}</IonSelectOption>
            <IonSelectOption value="Need Installation">{t('Utils.Need Installation')}</IonSelectOption>
            <IonSelectOption value="Replaced">{t('Utils.Replaced')}</IonSelectOption>
            <IonSelectOption value="Need Replacement">{t('Utils.Need Replacement')}</IonSelectOption>
          </IonSelect>
        </div>

        <div className='active-filter-row'>
          {activef &&
            activef.status.map((item: any, statusChipIndex) => {
              return (
                item && <div key={`chip-status-${statusChipIndex}`}>
                  <IonChip outline color="dark">
                    <IonLabel>{item}</IonLabel>
                    <IonIcon src={closeCircle} onClick={() => clearFilter('status', item)}></IonIcon>
                  </IonChip>
                </div>
              );
            })}
        </div>
        {isLoading &&
          [1, 2, 3, 4, 5, 6, 7, 8, 9].map((element, key) => {
            return (
              <div key={key} style={{ padding: '1rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '60%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '30%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
              </div>
            );
          })}
        <IonGrid>
          {data.dataSet.map((item: any, key) => {
            return (
              <IonCard key={`asset-card-${key}`} className="data-list" style={{ marginTop: '1rem' }}>
                <IonRow className="ion-text-center">
                  <IonCol style={{ padding: '0.5rem', cursor: 'pointer' }}>
                    <IonLabel className="card-heading">
                      <Link className="underline capitalize" to={{ pathname: '/assetDetail', state: item.name }}>
                        {item.name}
                      </Link>
                    </IonLabel>
                    <IonText>
                      <p className="card-text-details">
                        {t('Assets.CreateA.project')}: {item.project}
                      </p>
                    </IonText>
                  </IonCol>
                  <IonCol>
                    <div
                      onClick={() => {
                        setPname(item.name);
                        modalFunc(item.name);
                      }}
                      className="view-details flex items-center justify-center my-2"
                    >
                      <IonIcon src={constructOutline} className="view-detail-highight-icon" />
                    </div>
                    <div className='flex items-center justify-center'>
                      <div className="timeago" style={{ width: '12px', height: '12px', borderRadius: '1rem', backgroundColor: getStatusColor(item.status), margin: '4px' }}></div>
                      <span>{t(`Utils.${item.status}`)}</span>
                    </div>
                  </IonCol>
                </IonRow>
              </IonCard>
            );
          })}
          <IonModal isOpen={fabModal} onDidDismiss={() => setFabModal(false)}>
            <IonHeader>
              <IonToolbar color="primary">
                <IonButtons slot="end">
                  <IonButton
                    className="line-button"
                    onClick={() => {
                      setFabModal(false);
                    }}
                  >
                    <IonIcon name="close" slot="icon-only"></IonIcon>
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>

            <IonContent>
              <IonLoading
                cssClass="my-custom-class"
                isOpen={loaderM}
                onDidDismiss={() => setLoaderM(false)}
                message={t('Utils.pleasewait')}
              />
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '1rem',
                  color: 'rgba(31, 87, 148, 1)',
                }}
              >
                <b>{pname}</b>
              </p>
              <form onSubmit={(e) => SubmitStatusUpdate(e)}>
                {_data.map((form_field, index) => {
                  if (form_field.field === 'asset_component') {
                    return (
                      <div key={index} className="input-container">
                        <label className="label">{t(`Assets.CreateA.${form_field.field}`)}</label>
                        <IonSelect
                          id={`${form_field.field}_choice`}
                          className="custom-ion-select"
                          multiple
                          interface='popover' style={{ color: '#1d508d', fontSize: '14px' }} placeholder='Change Status'
                          onIonChange={(e) => handleMultiSelectChange(e.detail.value)}
                          value={update[form_field.field]}
                        >
                          {form_field.options &&
                            form_field.options.map(function (opt, indexSelect) {
                              return (
                                <IonSelectOption value={
                                  //@ts-ignore
                                  opt.id}
                                  key={`${form_field.field}-${indexSelect}`}
                                  id={//@ts-ignore
                                    `${opt.id}_item_${indexSelect}`}>{opt.label}</IonSelectOption>
                              );
                            })}
                        </IonSelect>
                      </div>
                    );
                  } else if (form_field.field === 'status' || form_field.field === 'asset_state') {
                    return (
                      <div key={index} className="input-container">
                        <label className="label">{t(`Assets.CreateA.${form_field.field}`)}</label>
                        <Select
                          className="custom-select"
                          required={form_field.required}
                          id={`${form_field.field}_choice`}
                          name={form_field.field}
                          onChange={handleUpdateStatus}
                          value={update[form_field.field]}
                          disabled={form_field.disabled || false}
                          key={`${form_field.field}-${index}`}
                        >
                          {form_field.options &&
                            form_field.options.map(function (opt, index) {
                              return (
                                <MenuItem value={`${opt}`} key={`${form_field.field}-${index}`}>
                                  <section style={{ display: 'flex', height: '1rem' }}>
                                    <p
                                      style={{
                                        color: 'rgba(31, 87, 148, 1)',
                                        marginLeft: '0.2rem',
                                        fontSize: '12px',
                                        marginTop: '0px',
                                      }}
                                    >
                                      {form_field.translate ? t(`Assets.CreateA.${opt}`) : opt}
                                    </p>
                                  </section>
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </div>
                    );
                  } else if (
                    form_field.field === 'brand' ||
                    form_field.field === 'model' ||
                    form_field.field === 'year'
                  ) {
                    return (
                      <div key={index} className="input-container">
                        <label className="label">{t(`Assets.CreateA.${form_field.field}`)}</label>
                        <input
                          className="basic-input"
                          required={false}
                          key={`${form_field.field}-${index}`}
                          id={form_field.field}
                          placeholder={t(`Assets.CreateA.${form_field.field}`)}
                          onChange={handleChangeQuickUpdate}
                          value={update[form_field.field]}
                        />
                      </div>
                    );
                  } else {
                    return '';
                  }
                })}
                <div className="input-container">
                  <label className="label">{t(`Utils.Comment`)}</label>
                  <textarea
                    className="textarea"
                    required={false}
                    key="comment"
                    id="comment"
                    placeholder={t('Utils.Add your comment')}
                    onChange={handleChangeQuickUpdate}
                    value={update.comment}
                  />
                </div>
                <button className="submit-button" type="submit">
                  {t(`Assets.CreateA.update`)}
                </button>
              </form>
            </IonContent>
          </IonModal>
          {!filter ? (
            <IonInfiniteScroll onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
              <IonInfiniteScrollContent
                loadingSpinner="bubbles"
                loadingText="Loading more data..."
              ></IonInfiniteScrollContent>
            </IonInfiniteScroll>
          ) : (
            ''
          )}
          {!isLoading && data.dataSet.length === 0 ? (
            <IonRow style={{ margin: 'auto', display: 'block' }}>
              <NoRecords width="60%" smallText={false} />
            </IonRow>
          ) : (
            <div></div>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AllAssets;
