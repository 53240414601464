export const createAssetomponent = [
  {
    title: 'Maintenance.CreateM.PrDet'
  },
  // {
  //   label: 'CreateAC.asset',
  //   field: 'asset',
  //   required: true,
  //   type: 'select',
  //   options: [],
  //   value: '',
  //   requiredText: 'Assets are required'
  // },
  {
    label: 'CreateAC.component_name',
    field: 'component_name',
    required: true,
    type: 'text',
    options: [],
    value: '',
    requiredText: 'Component Name is required'
  },
  {
    label: 'CreateAC.component_number',
    field: 'component_number',
    required: false,
    type: 'text',
    options: [],
    value: '',
    requiredText: 'Component Number is required'
  },
  {
    label: 'CreateAC.barcode_id',
    field: 'barcode_id',
    required: false,
    type: 'barcode',
    options: [],
    value: '',
    requiredText: 'BarCode is required'
  },
  {
    label: 'CreateAC.component_wattage',
    field: 'component_wattage',
    required: false,
    type: 'text',
    options: [],
    value: ''
  },
  {
    label: 'CreateAC.component_quantity',
    field: 'component_quantity',
    required: true,
    type: 'text',
    options: [],
    value: ''
  },
  {
    label: 'CreateAC.component_category',
    field: 'component_category',
    required: false,
    type: 'select',
    options: [],
    value: '',
    requiredText: 'Please select Component Category',
    info: 'compInfo'
  },
  {
    label: 'CreateAC.component_type',
    field: 'component_type',
    required: false,
    type: 'select',
    options: [],
    value: '',
    requiredText: 'Please select type of Component',
    info: 'compInfo'
  },
  {
    label: 'CreateAC.external_component',
    field: 'external_component',
    required: true,
    type: 'select',
    options: ['1', '0'],
    value: '',
    requiredText: 'Please select if its External Component'
  },
  {
    label: 'CreateAC.component_description',
    field: 'component_description',
    required: true,
    type: 'textarea',
    options: [],
    value: '',
    requiredText: 'Component Description required'
  },
  {
    title: 'CreateAC.title1'
  },
  {
    label: 'CreateAC.component_serial_number',
    field: 'component_serial_number',
    required: false,
    type: 'text',
    options: [],
    value: '',
    requiredText: 'Component Serial Number required'
  },
  {
    label: 'CreateAC.component_manufacturing_date',
    field: 'component_manufacturing_date',
    required: false,
    type: 'date',
    options: [],
    value: ''
  },
  {
    label: 'CreateAC.component_manufacturer',
    field: 'component_manufacturer',
    required: false,
    type: 'text',
    options: [],
    value: ''
  },
  {
    label: 'CreateAC.component_warranty_date',
    field: 'component_warranty_date',
    required: false,
    type: 'date',
    options: [],
    value: ''
  },
  {
    label: 'CreateAC.amount',
    field: 'amount',
    required: false,
    type: 'text',
    options: [],
    value: ''
  },
  {
    title: 'CreateAC.title2'
  },
  {
    label: 'CreateAC.component_dimensions',
    field: 'component_dimensions',
    required: false,
    type: 'text',
    options: [],
    value: ''
  },
  {
    label: 'CreateAC.component_material',
    field: 'component_material',
    required: false,
    type: 'text',
    options: [],
    value: ''
  }, {
    label: 'CreateAC.component_weight',
    field: 'component_weight',
    required: false,
    type: 'text',
    options: [],
    value: ''
  }, {
    label: 'CreateAC.component_shape',
    field: 'component_shape',
    required: false,
    type: 'text',
    options: [],
    value: ''
  }, {
    label: 'CreateAC.component_height',
    field: 'component_height',
    required: false,
    type: 'text',
    options: [],
    value: ''
  },
  {
    label: 'CreateAC.health_index',
    field: 'health_index',
    required: false,
    type: 'readonly',
    value: '',
  },
  {
    label: 'CreateAC.risk_index',
    field: 'risk_index',
    required: false,
    type: 'readonly',
    value: '',
  }
]