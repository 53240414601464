import {
  IonContent,
  IonPage,
  IonSkeletonText,
  IonGrid,
  IonCard,
  IonRow,
  IonCol,
  IonText,
  IonLabel,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import TimeAgo from 'timeago-react';
import { useContext } from 'react';
import { AppContext } from '../../providers/AppContext';
import { RefresherEventDetail } from '@ionic/core';
import { chevronDownCircleOutline } from 'ionicons/icons';
import NoRecords from '../../components/NoRecords';

const CompDetails: React.FC = () => {
  const [data, setData] = useState({
    dataSet: [],
    loading: false,
  });
  const { state } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);

  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);

  const getData = () => {
    const url = `/resource/Asset Components?limit_page_length=${state.maxLength}&limit_start=${
      currentPage * 5 + 0
    }&fields=["*"]&order_by=creation%20desc`;

    var config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    axios(config)
      .then((res: any) => {
        if (res && res.data.data && res.data.data.length > 0) {
          setCurrentPage(currentPage + 1);
          var Data = { ...data };
          // @ts-ignore
          Data.dataSet.push(...res.data.data);
          setData(Data);
          setDisableInfiniteScroll(res.data.data.length < state.maxLength);
        } else {
          const value = { ...data };
          setData(value);
          setDisableInfiniteScroll(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ion-refersher
  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    getData();
    setTimeout(() => {
      event.detail.complete();
    }, 2000);
  }

    function searchNext($event: CustomEvent<void>) {
    getData();

    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  const { t, i18n } = useTranslation();

  return (
    <IonPage>
      <Header enableBackButton='goBack' title={t('Component.allComponent')} />
      {isLoading &&
        [1, 2, 3, 4, 5, 6, 7, 8, 9].map((element, key) => {
          return (
            <div key={key} style={{ padding: '1rem' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <IonSkeletonText animated style={{ width: '60%' }} />
                <IonSkeletonText animated style={{ width: '20%' }} />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <IonSkeletonText animated style={{ width: '30%' }} />
                <IonSkeletonText animated style={{ width: '20%' }} />
              </div>
            </div>
          );
        })}
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={t('Utils.pullRefresh')}
            refreshingSpinner="circles"
            refreshingText={t('Utils.refreshing')}
          ></IonRefresherContent>
        </IonRefresher>
        <IonGrid>
          {data.dataSet.map((item: any, key) => {
            return (
              <IonCard key={`asset-card-${key}`} className="data-list" style={{ marginTop: '1rem' }}>
                <IonRow className="ion-text-center">
                  <IonCol style={{ padding: '0.5rem', cursor: 'pointer' }}>
                    <IonLabel color="primary">{item.component_name}</IonLabel>
                    {item.component_quantity ? (
                      <IonText>
                        <p>
                          {t('Assets.Details.Quantity')}: {item.component_quantity}
                        </p>
                      </IonText>
                    ) : (
                      ''
                    )}
                  </IonCol>
                  <IonCol style={{ display: 'block' }}>
                    <Link to={{ pathname: '/comDetail', state: item }}>{t('Assets.Details.Details')}</Link>
                    <p>
                      <TimeAgo
                        datetime={new Date(item.creation.split(' ').join('T') + 'Z').toLocaleString('lt-LT')}
                        locale={i18n.language.toLocaleUpperCase()}
                      />
                    </p>
                  </IonCol>
                </IonRow>
              </IonCard>
            );
          })}
          <IonInfiniteScroll onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Loading more data..."
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
          {!isLoading && data.dataSet.length === 0 ? (
            <IonRow>
              <NoRecords width="30%" smallText={true} />
            </IonRow>
          ) : (
            <div></div>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CompDetails;
