import { useState, useEffect } from 'react';
import {
  IonContent,
  IonPage,
  useIonToast,
  useIonViewWillEnter,
  IonLoading,
  useIonRouter,
  IonButton,
  IonIcon,
  IonSkeletonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonList,
  IonItem,
  IonModal,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonTitle,
  IonLabel,
  IonCheckbox,
} from '@ionic/react';
import Header from '../../components/Header';
import axios from '../../axios';
import { useTranslation } from 'react-i18next';
import { Network } from '@capacitor/network';

import { createServiceFields } from '../../models/createServiceFields';

import { MenuItem, Select } from '@material-ui/core';

import { Camera, CameraResultType } from '@capacitor/camera';
import { getSetComponentCategory, getSetComponentType, getFaultType, getSetAssetNameWithNoService, getSetProjectList } from '../../utils';
import { informationCircle } from 'ionicons/icons';
import { useHistory } from 'react-router';

const ServiceCreate: React.FC = () => {
  const [showLoading, setShowLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(Boolean);

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState();
  const [projectModal, setProjectModal] = useState(false);
  const [shimmer, setShimmer] = useState(false);
  const [infiniteScroll, setInfiniteScroll] = useState(true);


  const history = useHistory();
  const [present] = useIonToast();

  const { t } = useTranslation();


  const [data, setData] = useState(createServiceFields);

  const [currentPage, setCurrentPage] = useState(0);

  const [assets, setAssets] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedAsset, setSelectedAsset] = useState();
  const [assetModalToggle, setSetAssetModalToggle] = useState(false);


  // const handleChangeFile = (event: React.ChangeEvent<{ files: any; id: any }>) => {
  //   var formentry: any = [...data];
  //   const f_index = formentry.findIndex((v: any) => v.field === event.target.id);
  //   var change_form_object = formentry[f_index];
  //   change_form_object.value = event.target.files[0];
  //   formentry[f_index] = change_form_object;
  //   setData(formentry);
  // };

  const handleChange = (event: React.ChangeEvent<{ value: unknown; id: any }>) => {
    var formentry: any = [...data];
    const f_index = formentry.findIndex((v: any) => v.field === event.target.id);
    var change_form_object = formentry[f_index];
    change_form_object.value = event.target.value;
    formentry[f_index] = change_form_object;
    setData(formentry);
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    var formentry: any = [...data];
    // @ts-ignore
    const select = event.target.name.replaceAll('-', '_');
    const f_index = formentry.findIndex((v: any) => v.field === select);
    formentry[f_index].value = event.target.value;
    setData(formentry);
  };


  const url = '/method/preflet_asset.api.create_repair_request';

  useIonViewWillEnter(async () => {
    var Data = [...data];
    try {

      /* Get Component Category */
      const ccat = Data.findIndex((d) => d.field === 'component_name');
      Data[ccat].options = await getSetComponentCategory() || [];
      console.log(ccat)
      setShimmer(true);
      /* Get Asset name */
      const aname = Data.findIndex((d) => d.field === 'asset');
      Data[aname].options = await getSetAssetNameWithNoService('', '', currentPage);// Filter service done assets
      const assetsList = Data[aname].options;
      // @ts-ignore
      setAssets(assetsList);

      /* Get Project by User */
      const pname = Data.findIndex((d) => d.field === 'project');
      const projectList = await getSetProjectList(0)
      // : localStorage.getItem('projectList') || [];
      Data[pname].options = projectList;
      if (projectList.length < 10) {
        setInfiniteScroll(false);
      }
      setProjects(projectList);

      /* Get Component Type */
      const ctype = Data.findIndex((d) => d.field === 'component_type');
      Data[ctype].options = await getSetComponentType();

      /* Get Fault Type */

      const ftype = Data.findIndex((d) => d.field === 'fault_type');
      Data[ftype].options = await getFaultType();

      setData(Data);

    } catch (error) {
      console.log(error);
    } finally {
      setShimmer(false);
    }

    // /* Get Location*/
    // const lname = Data.findIndex((d) => d.field === 'place');
    // Data[lname].options = await getSetLocation();


  });

  useEffect(() => {
    (async () => {
      const internetStatus = await Network.getStatus();
      setIsOnline(internetStatus.connected);

      Network.addListener('networkStatusChange', (status) => {
        setIsOnline(status.connected);
      });
    })();
  }, []);

  async function searchNextProject($event: CustomEvent<void>) {
    try {
      const res = await getSetProjectList(currentPage + 1);
      if (res?.length) {
        //@ts-ignore  
        setProjects([...projects, ...res]);
      }
    } catch (error) {
      console.log(error)
    } finally {
      setShimmer(false)
      setCurrentPage(currentPage + 1);
    }
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  const takePicture = async (imageElementIndex: number) => {
    try {
      const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: true,
        resultType: CameraResultType.Uri,
      });

      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      var imageUrl = image.webPath;

      // Can be set to the src of an image now
      var formentry: any = [...data];
      formentry[imageElementIndex].src = imageUrl;
      formentry[imageElementIndex].format = image.format;
      setData(formentry);
    } catch (e) { }
  };

  const setProjectInValue = (project: any) => {
    var Data = [...data];
    const aname = Data.findIndex((d) => d.field === 'project');
    Data[aname].value = project;
    setSelectedProject(project);
  }

  const Submit = (e: any) => {
    e.preventDefault();
    let valid = true;
    for (const x in data) {
      if (data[x].required && !data[x].value) {
        present({
          message: data[x].requiredText,
          color: 'danger',
          duration: 2000,
        });
        valid = false;
      }
    }

    if (valid) {
      hitCreateApi();
      if (!isOnline) {
        const allData = JSON.parse(localStorage.getItem('allData') || '[]');
        const newData = { data, url };
        allData.push(newData);
        localStorage.setItem('allData', JSON.stringify(allData));
      }
    }
  };

  const hitCreateApi = async () => {
    const formData = new FormData();
    for (const item in data) {
      if (data[item].type === 'file') {
        try {
          // @ts-ignore
          if (data[item].src?.length > 0) {
            const f_index = data.findIndex((v: any) => v.field === 'name1');
            //@ts-ignore
            const response = await fetch(data[item].src?.toString());
            const blob = await response.blob();
            formData.append(
              'upload_attachment',
              new File([blob], `${data[f_index].value}-${data[item].field}.${data[item].format}`, {
                type: `image/${data[item].format}`,
              })
            );
          }
        } catch (e) {
          data[item].value = '';
        }
      } else {
        const field = data[item]?.field;
        const value = data[item]?.value;
        if (typeof field === 'string' && typeof value === 'string') {
          formData.append(field, value);
        }
      }
    }
    var config: any = {
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: formData,
    };
    try {
      setShowLoading(true);
      axios(config)
        .then(async (res) => {
          if (res.status === 200) {
            present({
              message: t('Service.serviceUpdate'),
              duration: 2000,
            });
            const assetData = data.find((v: any) => v.field === 'asset');
            const status = data.find((v: any) => v.field === 'status');
            if (assetData?.value !== undefined && status?.value !== undefined) {
            }
            reset();
            history.push({
              pathname: '/servicehome',
              state: '',
            });
          }
        })
        .catch(function (error) {
          console.log(error?.response?.data);
          const x = error.response.data._server_messages;
          console.log(x?.slice(18)?.slice(0, x?.slice(18)?.indexOf('\\')));
          present({
            message: x?.slice(18)?.slice(0, x?.slice(18)?.indexOf('\\')),
            color: 'danger',
            duration: 2000,
          });
        })
        .finally(() => {
          setShowLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const reset = () => {
    var formentry: any = [...data];
    var x: any;
    for (x in formentry) {
      if (data[x].type === 'select') {
        data[x].value = '';
      } else if (data[x].type === 'file') {
        data[x].value = '';
        data[x].src = '';
        data[x].format = '';
      } else {
        data[x].value = '';
      }
    }
    setData(formentry);
  };


  async function searchNext($event: CustomEvent<void>) {
    console.log()
    const res = await getSetAssetNameWithNoService('', searchText, currentPage + 1);
    console.log('getSetAssetNameWithNoService',res)
    console.log('searchText',searchText)
    if (res.length) {
      //@ts-ignore  
      setAssets([...assets, ...res]);
    }
    setCurrentPage(currentPage + 1);
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  const setAssetInValue = (asset: any) => {
    var Data = [...data];
    const aname = Data.findIndex((d) => d.field === 'asset');
    Data[aname].value = asset;
    setSelectedAsset(asset);
  }

  const handleSearch = async (text: any) => {
    setSearchText(text);
    const res = await getSetAssetNameWithNoService('', text, 1);
    if (res.length) {
      setAssets(res);
    } else {
      setAssets([])
    }
    setCurrentPage(1);
  }

  const Shimmer = () => {
    return (
      <div>
        {
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((element) => {
            return (
              <div
                key={element}
                id="shimmer-body"
                style={{ padding: '1rem' }}
                className="w-50 data-list shimmer-container"
              >
                <div className="flex justify-between">
                  <IonSkeletonText animated style={{ width: '50%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };



  return (
    <IonPage>
      <Header enableBackButton='/servicehome' title={t('Service.Create')} />
      <IonContent>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={t('Utils.pleasewait')}
          duration={5000}
        />
        {/* <div style={{ display: 'flex', margin: 'auto', paddingTop: '2rem' }}>
          <button className="scan-button" onClick={() => setIsScan(true)}>
            <img src={ScanAsset} alt="scan" />
            <p className="scan-text">{t('Assets.Scan')}</p>
          </button>
          {isScan ? <Scan setIsScan={setIsScan} sendIdBackToPage={sendIdBackToPage} /> : null}
        </div>

        <p className="fill-text">{t('Assets.CreateA.Fill')}.</p> */}

        <form className="ion-margin-top" style={{ textAlign: 'center' }} onSubmit={(e) => Submit(e)}>
          {data.map((form_field, index) => {
            if (form_field.title) {
              return (
                <p className="form-title" key={`${form_field.title}-${index}`}>
                  {t(form_field.title)}
                </p>
              );
            } else if (form_field.type === 'text' || form_field.type === 'number') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(form_field.label)}
                    {form_field.required ? '*' : null}
                  </label>
                  <input
                    required={form_field.required}
                    type={form_field.type}
                    className="basic-input"
                    id={form_field.field}
                    placeholder={t(form_field.label)}
                    onChange={handleChange}
                    value={form_field.value}
                  />
                </div>
              );
            } else if (form_field.type === 'textarea') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(form_field.label)}
                    {form_field.required ? '*' : null}
                  </label>
                  <textarea
                    className="textarea"
                    required={form_field.required}
                    id={form_field.field}
                    placeholder={t(form_field.label)}
                    onChange={handleChange}
                    value={form_field.value}
                  />
                </div>
              );
            } else if (form_field.field === 'asset') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(form_field.label)}
                    {form_field.required ? '*' : null}
                  </label>
                  <p onClick={() => setSetAssetModalToggle(!assetModalToggle)} className='basic-input' style={{ marginTop: '0px' }}>{form_field.value || <span style={{ color: 'gray' }}>Select Asset</span>}</p>
                </div>)
            }
            else if (form_field.field === 'project') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(`Assets.CreateA.${form_field.field}`)}
                    {form_field.required ? '*' : null}
                  </label>
                  <p onClick={() => setProjectModal(!projectModal)} className='basic-input' style={{ marginTop: '0px' }}>{form_field.value || <span style={{ color: 'gray' }}>Select Project</span>}</p>
                </div>)
            }
            else if (form_field.type === 'select') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(form_field.label)}
                    {form_field.required ? '*' : null}
                  </label>
                  {form_field.info ? (
                    <div className="tooltip">
                      <IonIcon style={{ marginBottom: '-4px' }} src={informationCircle} />
                      <span className="tooltiptext">{t(`Utils.${form_field.info}`)}</span>
                    </div>
                  ) : null}
                  <Select
                    className="custom-select"
                    required={form_field.required}
                    id={`${form_field.field}_choice`}
                    name={form_field.field}
                    onChange={handleSelectChange}
                    value={form_field.value}
                    disabled={form_field.disabled || false}
                  >
                    {form_field.options &&
                      form_field.options.map(function (opt, optIndex) {
                        return (
                          <MenuItem
                            value={`${opt}`}
                            key={`${form_field.field}-${optIndex}`}
                            id={`${opt}_item_${optIndex}`}
                          >
                            <section style={{ display: 'flex', height: '1rem' }}>
                              {/* <img src={Man} alt="Users" style={{ width: '15px' }} /> */}
                              <p
                                style={{
                                  color: 'rgba(31, 87, 148, 1)',
                                  marginLeft: '0.2rem',
                                  fontSize: '12px',
                                  marginTop: '0px',
                                }}
                              >
                                {t(opt)}
                              </p>
                            </section>
                          </MenuItem>
                        );
                      })}
                  </Select>
                </div>
              );
            } else if (form_field.type === 'date' || form_field.type === 'datetime-local') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(form_field.label)}
                    {form_field.required ? '*' : null}
                  </label>
                  <input
                    required={form_field.required}
                    className="date"
                    id={form_field.field}
                    placeholder={t(form_field.label)}
                    onChange={handleChange}
                    value={form_field.value}
                    type={form_field.type}
                  />
                </div>
              );
            } else if (form_field.type === 'file') {
              return (
                <div key={`${form_field.field}-${index}`}>
                  <IonButton size="small" color="light" onClick={() => takePicture(index)}>
                    {form_field.src ? t('Utils.change_image') : t('Utils.image')}
                  </IonButton>
                  <br />
                  <img src={form_field.src} alt="" />
                </div>
              );
            } else {
              return '';
            }
          })}
          <button className="submit-button" type="submit">
            {t(`Service.ServiceCreate.createSR`)}
          </button>
          <div style={{ marginBottom: '1rem' }}></div>
        </form>

        <IonModal
          isOpen={assetModalToggle}
          onDidDismiss={() => setSetAssetModalToggle(false)}
        ><IonHeader>
            <IonToolbar>
              <IonTitle>Assets</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setSetAssetModalToggle(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonSearchbar
              className="data-list"
              value={searchText}
              // @ts-ignore
              onIonChange={(e) => handleSearch(e.detail.value!)}
            ></IonSearchbar>
            {shimmer && <Shimmer />}
            <IonList>
              {assets && assets.length ? assets.map((asset, index) => (
                <IonItem
                  key={index}
                  onClick={() => {
                    setSetAssetModalToggle(false);
                    setAssetInValue(asset)
                  }}
                >
                  <IonLabel>{asset}</IonLabel>
                  <IonCheckbox
                    slot="end"
                    value={asset}
                    checked={asset === selectedAsset} />
                </IonItem>
              )) :
                <div style={{ margin: 'auto', display: 'block' }}>
                  <p className='text-center' style={{ color: 'gray' }}>No Asset found</p>
                </div>}
            </IonList>

            <IonInfiniteScroll onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
              <IonInfiniteScrollContent
                loadingSpinner="bubbles"
                loadingText="Loading more data..."
              ></IonInfiniteScrollContent>
            </IonInfiniteScroll>
          </IonContent>
        </IonModal>

        <IonModal
          isOpen={projectModal}
          onDidDismiss={() => setProjectModal(false)}
        ><IonHeader>
            <IonToolbar>
              <IonTitle>Projects</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setProjectModal(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            {shimmer && <Shimmer />}
            <IonList>
              {projects.length ? projects.map((project: any, index: number) => (
                <IonItem
                  key={index}
                  onClick={() => {
                    setProjectModal(false);
                    setProjectInValue(project)
                  }}
                >
                  <IonLabel>{project}</IonLabel>
                  <IonCheckbox
                    slot="end"
                    value={project}
                    checked={project === selectedProject} />
                </IonItem>
              )) :
                <div style={{ margin: 'auto', display: 'block' }}>
                  <p className='text-center' style={{ color: 'gray' }}>No Asset found</p>
                </div>}
            </IonList>
            {infiniteScroll ?
              <IonInfiniteScroll onIonInfinite={(e: CustomEvent<void>) => searchNextProject(e)}>
                <IonInfiniteScrollContent
                  loadingSpinner="bubbles"
                  loadingText="Loading more data..."
                ></IonInfiniteScrollContent>
              </IonInfiniteScroll> : ''}
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default ServiceCreate;
