/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import {
  IonContent,
  IonPage,
  IonItem,
  IonToolbar,
  IonSkeletonText,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonModal,
  IonHeader,
  IonButtons,
  IonButton,
  useIonToast,
  IonLoading,
} from '@ionic/react';
import Header from '../../components/Header';
import CreateAsset from '../../assets/CreateAsset.svg';
import { RefresherEventDetail } from '@ionic/core';
import { chevronDownCircleOutline, rocketOutline } from 'ionicons/icons';
import Clock from '../../assets/Clock.svg';
import AssetButton from '../../components/ScanButton';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import NoRecords from '../../components/NoRecords';
import { useHistory } from 'react-router';
import { MenuItem, Select } from '@material-ui/core';
import { createServiceFields, updateServiceRequest } from '../../models/createServiceFields';
import { isFormDataEmpty } from '../../utils';

const ServiceHome: React.FC = () => {
  const { t } = useTranslation();
  const [present] = useIonToast();
  const history = useHistory();
  const [data, setData] = useState([]);

  const [highlights, setHighlights] = useState([]);
  const [loader, setLoader] = useState(true);


  const [isLoading, setIsLoading] = useState(true);
  const [fabModal, setFabModal] = useState(false);
  const [rname, setRname] = useState(''); // Repair Name

  const [_data, set_Data] = useState(createServiceFields);
  const [updateRequest, setUpdateRequest] = useState(updateServiceRequest);
  const [showLoading, setShowLoading] = useState(false);

  const [primaryData, setPrimaryData] = useState({});

  const handleChange = (event: React.ChangeEvent<{ value: unknown; id: any }>) => {
    var formentry: any = [...updateRequest];
    const f_index = formentry.findIndex((v: any) => v.field === event.target.id);
    var change_form_object = formentry[f_index];
    change_form_object.value = event.target.value;
    formentry[f_index] = change_form_object;
    setUpdateRequest(formentry);
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    var formentry: any = [...updateRequest];
    // @ts-ignore
    const select = event.target.name.replaceAll('-', '_');
    const f_index = formentry.findIndex((v: any) => v.field === select);
    formentry[f_index].value = event.target.value;
    setUpdateRequest(formentry);
  };

  const getData = () => {
    const url = `/method/preflet_asset.api.get_assigned_repair_requests?limit=5&start=0`
    var config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    axios(config)
      .then((res: any) => {
        setHighlights(res?.data?.message?.repair_request_list);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.state]);

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    getData();
    setTimeout(() => {
      console.log('Async operation has ended');
      event.detail.complete();
    }, 2000);
  }

  const modalFunc = async (param: any) => {
    setFabModal(true);
    var config: any = {
      method: 'get',
      url: `/resource/Repair Request/${param}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    try {
      const res = await axios(config)
      const temp = res.data.data;
      setPrimaryData(temp);
      var Data = [..._data];
      for (const x in Data) {
        const f = Data[x].field;
        if (f === 'status' || f === 'remarks') {
          var formentry: any = [...updateRequest];
          const f_index = formentry.findIndex((v: any) => v.field === Data[x].field);
          formentry[f_index].value = f && temp[f];
          setUpdateRequest(formentry);
        }
        if (f) {
          Data[x].value = temp[f];
        }
      }
      set_Data(Data);
    } catch (error) {
      console.log(error)
    }
  }


  const repairSubmit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    const api_data: any = {};
    updateRequest.map((_entry: any) => {
      api_data[_entry.field] = _entry.value;
      // @ts-ignore
      if (primaryData[_entry.field] !== _entry.value) {
        formData.append(_entry.field, _entry.value);
      }
    });



    var config: any = {
      method: 'put',
      url: `method/preflet_asset.api.update_repair_request/${rname}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: formData,
    };
    if (!isFormDataEmpty(formData)) {
      try {
        setShowLoading(true);
        const res = await axios(config);
        present({
          message: t('Service.serviceUpdate'),
          duration: 2000,
        });
        setFabModal(false);
        reset();
        history.push({
          pathname: '/servicehome',
          state: '',
        });
      } catch (error) {
        console.log(error)
      } finally {
        setShowLoading(false);
      }
    } else {
      setFabModal(false);
    }
  };



  const reset = () => {
    for (const x in updateRequest) {
      updateRequest[x].value = '';
    }
  };

  const Shimmer = () => {
    return (
      <div>
        {
          [1, 2, 3, 4, 5, 6, 7, 8, 9].map((element) => {
            return (
              <div
                key={element}
                id="shimmer-body"
                style={{ padding: '1rem' }}
                className="w-50 data-list shimmer-container"
              >
                <div className="flex justify-between">
                  <IonSkeletonText animated style={{ width: '50%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
                <IonSkeletonText animated style={{ width: '30%' }} />
              </div>
            );
          })
        }
      </div>
    );
  };

  const Highlights = ({ highlights }: { highlights: any }) => {
    return (
      <div>
        {highlights.length > 0 && highlights.map((item: any, key: number) => {
          return (
            <IonCard id="card-container" key={key}>
              <IonCardHeader>
                <IonCardTitle className="card-heading">
                  <div className="flex justify-between items-center">
                    <Link to={{ pathname: '/serviceDetail', state: item.name, search: 'back=servicehome' }}>
                      <span>{item.name}</span>
                    </Link>
                    <div
                      onClick={() => {
                        modalFunc(item.name);
                        setRname(item.name);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <IonIcon className="view-detail-highight-icon" src={rocketOutline} />
                    </div>
                  </div>
                </IonCardTitle>
                <IonCardSubtitle>
                  {t('Assets.Details.AID')} : {item.asset}
                  {item.target_date && (new Date(item.target_date).toLocaleDateString() < new Date().toLocaleDateString() ?
                    <div className='overdue'>{t('Assets.Details.overdue')}</div> : '')}
                </IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          );
        })}
        {highlights.length > 0 ? <Link to="/serviceHistory" style={{ margin: 'auto', display: 'block' }} className="text-center underline pointer">{t('Utils.viewAll')}</Link> : ''}
      </div>
    );
  };



  return (
    <IonPage>
      <Header enableBackButton='' title="" />
      <IonContent>
        <div style={{ display: 'flex', margin: 'auto', paddingTop: '2rem', marginLeft: '10vw', marginRight: '10vw' }}>
          <Link to="./serviceCreate" style={{ textDecoration: 'none' }}>
            <section
              style={{
                width: '25vw',
                height: '7rem',
                marginLeft: '5px',
                marginRight: '5px',
                textAlign: 'center',
                boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
              }}
            >
              <img src={CreateAsset} alt="scan" style={{ paddingTop: '1rem' }} />
              <p style={{ margin: 'auto', color: 'rgba(29, 80, 141, 1)', marginTop: '2px', fontSize: '11px' }}>
                {t('Service.Create')}
              </p>
            </section>
          </Link>

          <Link to="./serviceHistory" style={{ textDecoration: 'none' }}>
            <section
              style={{
                width: '25vw',
                height: '7rem',
                marginLeft: '5px',
                marginRight: '5px',
                textAlign: 'center',
                boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
              }}
            >
              <img src={Clock} alt="scan" style={{ paddingTop: '1rem' }} />
              <p style={{ margin: 'auto', color: 'rgba(29, 80, 141, 1)', marginTop: '15px', fontSize: '11px' }}>
                {t('Service.History')}
              </p>
            </section>
          </Link>
        </div>

        <IonItem className="heading data-list" style={{ marginTop: '1rem' }}>
          <p style={{ marginBottom: '0.5rem' }}>{t('Service.Recent')}</p>
        </IonItem>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={t('Utils.pullRefresh')}
            refreshingSpinner="circles"
            refreshingText={t('Utils.refreshing')}
          ></IonRefresherContent>
        </IonRefresher>
        {/* {isLoading &&
          [1, 2, 3, 4, 5].map((element, key) => {
            return (
              <div key={key} style={{ padding: '1rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '60%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '30%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
              </div>
            );
          })} */}
        {loader && <Shimmer />}
        {highlights && <Highlights highlights={highlights} />}

        <IonModal isOpen={fabModal} onDidDismiss={() => setFabModal(false)}>
          <IonHeader>
            <IonToolbar color="primary">
              &nbsp; {rname}
              <IonButtons slot="end">
                <IonButton
                  className="line-button"
                  onClick={() => {
                    setFabModal(false);
                    reset();
                  }}
                >
                  <IonIcon name="close" slot="icon-only"></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonLoading
              cssClass="my-custom-class"
              isOpen={showLoading}
              onDidDismiss={() => setShowLoading(false)}
              message={'Please wait...'}
            />
            <div className="data-list">
              {_data.map((item: any, key) => {
                if (item.field === 'target_date' || item.field === 'asset' || item.field === 'date' || item.field === 'name1' || item.field === 'fault_type' || item.field === 'status' || item.field === 'priority') {
                  return (
                    <div className="preview-container" key={key}>
                      <p className='preview-key'>{t(item.label)}</p>
                      <p className='preview-value'>{item.value}</p>
                    </div>
                  )
                }
              })}
            </div>
            <form onSubmit={(e) => repairSubmit(e)}>
              {updateRequest.map((form_field, index) => {
                if (form_field.type === 'text') {
                  return (
                    <div key={`${form_field.label}-${index}`} className="input-container">
                      <label className="label">
                        {t(form_field.label)}
                        {form_field.required ? '*' : ''}
                      </label>
                      <input
                        required={form_field.required}
                        className="basic-input"
                        key={`${form_field.field}-${index}`}
                        id={form_field.field}
                        placeholder={t(form_field.label)}
                        onChange={handleChange}
                        value={form_field.value}
                      />
                    </div>
                  );
                } else if (form_field.type === 'textarea') {
                  return (
                    <div key={`${form_field.label}-${index}`} className="input-container">
                      <label className="label">{t(form_field.label)}
                        {form_field.required ? '*' : ''}</label>
                      <textarea
                        className="textarea"
                        required={form_field.required}
                        key={`${form_field.field}-${index}`}
                        id={form_field.field}
                        placeholder={t(form_field.label)}
                        onChange={handleChange}
                        value={form_field.value}
                      />
                    </div>
                  );
                } else if (form_field.type === 'select') {
                  return (
                    <div key={`${form_field.label}-${index}`} className="input-container">
                      <label className="label">
                        {t(form_field.label)}
                        {form_field.required ? '*' : ''}
                      </label>
                      <Select
                        className="custom-select"
                        required={form_field.required}
                        id={`${form_field.field}_choice`}
                        name={form_field.field}
                        onChange={handleSelectChange}
                        value={form_field.value}
                        key={`${form_field.field}-${index}`}
                      >
                        {form_field.options &&
                          form_field.options.map(function (opt, indexSelect) {
                            return (
                              <MenuItem
                                value={`${opt}`}
                                key={`${form_field.field}-${indexSelect}`}
                                id={`${opt}_item_${indexSelect}`}
                              >
                                <section style={{ display: 'flex', height: '1rem' }}>
                                  {/* <img src={Man} alt="Users" style={{ width: '15px' }} /> */}
                                  <p
                                    style={{
                                      color: 'rgba(31, 87, 148, 1)',
                                      marginLeft: '0.2rem',
                                      fontSize: '12px',
                                      marginTop: '0px',
                                    }}
                                  >
                                    {t(opt)}
                                  </p>
                                </section>
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  );
                } else if (form_field.type === 'date') {
                  return (
                    <div key={`${form_field.label}-${index}`} className="input-container">
                      <label className="label">
                        {t(form_field.label)}
                        {form_field.required ? '*' : ''}
                      </label>
                      <input
                        required={form_field.required}
                        className="date"
                        key={`${form_field.field}-${index}`}
                        id={form_field.field}
                        placeholder={t(form_field.label)}
                        onChange={handleChange}
                        value={form_field.value}
                        type="date"
                      />
                    </div>
                  );
                } else {
                  return '';
                }
              })}
              <button className="submit-button" type="submit">
                {t(`Assets.CreateA.update`)}
              </button>
            </form>
          </IonContent>
        </IonModal>
        <IonToolbar></IonToolbar>
        {!isLoading && data.length === 0 ? (
          <IonRow>
            <NoRecords width="30%" smallText={true} />
          </IonRow>
        ) : (
          <div></div>
        )}
        <AssetButton />
      </IonContent>
    </IonPage>
  );
};
export default ServiceHome;
