export const createSurveyFields = [
  {
    title: 'Survey.identification',
    section: 1,
  },
  {
    label: 'Survey.code',
    field: 'si_survey_code',
    required: true,
    type: 'text',
    options: [],
    value: '',
    disabled: false,
    requiredText: '',
    placeholder: '',
    section: 1,
  },
  {
    title: 'Survey.household',
    section: 2,
  },
  {
    label: 'Survey.floors',
    field: 'hh_no_of_floors',
    required: false,
    type: 'int',
    options: [],
    value: '',
    disabled: false,
    section: 2,
  },
  {
    label: 'Survey.typology',
    field: 'hh_typology',
    required: false,
    type: 'select',
    options: ['T0','T1', 'T2', 'T3', 'T4', 'Superior'],
    value: '',
    disabled: false,
    section: 2,
  },
  {
    label: 'Survey.area',
    field: 'hh_area_m_sq',
    required: false,
    type: 'int',
    options: [],
    value: '',
    disabled: false,
    section: 2,
  },
  {
    title: 'Survey.construction_features',
    section: 3,
  },
  {
    label: 'Survey.decade',
    field: 'cf_building_decade',
    required: false,
    type: 'select',
    options: ['<1950', '1960', '1970', '1980', '1990', '2000', '2010', '2020'],
    value: '',
    disabled: false,
    section: 3,
  },
  {
    label: 'Survey.renovated',
    field: 'cf_the_building_as_been_renovated_since_its_construction',
    required: false,
    type: 'select',
    options: ['Windows', 'Painting', 'Air Conditioning', 'Roof', 'Other'],
    value: [],
    multiple: true,
    disabled: false,
    section: 3,
  },
  {
    label: 'Survey.energetic',
    field: 'cf_energetic_certificate',
    required: false,
    type: 'select',
    options: ['A+', 'A', 'B', 'B-', 'C', 'D', 'E', 'F', 'Does not have'],
    value: '',
    disabled: false,
    section: 3,
  },
  {
    label: 'Survey.type_of_frame',
    field: 'cf_identification_of_the_type_of_frame',
    required: false,
    type: 'select',
    options: ['Metal', 'Wood', 'PVC'],
    value: '',
    disabled: false,
    section: 3,
  },
  {
    label: 'Survey.type_of_glass',
    field: 'cf_identification_of_type_glass',
    required: false,
    type: 'select',
    options: ['Simple', 'Double'],
    value: '',
    disabled: false,
    section: 3,
  },
  {
    label: 'Survey.verification_existence',
    field: 'cf_verification_of_existence_and_type_blindbox',
    required: false,
    type: 'select',
    options: ['Does not have', 'Interior', 'Exterior'],
    value: '',
    disabled: false,
    section: 3,
  },
  {
    label: 'Survey.cf_checking_existence_glazed_openings',
    field: 'cf_checking_existence_glazed_openings',
    required: false,
    type: 'select',
    options: ['With inner protection', 'With outer protection', 'No protection'],
    value:[],
    multiple:true,
    disabled: false,
    section: 3,
  },
  {
    label: 'Survey.cf_measurement_of_the_glazed_area',
    field: 'cf_measurement_of_the_glazed_area',
    required: false,
    type: 'int',
    value: '',
    disabled: false,
    section: 3,
  },
  {
    title: 'Survey.aqs_production_sanitary_hot_water_section',
    section: 4,
  },
  {
    label: 'Survey.aqs_type_of_equipment',
    field: 'aqs_type_of_equipment',
    required: false,
    type: 'select',
    options: ['Boiler', 'Water heater', 'Heater', 'Heating pump'],
    value: '',
    disabled: false,
    section: 4,
  },
  {
    label: 'Survey.file',
    field: 'aqs_type_eq',
    required: false,
    type: 'file',
    value: '',
    src: '',
    format: '',
    disabled: false,
    section: 4,
  },
  {
    label: 'Survey.aqs_number_of_weekly_baths',
    field: 'aqs_number_of_weekly_baths',
    required: false,
    type: 'int',
    value: '',
    disabled: false,
    section: 4,
  },
  {
    label: 'Survey.aqs_equipment_age',
    field: 'aqs_equipment_age',
    required: false,
    type: 'select',
    options: ['< 1 year', '1 year < age < 10year', '10 year < age < 20year', '> 20year'],
    value: '',
    disabled: false,
    section: 4,
  },
  {
    label: 'Survey.file',
    showlabel: true,
    field: 'aqs_up_accessible_nameplate',
    required: false,
    type: 'file',
    value: '',
    src: '',
    format: '',
    disabled: false,
    section: 4,
  },
  {
    label: 'Survey.aqs_timer_on_water_heater',
    field: 'aqs_timer_on_water_heater',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 4,
  },
  {
    label: 'Survey.aqs_appropriate_electrical_connection_of_the_water_heater',
    field: 'aqs_appropriate_electrical_connection_of_the_water_heater',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 4,
  },
  {
    label: 'Survey.file',
    field: 'aqs_up_ele_heat_water',
    required: false,
    type: 'file',
    src: '',
    format: '',
    value: '',
    disabled: false,
    section: 4,
  },
  {
    label: 'Survey.aqs_is_there_gas_extraction_in_the_boilerheater',
    field: 'aqs_is_there_gas_extraction_in_the_boilerheater',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 4,
  },
  {
    label: 'Survey.file',
    field: 'aqs_up_is_there_gas',
    required: false,
    type: 'file',
    src: '',
    format: '',
    value: '',
    disabled: false,
    section: 4,
  },
  {
    label: 'Survey.aqs_is_the_water_heater_placed_in_the_toilet',
    field: 'aqs_is_the_water_heater_placed_in_the_toilet',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 4,
  },
  {
    label: 'Survey.file',
    field: 'aqs_up_the_water_heter',
    required: false,
    type: 'file',
    src: '',
    format: '',
    value: '',
    disabled: false,
    section: 4,
  },
  {
    label: 'Survey.aqs_do_they_have_flow_reducers_installed_in_the_showers',
    field: 'aqs_do_they_have_flow_reducers_installed_in_the_showers',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 4,
  },
  {
    label: 'Survey.aqs_how_many_showers_are_there',
    field: 'aqs_how_many_showers_are_there',
    required: false,
    type: 'int',
    value: '',
    disabled: false,
    section: 4,
  },
  {
    title: 'Survey.climate',
    section: 5,
  },
  {
    label: 'Survey.cl_climate_type_of_equipment',
    field: 'cl_climate_type_of_equipment',
    required: false,
    type: 'select',
    options: [
      'Fireplace',
      'Heat recover',
      'Electric radiator',
      'Air conditioning',
      'Fan Heater',
      'Gas radiator',
      'Bar Heater',
    ],
    value: '',
    disabled: false,
    section: 5,
  },
  {
    label: 'Survey.file',
    field: 'cl_up_climate_type_of_equipment',
    required: false,
    type: 'file',
    src: '',
    format: '',
    value: '',
    disabled: false,
    section: 5,
  },
  {
    label: 'Survey.cl_climate_equipment_age',
    field: 'cl_climate_equipment_age',
    required: false,
    type: 'select',
    options: ['< 1 year', '1 year < age < 10year', '10 year < age < 20year', '> 20year'],
    value: '',
    disabled: false,
    section: 5,
  },
  {
    label: 'Survey.file',
    showlabel: 'true',
    field: 'cl_up_accessible_nameplate',
    required: false,
    type: 'file',
    src: '',
    format: '',
    value: '',
    disabled: false,
    section: 5,
  },
  {
    label: 'Survey.cl_temp_of_air_condition_winter',
    field: 'cl_temp_of_air_condition_winter',
    required: false,
    type: 'int',
    value: '',
    disabled: false,
    section: 5,
  },
  {
    label: 'Survey.cl_temp_of_air_condition_summer',
    field: 'cl_temp_of_air_condition_summer',
    required: false,
    type: 'int',
    value: '',
    disabled: false,
    section: 5,
  },
  {
    title: 'Survey.lighting',
    section: 6,
  },
  {
    subtitle: 'Survey.kitchen',
    section: 6,
  },
  {
    label: 'Survey.lamp_type',
    field: 'lt_kitchen_lamp_type',
    required: false,
    type: 'select',
    options: ['Compact fluorescents','Fluorescent tubes','Halogen','Incandescent','LED'],
    value: '',
    disabled: false,
    section: 6,
  },
  {
    label: 'Survey.lt_kitchen_hours_of_daily_operation',
    field: 'lt_kitchen_hours_of_daily_operation',
    required: false,
    type: 'int',
    value: '',
    disabled: false,
    section: 6,
  },
  {
    subtitle: 'Survey.living_room',
    section: 6,
  },
  {
    label: 'Survey.lamp_type',
    field: 'lt_living_room_lamp_type',
    required: false,
    type: 'select',
    options: ['Compact fluorescents','Fluorescent tubes','Halogen','Incandescent','LED'],
    value: '',
    disabled: false,
    section: 6,
  },
  {
    label: 'Survey.lt_living_room_hours_of_daily_operation',
    field: 'lt_living_room_hours_of_daily_operation',
    required: false,
    type: 'int',
    value: '',
    disabled: false,
    section: 6,
  },
  {
    subtitle: 'Survey.room',
    section: 6,
  },
  {
    label: 'Survey.lamp_type',
    field: 'lt_bedroom_lamp_type',
    required: false,
    type: 'select',
    options: ['Compact fluorescents','Fluorescent tubes','Halogen','Incandescent','LED'],
    value: '',
    disabled: false,
    section: 6,
  },
  {
    label: 'Survey.lt_kitchen_hours_of_daily_operation',
    field: 'lt_bedroom_hours_of_daily_operation',
    required: false,
    type: 'int',
    value: '',
    disabled: false,
    section: 6,
  },
  {
    title: 'Survey.home_appliances',
    section: 7,
  },
  {
    label: 'Survey.ha_do_u_have_a_washing_machine',
    field: 'ha_do_u_have_a_washing_machine',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 7,
  },
  {
    label: 'Survey.ha_age_washing_machine',
    field: 'ha_age_washing_machine',
    required: false,
    type: 'select',
    options: ['< 1 year', '1 year < age < 10year', '10 year < age < 20year', '> 20year'],
    value: '',
    disabled: false,
    section: 7,
  },
  {
    label: 'Survey.ha_do_you_have_a_dryer',
    field: 'ha_do_you_have_a_dryer',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 7,
  },
  {
    label: 'Survey.ha_dryer_age',
    field: 'ha_dryer_age',
    required: false,
    type: 'select',
    options: ['< 1 year', '1 year < age < 10year', '10 year < age < 20year', '> 20year'],
    value: '',
    disabled: false,
    section: 7,
  },
  {
    label: 'Survey.ha_do_u_have_dishwasher',
    field: 'ha_do_u_have_dishwasher',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 7,
  },
  {
    label: 'Survey.ha_dishwasher_age',
    field: 'ha_dishwasher_age',
    required: false,
    type: 'select',
    options: ['< 1 year', '1 year < age < 10year', '10 year < age < 20year', '> 20year'],
    value: '',
    disabled: false,
    section: 7,
  },
  {
    label: 'Survey.ha_number_of_refrigerators',
    field: 'ha_number_of_refrigerators',
    required: false,
    type: 'int',
    value: '',
    disabled: false,
    section: 7,
  },
  {
    label: 'Survey.ha_refrigerator_age',
    field: 'ha_refrigerator_age',
    required: false,
    type: 'select',
    options: ['< 1 year', '1 year < age < 10year', '10 year < age < 20year', '> 20year'],
    value: '',
    disabled: false,
    section: 7,
  },
  {
    label: 'Survey.ha_is_refrigerator_away_frm_heat_sources',
    field: 'ha_is_refrigerator_away_frm_heat_sources',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 7,
  },
  {
    label: 'Survey.ha_number_of_freezers',
    field: 'ha_number_of_freezers',
    required: false,
    type: 'int',
    value: '',
    disabled: false,
    section: 7,
  },
  {
    label: 'Survey.ha_age_of_freezer',
    field: 'ha_age_of_freezer',
    required: false,
    type: 'select',
    options: ['< 1 year', '1 year < age < 10year', '10 year < age < 20year', '> 20year'],
    value: '',
    disabled: false,
    section: 7,
  },
  {
    label: 'Survey.ha_space_btwn_the_back_of_the_refrigerator_and_wall',
    field: 'ha_space_btwn_the_back_of_the_refrigerator_and_wall',
    required: false,
    type: 'int',
    value: '',
    disabled: false,
    section: 7,
  },
  {
    label: 'Survey.ha_do_refrigerator_rubbers_seal_well',
    field: 'ha_do_refrigerator_rubbers_seal_well',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 7,
  },
  {
    label: 'Survey.ha_do_u_have_an_oven',
    field: 'ha_do_u_have_an_oven',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 7,
  },
  {
    label: 'Survey.ha_does_the_oven_have_light',
    field: 'ha_does_the_oven_have_light',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 7,
  },
  {
    title: 'Survey.pathologies',
    section: 8,
  },
  {
    label: 'Survey.pl_are_there_broken_frames_or_glass',
    field: 'pl_are_there_broken_frames_or_glass',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 8,
  },
  {
    label: 'Survey.file',
    field: 'pl_up_are_there_broken_frames_or_glass',
    required: false,
    type: 'file',
    src: '',
    format: '',
    value: '',
    disabled: false,
    section: 8,
  },
  {
    label: 'Survey.pl_degraded_coatings',
    field: 'pl_degraded_coatings',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 8,
  },
  {
    label: 'Survey.file',
    field: 'pl_up_degraded_coatings',
    required: false,
    type: 'file',
    src: '',
    format: '',
    value: '',
    disabled: false,
    section: 8,
  },
  {
    label: 'Survey.pl_existence_of_mold_on_the_walls',
    field: 'pl_existence_of_mold_on_the_walls',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 8,
  },
  {
    label: 'Survey.file',
    field: 'pl_up_existence_of_mold_on_the_walls',
    required: false,
    type: 'file',
    src: '',
    format: '',
    value: '',
    disabled: false,
    section: 8,
  },
  {
    label: 'Survey.pl_existence_of_cracks_in_the_walls',
    field: 'pl_existence_of_cracks_in_the_walls',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 8,
  },
  {
    label: 'Survey.file',
    field: 'pl_up_existence_of_cracks_in_the_walls',
    required: false,
    type: 'file',
    src: '',
    format: '',
    value: '',
    disabled: false,
    section: 8,
  },
  {
    title: 'Survey.phantom',
    section: 9,
  },
  {
    label: 'Survey.pc_do_you_have_a_rooter',
    field: 'pc_do_you_have_a_rooter',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 9,
  },
  {
    label: 'Survey.pc_check_stand_by_existence',
    field: 'pc_check_stand_by_existence',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 9,
  },
  {
    title: 'Survey.ventilation',
    section: 10,
  },
  {
    label: 'Survey.vl_check_for_cracks_in_glazed_doors_and_openings',
    field: 'vl_check_for_cracks_in_glazed_doors_and_openings',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 10,
  },
  {
    title: 'Survey.renewable_energy',
    section: 11,
  },
  {
    label: 'Survey.re_renewable_energy_production_equipment',
    field: 're_renewable_energy_production_equipment',
    required: false,
    type: 'checkbox',
    value: false,
    disabled: false,
    section: 11,
  },
];
