/* eslint-disable array-callback-return */
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { IonButton, IonButtons, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPopover, IonSelect, IonSelectOption, IonSkeletonText, IonToolbar, useIonToast } from '@ionic/react';
import { MenuItem, Select } from '@material-ui/core';
import { barcodeOutline, chevronDownOutline, close, cogOutline, createOutline, personAddOutline, receiptOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';

import axios from '../axios';
import { createAssetFields, quickAssetUpdateFields } from '../models/createAssetFields';
import { AppContext } from '../providers/AppContext';
import { getAssigneeList, getDashboard } from '../utils';
import AssetTimeline from './AssetTimeline';
import Shimmer from './Shimmer';
import toolkit from '../assets/toolkitBlue.svg';
import calender from '../assets/CalenderBlue.svg';
import { ReactComponent as RepairIconBlue } from '../assets/toolkitBlue.svg';
import { ReactComponent as RepairIconRed } from '../assets/toolkitRed.svg';
import { ReactComponent as MaintenanceIconBlue } from '../assets/CalenderBlue.svg';
import { ReactComponent as MaintenanceIconRed } from '../assets/CalenderRed.svg';
import { Link } from 'react-router-dom';

const AssetOnMapModal: React.FC<{ id: String; assetLayoutModal: Boolean; fullScreen: Boolean }> = ({ id = '', assetLayoutModal = false, fullScreen = false }) => {

  const { t } = useTranslation();
  const ref = useRef<any>(null);



  const { dispatch } = useContext(AppContext);

  const [assetModal, setAssetModal] = useState(true)
  const [quickUpdateToggle, setQuickUpdateToggle] = useState(false)
  const [_data, set_Data] = useState(quickAssetUpdateFields);
  // hide the asset name for the modal
  const showAssetFields = createAssetFields.filter((item) => {
    return item.field !== 'asset_name';
  });
  const [assetDetail, setAssetDetail] = useState(showAssetFields)
  const [asToggle, setAsToggle] = useState(false);
  const [color, setColor] = useState('');
  const [scanToggle, setScanToggle] = useState(false);
  const [shimmer, setShimmer] = useState(false);
  const [loader, setLoader] = useState(false)
  const [modalToggle, setModalToggle] = useState(false);
  const [assignFragment, setAssignFragment] = useState(false);
  const [user, setUser] = useState([]);
  const [assignee, setAssignee] = useState('');
  const [activeBRCModal, setActiveBRCModal] = useState(''); // Active Barcode Reader Modal
  const [assetC, setAssetC] = useState('');
  const [serviceFragment, setServiceFragment] = useState(false);
  const [maintenanceFragment, setMaintenanceFragment] = useState(false);
  const [repairRequest, setRepairRequest] = useState([]);
  const [maintenance, setMaintenance] = useState([]);
  const [apiLoader, setApiLoader] = useState(false);
  const [modelo937Fragment, setModelo937Fragment] = useState(false);
  const [modelo937, setModelo937] = useState([]);
  const [optModalFullScr, setOptModalFullScr] = useState(false);
  const [loaderModal, setLoaderModal] = useState(false);
  const [currentSelected, setCurrentSelected] = useState('');

  const [present] = useIonToast();

  const [update, setUpdate] = useState({
    status: '',
    asset_state: '',
    brand: '',
    model: '',
    year: '',
    comment: '',
    asset_component: '',
    attachment: {},
    barcode_id: ''
  });

  const handleChangeQuickUpdate = (event: React.ChangeEvent<{ value: unknown; id: any }>) => {
    var formentry: any = { ...update };
    if (event.target.id in formentry) {
      formentry[event.target.id] = event.target.value;
    }
    setUpdate(formentry);
  };


  useEffect(() => {
    getAssetDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAssetDetail = () => {
    if (id) {
      setShimmer(true);
      axios({
        method: 'get',
        url: `resource/Asset/${id}`,
        headers: {
          Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
        },
      })
        .then((res) => {
          const temp = res?.data?.data;
          if (temp?.asset_category) {
            getAssetCategoryColor(temp.asset_category || '')
          }
          setAssetDetail(temp);
          setModalToggle(true);
        })
        .catch((err) => {
          console.log(err);
        }).finally(() => {
          setShimmer(false);
        });
    }
  }

  const getAssetComponentfromBarcodeId = (id: string) => {
    axios({
      method: 'get',
      url: `resource/Asset Components?fields=["name"]&filters=[["barcode_id","=","${id}"]]`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    })
      .then((res) => {
        setAssetC(res?.data?.data[0]?.name)
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
      });
  }

  const quickStatus = async () => {

    var formentry: any = { ...update };
    //@ts-ignore
    formentry.status = assetDetail.status
    //@ts-ignore
    formentry.asset_state = assetDetail.asset_state
    //@ts-ignore
    formentry.brand = assetDetail.brand
    //@ts-ignore
    formentry.model = assetDetail.model
    //@ts-ignore
    formentry.year = assetDetail.year
    //@ts-ignore
    formentry.comment = assetDetail.comment
    //@ts-ignore
    formentry.attachment.value = assetDetail.attachment
    //@ts-ignore
    formentry.barcode_id = assetDetail.barcode_id
    setUpdate(formentry);
  };

  const getAssetCategoryColor = (asset_category: string) => {
    axios({
      method: 'get',
      url: `resource/Asset Category/${asset_category}`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    })
      .then((res) => {
        setColor(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const SubmitStatusUpdate = async (e: any) => {
    e.preventDefault();
    uploadQSFiles();
  };

  const uploadQSFiles = async () => {
    const formData = new FormData();
    try {
      // @ts-ignore
      if (update['attachment']['src']?.length > 0) {
        //@ts-ignore
        const response = await fetch(update['attachment'].src?.toString());
        const blob = await response.blob();
        //@ts-ignore
        formData.append(
          'file',
          new File([blob], `${ //@ts-ignore
            update['attachment']['src']}-${new Date().getTime()}.${update['attachment'].format}`, {
            //@ts-ignore
            type: `image/${update['attachment'].format}`,
          })
        );

        formData.append('is_private', '0');
        formData.append('folder', 'Home');
        formData.append('doctype', 'Asset');
        var config: any = {
          method: 'put',
          url: '/method/upload_file',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
          },
          data: formData,
        };
        setLoader(true);
        const res = await axios(config);
        //@ts-ignore
        update['attachment']['src'] = res.data.message.file_url;
        formData.delete('file');
        set_Data(_data);
      }
    } catch (e) {
      //@ts-ignore
      update['attachment']['src'] = '';
    }
    hitQSupdate();
  };

  const hitQSupdate = async () => {

    const api_data: any = {};
    _data.map((_entry: any) => {
      if (_entry.field === 'asset_component') {

        api_data[_entry.field] = [{ asset_component: assetC }];
      } else if (
        _entry.field === 'status' ||
        _entry.field === 'asset_state' ||
        _entry.field === 'brand' ||
        _entry.field === 'model' ||
        _entry.field === 'year' ||
        _entry.field === 'barcode_id'
      ) {
        if (_entry.field === 'barcode_id') {
          //@ts-ignore
          api_data['equipment_number'] = update[_entry.field];
        }
        //@ts-ignore
        api_data[_entry.field] = update[_entry.field];
      }
      else if (_entry.field === 'attachment') {
        //@ts-ignore
        api_data[_entry.field] = update[_entry.field]['src']
      }
      return null;
    });

    var config: any = {
      method: 'put',
      url: `resource/Asset/${
        //@ts-ignore
        assetDetail.asset_name}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: api_data,
    };

    // Update status api
    try {
      setLoader(true);
      await axios(config);
    } catch (error) {
      console.log(error);
    }

    // Update comment
    try {
      setLoader(true);
      var config_comment: any = {
        method: 'post',
        url: 'resource/Comment',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
        },
        data: {
          comment_type: 'Comment',
          reference_doctype: 'Asset',
          //@ts-ignore
          reference_name: assetDetail?.asset_name,
          content: update.comment,
        },
      };

      await axios(config_comment);
    } catch (error) {
      console.log(error);
    } finally {
      getDashboardData(); // Refresh Dashboard
      //@ts-ignore
      getAssetDetail(assetDetail.asset_name);
      setQuickUpdateToggle(false);
      setAssetModal(true);
      setLoader(false);
      present({
        message: t('Assets.CreateA.assetUpdateSuccesss'),
        duration: 2000,
      });
      // reset modal
      setUpdate({
        status: '',
        asset_state: '',
        brand: '',
        model: '',
        year: '',
        comment: '',
        asset_component: '',
        attachment: {},
        barcode_id: ''
      });
    }
  }

  const getDashboardData = async () => {
    const res = await getDashboard('', 'Need Installation');
    dispatch({ type: 'dashboard', payload: res || {} });
    //@ts-ignore
    dispatch({ type: 'map', payload: res['geo_map_data'] || [] });
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAssignee(event.target.value as string);
  };

  const getScan = (field: any) => {
    setScanToggle(true);
    setActiveBRCModal(field)
  };

  const takeQSPicture = async (imageElementIndex: number) => {
    try {
      const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
      });
      var imageUrl = image.webPath;

      // Can be set to the src of an image now
      var formentry: any = { ...update }
      formentry['attachment'].value = null;
      formentry['attachment'].src = imageUrl;
      formentry['attachment'].format = image.format;
      setUpdate(formentry);
    } catch (e) {
      //@ts-ignore
      console.log('Error: ', e);
      // @ts-ignore
      if (e.toString().includes('cancelled')) {
        //@ts-ignore
        present({
          message: t('Utils.cameraCancel'),
          color: 'danger',
          duration: 2000,
        });
      }
      //@ts-ignore
      else if (e.toString().includes('denied') || e.includes('access')) {
        //@ts-ignore
        present({
          message: t('Utils.cameraErrorPermission'),
          color: 'danger',
          duration: 2000,
        });
      } else {
        //@ts-ignore
        present({
          message: e,
          color: 'danger',
          duration: 2000,
        });
      }
    }
  };

  const emptyQSImg = (index: number) => {
    var tempData = { ...update };
    tempData.attachment = {};
    setUpdate(tempData);
  };

  const loadAssignee = async () => {
    const assignee = await getAssigneeList();
    setUser(assignee);
  }

  const assignUser = async () => {
    setLoader(true);
    var config: any = {
      method: 'post',
      url: `/method/frappe.desk.form.assign_to.add?assign_to=["${assignee}"]&doctype=Asset&name=${id}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    try {
      await axios(config);
      present({
        message: t('Assets.CreateA.assetAssignedSuccess'),
        duration: 2000,
      });
      setAssetModal(true)
      setAssignFragment(false)
      getDashboardData();
      setAssignee('');
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }

  }

  const handleScan = (data: any,) => {
    console.log('handleScan',data)
    if (data && activeBRCModal === 'asset_component') {
      getAssetComponentfromBarcodeId(data);
    }
    if (data) {
      try {
        var tempData = { ...update };
        //@ts-ignore
        tempData[activeBRCModal] = data;
        setUpdate(tempData);
      } catch {
        present({
          message: t('Utils.fetchFail'),
          color: 'danger',
          duration: 4000,
        });
      } finally {
        setScanToggle(false);
        setActiveBRCModal('');
      }
    }
  };

  const handleChange = async (status: string, val: any, item: any) => {
    const formData = new FormData();
    /* set status in value of status in item */
    const tempData = [...maintenance];
    const index = tempData.findIndex((item: any) => item.name === val);
    if (index !== -1) {
      // @ts-ignore
      tempData[index].status = status;
      setMaintenance(tempData);
    }

    /* set status in value of status in item */
    const tempData1 = [...repairRequest];
    const index1 = tempData1.findIndex((item: any) => item.name === val);
    if (index1 !== -1) {
      // @ts-ignore
      tempData1[index1].status = status;
      setRepairRequest(tempData1);
    }

    setShimmer(false);
    if (fullScreen) {
      console.log('full screen')
      setCurrentSelected(val);
    }
    formData.append('status', status);
    var config: any = {
      method: 'put',
      url: `method/${maintenanceFragment ? 'preflet_asset.api.update_maintenance_request' : 'preflet_asset.api.update_repair_request'}/${val}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: formData,
    };
    try {
      if (fullScreen) {
        setLoaderModal(true);
      }
      await axios(config);
      present({
        message: maintenanceFragment ? t('Maintenance.maintenanceUpdate') : t('Service.serviceUpdate'),
        duration: 2000,
      });
      if (fullScreen && optModalFullScr) {
        maintenanceFragment ? getMaintenanceById() : getServiceRequestById();
      }
    } catch (error) {
      console.log(error);
      present({
        message: 'Failed to update',
        color: 'danger',
        duration: 2000,
      });
    } finally {
      if (fullScreen) {
        setOptModalFullScr(false);
        setLoaderModal(false);
        setCurrentSelected('');
      }
    }
  }

  const clearFields = () => {
    setMaintenance([]);
    setRepairRequest([]);
  }

  const openMap = (coordinates: any) => {
    let url = 'https://www.google.com.sa/maps/search/' + coordinates;
    window.open(url, '_blank');
  };


  const getServiceRequestById = async () => {
    setApiLoader(true);
    axios({
      method: 'get',
      // @ts-ignore
      url: `/method/preflet_asset.api.get_assigned_repair_requests?asset=${assetDetail.name}`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    })
      .then((res) => {

        setRepairRequest(res?.data?.message?.repair_request_list)
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setApiLoader(false);
      });
  };

  const getMaintenanceById = async () => {
    setApiLoader(true);
    axios({
      method: 'get',
      // @ts-ignore
      url: `/method/preflet_asset.api.get_assigned_maintenance?asset=${assetDetail.name}`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    })
      .then((res) => {
        setMaintenance(res?.data?.message?.maintenance_list)
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setApiLoader(false);
      });
  };

  const loadModelo937 = async () => {
    setApiLoader(true);
    axios({
      method: 'get',
      // @ts-ignore
      url: `/resource/Model%20937?fields=[%22nome%22,%22name%22,%22asset%22]&filters=[[%22asset%22,%22=%22,%22${assetDetail.name}%22]]`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    })
      .then((res) => {
        setModelo937(res?.data?.data)
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setApiLoader(false);
      });
  }

  return (
    <div>
      {modalToggle ? <div className='asset-modal-layout' style={{ height: '100%', paddingBottom: '2rem', border: '4px solid #1d508d', boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)' }}>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={loader}
          onDidDismiss={() => setLoader(false)}
          message={t('Utils.pleasewait')}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <IonIcon src={chevronDownOutline} className="pointer" style={{ fontSize: '25px' }} onClick={() => {
            setQuickUpdateToggle(false)
            setModalToggle(false)
          }
          } />
        </div>
        <div>
          <p className="preview-title" style={{ width: '50%' }}>{
            //@ts-ignore
            assetDetail['asset_name']}</p>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '0.5rem' }}>
            {assetModal ?
              <div className='action-icon-container'>
                {
                  // @ts-ignore
                  assetDetail['status'].includes('Need Repairing') || assetDetail['status'].includes('Installed But Not Operational') || assetDetail['status'].includes('Need Replacement') || assetDetail['status'].includes('Need Maintenance And Repair') ?
                    <RepairIconRed style={{ width: '20px', height: '20px', marginRight: '10px' }}
                      className="action-icon-map-modal"
                      onClick={() => {
                        setAssignFragment(false);
                        setQuickUpdateToggle(false)
                        setAssetModal(false)
                        setServiceFragment(true)
                        setMaintenanceFragment(false)
                        getServiceRequestById();
                      }} /> : <RepairIconBlue className="action-icon-map-modal"
                        onClick={() => {
                          setAssignFragment(false);
                          setQuickUpdateToggle(false)
                          setAssetModal(false)
                          setServiceFragment(true)
                          setMaintenanceFragment(false)
                          getServiceRequestById();
                        }} />
                }
                {
                  // @ts-ignore
                  assetDetail['status'].includes('Need Maintenance') || assetDetail['status'].includes('Need Maintenance And Repair') ?
                    <MaintenanceIconRed style={{ width: '20px', height: '20px', marginRight: '10px' }}
                      className="action-icon-map-modal"
                      onClick={() => {
                        setAssignFragment(false);
                        setQuickUpdateToggle(false)
                        setAssetModal(false)
                        setServiceFragment(false)
                        setMaintenanceFragment(true)
                        getMaintenanceById();
                      }} /> : <MaintenanceIconBlue className="action-icon-map-modal"
                        onClick={() => {
                          setAssignFragment(false);
                          setQuickUpdateToggle(false)
                          setAssetModal(false)
                          setServiceFragment(false)
                          setMaintenanceFragment(true)
                          getMaintenanceById();
                        }} />
                }

                <IonIcon
                  aria-details='assign-asset'
                  aria-label='assign-asset'

                  className="action-icon-map-modal"
                  src={receiptOutline}
                  onClick={() => {
                    setAssignFragment(false);
                    setQuickUpdateToggle(false)
                    setAssetModal(false)
                    setModelo937Fragment(true)
                    loadModelo937();
                  }} />

                <IonIcon
                  aria-details='assign-asset'
                  aria-label='assign-asset'

                  className="action-icon-map-modal"
                  src={personAddOutline}
                  onClick={() => {
                    setAssignFragment(true);
                    setQuickUpdateToggle(false)
                    setAssetModal(false)
                    loadAssignee();
                  }} />
                <IonIcon
                  onClick={() => {
                    setQuickUpdateToggle(true);
                    quickStatus();
                    setAssetModal(false)
                  }}
                  className="action-icon-map-modal"
                  src={createOutline}
                /> </div> : <p onClick={() => {
                  setQuickUpdateToggle(false)
                  setAssetModal(true)
                  getAssetDetail()
                  setAssignFragment(false)
                  setServiceFragment(false)
                  setMaintenanceFragment(false)
                  setModelo937Fragment(false)
                  clearFields()
                }} style={{ fontSize: '15px', textDecoration: 'underline', color: '#1d508d', cursor: 'pointer' }}>{t('Utils.Back')}</p>
            }
          </div>

        </div>
        {assignFragment ? <div>
          <div
            id="assign-asset"
            className="input-container"
          >
            <label className="label">
              {t(`Assign`)}
            </label>
            <Select
              className="custom-select"
              name={assignee}
              onChange={handleSelectChange}
              value={assignee}
            >
              {user.map(function (opt, indexSelect) {
                return (
                  <MenuItem
                    value={`${opt}`}
                    key={indexSelect + '-user'}
                    id={`${opt}_item_${indexSelect}`}
                  >
                    <section
                      id={`section-${opt}-${indexSelect}`}
                      style={{ display: 'flex', height: '1rem' }}
                    >
                      <p
                        id={`value-${opt}-${indexSelect}`}
                        style={{
                          color: 'rgba(31, 87, 148, 1)',
                          marginLeft: '0.2rem',
                          fontSize: '12px',
                          marginTop: '0px',
                        }}
                      >
                        {opt}
                      </p>
                    </section>
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <button className="submit-button" type="submit" onClick={(e) => assignUser()} style={{ marginBottom: '20rem' }}>
            {t('Utils.assignUser')}
          </button>
        </div> : ''}
        {maintenanceFragment ?
          <div className="preview-title" style={{ padding: '0', margin: '0', display: 'flex', alignItems: 'center' }}>
            <img
              style={{ width: '20px', height: '20px', marginRight: '10px', fill: 'currentColor', color: '#1d508d' }}
              src={calender}
            />
            <span>{t('Maintenance.Maintenance Request')}</span>
          </div> : ''}
        {serviceFragment ?
          <div className="preview-title" style={{ padding: '0', margin: '0', display: 'flex', alignItems: 'center' }}>
            <img
              style={{ width: '20px', height: '20px', marginRight: '10px', fill: 'currentColor', color: '#1d508d' }}
              src={toolkit}
              className="action-icon-map-modal"
            />
            <span>{t('Home.map.bar')}</span>
          </div> : ''}
        {serviceFragment ?
          repairRequest.length > 0 ?
            repairRequest.map((item, index) => {
              return (
                <div key={index + '-1-repair'}>
                  <IonList style={{ marginTop: '4px' }}>
                    <IonItem lines='none'>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
                        className="ion-margin data-list"
                      >
                        <IonLabel>
                          <p style={{ fontSize: '16px', color: '#1d508d', cursor: 'pointer' }}>{item['name']}</p>
                          <p className='preview-key'>{item['name1']}</p>
                        </IonLabel>
                        {fullScreen ? <p onClick={() => {
                          setOptModalFullScr(!optModalFullScr)
                          setCurrentSelected(item['name'])
                        }} className='optionMapClickable'>{t('Service.' + item['status'])}</p> :
                          <IonSelect interface='action-sheet' style={{ color: '#1d508d', fontSize: '14px' }} value={item['status']} placeholder='Change Status'
                            onIonChange={(e) => handleChange(e.detail.value, item['name'], repairRequest)}>
                            <IonSelectOption value="New">{t('Service.New')}</IonSelectOption>
                            <IonSelectOption value="In Progress">{t('Service.In Progress')}</IonSelectOption>
                            <IonSelectOption value="Faulty">{t('Service.Faulty')}</IonSelectOption>
                            <IonSelectOption value="Completed">{t('Service.Completed')}</IonSelectOption>
                          </IonSelect>
                        }
                      </div>
                    </IonItem>
                  </IonList>
                  {!fullScreen &&
                    <div className='view-details-modal'
                      onClick={() => {
                        setQuickUpdateToggle(false)
                        setModalToggle(false)
                      }}>
                      <Link to={{ pathname: '/serviceDetail', state: item['name'] }} >{t('Utils.viewDetails')}</Link></div>}
                  {fullScreen && optModalFullScr && currentSelected === item['name'] ?
                    <div className='mapModalFullScreen'>
                      <div key="New" className='mapOptionsTitle'>{t('Maintenance.New')}<input className="radioMapModal" onChange={(e) => handleChange(e.target.value, item['name'], repairRequest)} type="radio" value="New" checked={item['status'] === 'New'} /></div>
                      <div key="Progress" className='mapOptionsTitle'>{t('Maintenance.In Progress')}<input className="radioMapModal" onChange={(e) => handleChange(e.target.value, item['name'], repairRequest)} value="In Progress" type="radio" checked={item['status'] === 'In Progress'} /></div>
                      <div key="Faulty" className='mapOptionsTitle'>{t('Maintenance.Faulty')}<input className="radioMapModal" onChange={(e) => handleChange(e.target.value, item['name'], repairRequest)} type="radio" value="Faulty" checked={item['status'] === 'Faulty'} /></div>
                      <div key="Completed" className='mapOptionsTitle'>{t('Maintenance.Completed')}<input className="radioMapModal" onChange={(e) => handleChange(e.target.value, item['name'], repairRequest)} type="radio" value="Completed" checked={item['status'] === 'Completed'} /></div>
                    </div> : null}
                  {loaderModal ? <div className='toastMapModal'>{t('Utils.updating')}</div> : null}
                </div>
              )
            })
            :
            apiLoader ? <IonLabel>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem' }}>
                <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
                <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem' }}>
                <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
                <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem' }}>
                <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
                <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem' }}>
                <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
                <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
              </div>
            </IonLabel> :
              <div style={{ textAlign: 'center', margin: 'auto', marginTop: '5rem', marginBottom: '8rem' }}>
                <img
                  style={{ margin: 'auto', width: '50px', opacity: '0.5', marginRight: '10px', fill: 'currentColor', color: '#1d508d' }}
                  src={toolkit}
                  className="action-icon-map-modal"
                />
                <p style={{ textAlign: 'center', opacity: '0.5' }}>{t('Service.No Service Request')}</p>
              </div>
          : null}

        {modelo937Fragment ?
          <div className="preview-title" style={{ padding: '0', margin: '0', display: 'flex', alignItems: 'center' }}>
            <IonIcon
              style={{ width: '20px', height: '20px', marginRight: '10px', fill: 'currentColor', color: '#1d508d' }}
              className="action-icon-map-modal"
              src={receiptOutline}
            />
            <span>{t('Model937.model937')}</span>
          </div> : ''}

        {modelo937Fragment ?
          apiLoader && !modelo937.length ? <IonLabel>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem' }}>
              <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
              <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem' }}>
              <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
              <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem' }}>
              <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
              <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem' }}>
              <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
              <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
            </div>
          </IonLabel> :
            modelo937.length > 0 ?
              modelo937.map((item, index) => {
                return (
                  <IonList key={index + '-modelo-937'}>
                    <IonItem>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
                        className="ion-margin data-list"
                      >
                        <IonLabel>
                          <p style={{ fontSize: '16px', color: '#1d508d', cursor: 'pointer' }}>{item['nome']}</p>
                          <p className='preview-key'>{item['name']}</p>
                        </IonLabel>
                        <IonLabel>
                          {!fullScreen &&
                            <Link to={{ pathname: '/model-detail', state: item['name'] }}>{t('Utils.viewDetails')}</Link>}
                        </IonLabel>
                      </div>
                    </IonItem>
                  </IonList>
                )
              }) :
              <div style={{ textAlign: 'center', margin: 'auto', marginTop: '5rem', marginBottom: '8rem' }}>
                <IonIcon
                  style={{ margin: 'auto', width: '5rem', opacity: '0.5', marginRight: '10px', fill: 'currentColor', color: '#1d508d' }}
                  className="action-icon-map-modal"
                  src={receiptOutline}
                />
                <p style={{ textAlign: 'center', opacity: '0.5' }}>{t('Model937.noModel937')}</p>
              </div>
          : null}

        {maintenanceFragment ?
          maintenance.length > 0 ?
            maintenance.map((item) => {
              return (
                <div key={item['name1']}>
                  <IonList style={{ marginTop: '4px' }}>
                    <IonItem lines='none'>
                      <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
                        className="ion-margin data-list"
                      >
                        <IonLabel>
                          <p style={{ fontSize: '16px', color: '#1d508d', cursor: 'pointer' }}>{item['name']}</p>
                          <p className='preview-key'>{item['name1']}</p>
                        </IonLabel>
                        {fullScreen ? <p onClick={() => {
                          setOptModalFullScr(!optModalFullScr)
                          setCurrentSelected(item['name'])
                        }} className='optionMapClickable'>{t('Maintenance.' + item['status'])}</p> :
                          <IonSelect interface='action-sheet' style={{ color: '#1d508d', fontSize: '14px' }} value={item['status']} placeholder='Change Status'
                            onIonChange={(e) => handleChange(e.detail.value, item['name'], maintenance)}>
                            <IonSelectOption value="New">{t('Maintenance.New')}</IonSelectOption>
                            <IonSelectOption value="In Progress">{t('Maintenance.In Progress')}</IonSelectOption>
                            <IonSelectOption value="Completed">{t('Maintenance.Completed')}</IonSelectOption>
                            <IonSelectOption value="Good">{t('Maintenance.Good')}</IonSelectOption>
                            <IonSelectOption value="Need Repair">{t('Maintenance.Need Repair')}</IonSelectOption>
                          </IonSelect>
                        }
                      </div>
                    </IonItem>
                    {!fullScreen &&
                      <div className='view-details-modal'
                        onClick={() => {
                          setQuickUpdateToggle(false)
                          setModalToggle(false)
                        }}>
                        <Link to={{ pathname: '/maintainanceDetail', state: item['name'] }}>{t('Utils.viewDetails')}</Link></div>}
                  </IonList>

                  {fullScreen && optModalFullScr && currentSelected === item['name'] ?
                    <div key={currentSelected} className='mapModalFullScreen'>
                      <div className='mapOptionsTitle'>{t('Maintenance.New')}<input className="radioMapModal" onChange={(e) => handleChange(e.target.value, item['name'], maintenance)} type="radio" value="New" checked={item['status'] === 'New'} /></div>
                      <div className='mapOptionsTitle'>{t('Maintenance.In Progress')}<input className="radioMapModal" onChange={(e) => handleChange(e.target.value, item['name'], maintenance)} value="In Progress" type="radio" checked={item['status'] === 'In Progress'} /></div>
                      <div className='mapOptionsTitle'>{t('Maintenance.Completed')}<input className="radioMapModal" onChange={(e) => handleChange(e.target.value, item['name'], maintenance)} type="radio" value="Completed" checked={item['status'] === 'Completed'} /></div>
                      <div className='mapOptionsTitle'>{t('Maintenance.Good')}<input className="radioMapModal" onChange={(e) => handleChange(e.target.value, item['name'], maintenance)} type="radio" value="Good" checked={item['status'] === 'Good'} /></div>
                      <div className='mapOptionsTitle'>{t('Maintenance.Need Repair')}<input className="radioMapModal" onChange={(e) => handleChange(e.target.value, item['name'], maintenance)} type="radio" value="Need Repair" checked={item['status'] === 'Need Repair'} /></div>
                    </div> : null}
                  {loaderModal ? <div className='toastMapModal'>{t('Utils.updating')}</div> : null}
                </div>
              )
            })
            :
            apiLoader ? <IonLabel>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem' }}>
                <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
                <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem' }}>
                <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
                <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem' }}>
                <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
                <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem' }}>
                <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
                <IonSkeletonText animated={true} style={{ 'width': '40%', height: '1rem' }}></IonSkeletonText>
              </div>
            </IonLabel> :
              <div style={{ textAlign: 'center', margin: 'auto', marginTop: '5rem', marginBottom: '8rem' }}>
                <img
                  style={{ margin: 'auto', width: '50px', opacity: '0.5', marginRight: '10px', fill: 'currentColor', color: '#1d508d' }}
                  src={calender}
                  className="action-icon-map-modal"
                />
                <p style={{ textAlign: 'center', opacity: '0.5' }}>{t('Maintenance.No Maintenance Request')}</p>
              </div>
          : null}

        {quickUpdateToggle ?
          <form onSubmit={(e) => SubmitStatusUpdate(e)}
            style={{ paddingBottom: '2rem' }}>
            {_data.map((form_field, index) => {
              //  if (form_field.field === 'asset_component') {
              //   return (
              //     <div key={index} className="input-container">
              //       <label className="label">{t(`Assets.CreateA.${form_field.field}`)}</label>
              //       <Select
              //         className="custom-select"
              //         required={form_field.required}
              //         id={`${form_field.field}_choice`}
              //         name={form_field.field}
              //         multiple
              //         onChange={handleMultiSelectChange}
              //         value={update[form_field.field]}
              //         disabled={
              //           //@ts-ignore
              //           form_field.disabled || false}
              //         input={<Input  className="basic-input" id="select-multiple-chip"  />}
              //         renderValue={(selected) => (
              //           <Box style={{borderRadius:'33rem'}} sx={{ display: 'flex', flexWrap: 'wrap'}}>
              //             {//@ts-ignore
              //             update[form_field.field].map((value,index) => (
              //               <Chip  
              //               style={{fontSize:'10px',marginTop:'1px',marginRight:'1px'}} key={index} label={value.label} />
              //             ))}
              //           </Box>
              //         )}
              //       >
              //         {form_field.options &&
              //           form_field.options.map(function (opt, indexSelect) {
              //             return (
              //               <MenuItem
              //                 value={opt}
              //                 key={`${form_field.field}-${indexSelect}`}
              //                 id={`${opt}_item_${indexSelect}`}
              //               >
              //                    <Checkbox checked={//@ts-ignore
              //                                update[form_field.field].length ? update[form_field.field].filter(itemInArray => itemInArray.id === opt.id)[0]?.id === opt.id : false } />
              //                   <ListItemText primary={//@ts-ignore
              //                     opt.label} />
              //               </MenuItem>
              //             );
              //           })}
              //       </Select>
              //     </div>
              //   );
              // }
              //  else 
              if (form_field.field === 'status' || form_field.field === 'asset_state') {
                // we dont allow users to edit status so not returning it for now
                // (
                //   <div key={index} className="input-container">
                //     <label className="label">{t(`Assets.CreateA.${form_field.field}`)}</label>
                //     <Select
                //       className="custom-select"
                //       required={form_field.required}
                //       id={`${form_field.field}_choice`}
                //       name={form_field.field}
                //       onChange={handleUpdateStatus}
                //       value={update[form_field.field]}
                //       disabled={
                //         //@ts-ignore
                //         form_field.disabled || false}
                //       key={`${form_field.field}-${index}`}
                //     >
                //       {form_field.options &&
                //         form_field.options.map(function (opt, index) {
                //           return (
                //             <MenuItem value={`${opt}`} key={`${form_field.field}-${index}`}>
                //               <section key={`${form_field.field}-${index}`} style={{ display: 'flex', height: '1rem' }}>
                //                 <p
                //                   key={`${form_field.field}-${index}`}
                //                   style={{
                //                     color: 'rgba(31, 87, 148, 1)',
                //                     marginLeft: '0.2rem',
                //                     fontSize: '12px',
                //                     marginTop: '0px',
                //                   }}
                //                 >
                //                   {form_field.translate ? t(`Assets.CreateA.${opt}`) : opt}
                //                 </p>
                //               </section>
                //             </MenuItem>
                //           );
                //         })}
                //     </Select>
                //   </div>
                // );
              }
              else if (form_field.field === 'brand' || form_field.field === 'model' || form_field.field === 'year') {
                return (
                  <div key={index} className="input-container">
                    <label className="label">{t(`Assets.CreateA.${form_field.field}`)}</label>
                    <input
                      className="basic-input"
                      required={false}
                      key={`${form_field.field}-${index}`}
                      id={form_field.field}
                      placeholder={t(`Assets.CreateA.${form_field.field}`)}
                      onChange={handleChangeQuickUpdate}
                      value={update[form_field.field]}
                    />
                  </div>
                );
              }
              else if (form_field.type === 'file') {
                return (
                  <div
                    id={`${form_field.field}-${index}`}
                    className="input-container"
                    key={`${form_field.field}-${index}`}
                  >
                    <label id={`label-${form_field.field}`} className="label">
                      {t(`Assets.CreateA.${form_field.field}`)}
                      {form_field.required ? '*' : null}{' '}
                    </label>
                    <IonButton
                      style={{ marginTop: '1rem', margin: 'auto', width: '40%', color: 'black', marginBottom: '1rem' }}
                      size="small"
                      color="light"
                      onClick={() => takeQSPicture(index)}
                    >
                      {//@ts-ignore
                        update[form_field.field]['value'] ? t('Utils.change_image') : t('Utils.image')}
                    </IonButton>
                    <div className="flex justify-center items-center">
                      {//@ts-ignore
                        update[form_field.field]['value'] ? (
                          <img
                            src={`${localStorage.getItem('baseUrl')?.replace('/api', '')}${
                              //@ts-ignore
                              update[form_field.field]['value']}`}
                            alt=""
                            style={{
                              width: '60%',
                              margin: 'auto',
                              display: 'block',
                              border: '1px solid lightgray',
                              borderRadius: '12px',
                            }}
                          />
                        ) : (
                          ''
                        )}
                      {//@ts-ignore
                        update['attachment']['src'] ? <img className="preview-survey-img" src={update['attachment']['src']} alt="" /> : ''}
                      {//@ts-ignore
                        update['attachment']['src'] ? (
                          <IonIcon className="close-icon" onClick={() => emptyQSImg(index)} src={close} />
                        ) : (
                          ''
                        )}
                    </div>
                  </div>
                );
              } else if (form_field.type === 'barcode') {
                return (
                  <div
                    id={`${form_field.field}-${index}`}
                    className="input-container"
                    key={`${form_field.field}-${index}`}
                  >
                    <label id={`label-${form_field.field}`} style={{ marginTop: '1rem' }} className="label">
                      {t(`Assets.CreateA.${form_field.field}`)}
                      {form_field.required ? '*' : null}{' '}
                    </label>
                    <div className="flex items-center justify-between basic-input">
                      <input
                        required={form_field.required}
                        className="gps-input"
                        id={form_field.field}
                        placeholder={t(`Assets.CreateA.${form_field.field}`)}
                        value={//@ts-ignore
                          update[form_field.field]}
                        disabled={true}
                        style={{width: '80%'}}
                      />
                      <IonIcon onClick={() => getScan(form_field.field)} className="gps-icon" src={barcodeOutline} />
                    </div>
                  </div>
                );
              }
              else {
              }
            })}
            <div className="input-container">
              <label className="label">{t(`Utils.Comment`)}</label>
              <textarea
                className="textarea"
                required={false}
                key="comment"
                id="comment"
                placeholder={t('Utils.Add your comment')}
                onChange={handleChangeQuickUpdate}
                value={update.comment}
              />
            </div>
            <button className="submit-button" type="submit">
              {t(`Assets.CreateA.update`)}
            </button>
          </form> : ''}
        {shimmer ? <Shimmer ref={ref} /> :
          assetModal && showAssetFields.map((item: any, index) => {
            return (
              <div key={index + '-asset'} className="data-list">
                {item.field ? (
                  <div className="preview-container">
                    <p className="preview-key">{t(`Assets.CreateA.${item.field}`)}</p>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end', fontSize: '' }}>
                      <div className="preview-value" style={{ textTransform: 'capitalize', fontSize: '12px' }}>
                        {item.field === 'northing_easting_point' ? assetDetail[item.field] ?
                          <p onClick={() => openMap(assetDetail[item.field])} className="preview-value pointer">
                            <u>
                              {
                                assetDetail[item.field] ? assetDetail[item.field] : '-'
                              }
                            </u>
                          </p> : '-' : null}
                        {item.field === 'asset_component'
                          ? // @ts-ignore
                          assetDetail[item.field] &&
                          // @ts-ignore
                          // assetDetail[item.field].map((val: any, key: any) => {
                          //   return (
                          //     <p key={key} className="preview-value" style={{ textTransform:'capitalize',fontSize: '12px'}}>
                          //         {(val.component_name) + ', '} 
                          //     </p>
                          //   ) || '-';
                          // })
                          (assetDetail[item.field] ? assetDetail[item.field]?.map(m => m.component_name).join(',') || '-' : '-')
                          : null}</div>
                    </div>
                    {item.field === 'asset_category'
                      ? // @ts-ignore
                      <div className='flex items-center'>
                        {color ? <div className="color-box" style={{
                          background: `${                            //@ts-ignore
                            color.color}`
                        }} onClick={() => setAsToggle(true)} id="trigger-button"></div> : '-'}

                        <IonPopover isOpen={asToggle}
                          mode="ios"
                          backdropDismiss={true}
                          onDidDismiss={() => setAsToggle(false)}
                        >
                          <div style={{ marginTop: '.5rem' }} className="preview-container">
                            <IonIcon className="close-icon" onClick={() => setAsToggle(false)} src={close} />
                          </div>
                          <div className="preview-container">
                            <p className="preview-key ">
                              {t(`Assets.CreateA.category_name`)}
                            </p>
                            <p className="preview-value">
                              {//@ts-ignore
                                color.category_name}
                            </p>
                          </div>
                          <div className="preview-container">
                            <p className="preview-key">
                              {t(`Assets.CreateA.color`)}
                            </p>
                            <p className="preview-value">
                              {//@ts-ignore
                                color.color}
                            </p>
                          </div>
                        </IonPopover>
                      </div>
                      : null}
                    {item.field === 'status' ? (
                      // @ts-ignore
                      <p className="preview-value" style={{
                        // @ts-ignore
                        color: assetDetail[item.field]?.includes('Need') ? 'red' : undefined,
                      }}>
                        {/* @ts-ignore */}
                        {assetDetail[item.field] && item.translate
                          ? //@ts-ignore
                          t(`Assets.CreateA.${assetDetail[item.field]}`)
                          : //@ts-ignore
                          assetDetail[item.field]
                            ? //@ts-ignore
                            assetDetail[item.field]
                            : '-'}
                      </p>
                    ) : null}
                    {item.field !== 'status' && item.field !== 'asset_component' && item.field !== 'attachment' && item.field !== 'asset_category' && item.field !== 'northing_easting_point' ? (
                      // @ts-ignore
                      <p className="preview-value">
                        {/* @ts-ignore */}
                        {assetDetail[item.field] && item.translate
                          ? //@ts-ignore
                          t(`Assets.CreateA.${assetDetail[item.field]}`)
                          : //@ts-ignore
                          assetDetail[item.field]
                            ? //@ts-ignore
                            assetDetail[item.field]
                            : '-'}
                      </p>
                    ) : null}
                    {item.field === 'attachment' ? (
                      <div className="preview-value">
                        {/* @ts-ignore */}
                        {assetDetail[item.field] && item.translate
                          ? //@ts-ignore
                          t(`Assets.CreateA.${assetDetail[item.field]}`)
                          : //@ts-ignore
                          assetDetail[item.field]
                            ? //@ts-ignore
                            <img
                              src={`${localStorage.getItem('baseUrl')?.replace('/api', '')}${
                                //@ts-ignore
                                assetDetail[item.field]}`}
                              alt=""
                              style={{
                                borderRadius: '5px',
                              }}
                            />
                            : '-'}
                      </div>
                    ) : null}


                  </div>
                ) : (
                  ''
                )}

              </div>
            );
          })}
        {assetModal ?
          <AssetTimeline id={
            //@ts-ignore
            assetDetail.asset_name} /> : ''}
      </div> : null}

      <IonModal key={'modal'} isOpen={scanToggle} onDidDismiss={() => setScanToggle(false)}>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="end">
              <IonButton
                className="line-button"
                onClick={() => {
                  setScanToggle(false);
                }}
              >
                <IonIcon name="close" slot="icon-only"></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <BarcodeScannerComponent
          torch={true}
          onUpdate={(err, result) => {
            console.log('result', result);
            console.log('BarcodeScannerComponent-err', err);
            //@ts-ignore
            if (result && result.text) handleScan(result.text);
          }}
        />
        <div className="submit-button" style={{ textAlign: 'center' }} onClick={() => setScanToggle(false)}>
          {t('Utils.cancel')}
        </div>
      </IonModal>
    </div>
  );
};

export default AssetOnMapModal;
