import { Storage } from '@capacitor/storage';
import axios from '../axios';
import { Device } from '@capacitor/device';
import { AppContext } from '../providers/AppContext';
import { useContext } from 'react';

export const constant = {
  COMPONENT_CATEGORY: 'component_category',
  COMPONENT_TYPE: 'component_type',
  ASSET_NAME: 'asset_name',
  ASSET_CATEGORY: 'asset_category',
  ASSET_TYPE: 'asset_type',
  PROJECT_NAME_LIST: 'project_name_list',
  LOCATION: 'location',
  ASSET_COMPONENT: 'asset_component',
  ALL_DATA_SYNC_TABLE: 'main_hyprism_table',
  ALL_DATA_SYNC_TABLE_BACKUP: 'main_hyprism_table_backup',
  LAST_UPDATED_TIME: 'last_updated',
  SURVEY_UNIQUE_ID: 'si_survey_code',
  prefletColors: ['#7AABE5', '#FF9088', '#FCB454', '#61DBA7', '#C2F9EE', '#D4EEFF', '#FCC98B'],
  CHECKLIST: 'checklist_data',
  FAULT_TYPE: 'fault_type',
  TASK_LIST: 'task_list',
  ASSET_CPM: "asset_components_list",
  PROFILE_PHOTO: 'profile_photo',
  Installed: '#00a65a',
  Operational: '#00a65a',
  Installed_But_Not_Operational: '#f39c12',
  In_Progress: '#f39c12',
  Need_Replacement: '#f56954',
  Replaced: '#f56954',
  Need_Installation: '#f56954',
  PROJECT_STATUS: 'project_status',
  PROJECT_PRIORITY: "project_priority",
  PROJECT_LOCATION: "project_location",
  PROJECT_LIST: "project_list",
  TASK_TYPE: "task_type",
  MAX_LENGTH: 10,
};



export const offlineSaveToTable = async (saveData, url, docType) => {
  const localKey = new Date().getTime();
  // fetch the existing table data
  const getExistingTableKeys = await getStorage(constant.ALL_DATA_SYNC_TABLE, []);

  // add the key to existing table
  getExistingTableKeys.push(localKey);

  // save the new key back to storage
  setStorage(constant.ALL_DATA_SYNC_TABLE, getExistingTableKeys);

  // create a temporary backup
  setStorage(constant.ALL_DATA_SYNC_TABLE_BACKUP, getExistingTableKeys);

  // add extra fields
  saveData.base_url_offline_sync = url;
  saveData.doc_type = docType;

  // now save the original data with the localKey
  await setStorage(localKey, saveData);
};

export const setStorage = async (key, value) => {
  const info = await Device.getInfo()
  if (info.platform !== 'web') {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    await Storage.set({
      key,
      value: JSON.stringify(value),
    });
  }
};

export const deleteStorage = async (key) => {
  const info = await Device.getInfo()
  if (info.platform !== 'web') {
    localStorage.removeItem(key);
  } else {
    await Storage.remove({
      key,
    });
  }
};

export const getStorage = async (key, returnDefault) => {
  const info = await Device.getInfo()
  var value = returnDefault;
  try {
    if (info.platform !== 'web') {
      value = JSON.parse(localStorage.getItem(key));
    } else {
      value = JSON.parse(
        (
          await Storage.get({
            key,
          })
        ).value
      );
    }
  } catch (e) {
    value = returnDefault;
  }
  if (!value) return returnDefault;
  return value;
};

export const getSetComponentCategory = async () => {
  var component_category = [];
  try {
    const res = await axios({
      method: 'get',
      url: '/method/preflet_asset.api.get_component_category',
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    component_category = res?.data?.data.map((ct) => encodeURIComponent(ct?.name));
    // save data in storage
    setStorage(constant.COMPONENT_CATEGORY, JSON.stringify(component_category));
  } catch (e) {
    console.log(e)
    // get data from localstorage
    component_category = await getStorage(constant?.COMPONENT_CATEGORY, []);
  }
  return component_category?.map((cmp) => decodeURIComponent(cmp)) || [];
};

export const getSetComponentType = async () => {
  var component_type = [];
  try {
    const res = await axios({
      method: 'get',
      url: '/method/preflet_asset.api.get_component_type',
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    component_type = res?.data?.data.map((ct) => encodeURIComponent(ct.name));
    // save data in storage
    setStorage(constant.COMPONENT_TYPE, JSON.stringify(component_type));
  } catch (e) {
    // get data from localstorage
    component_type = await getStorage(constant.COMPONENT_TYPE, []);
  } finally {
    return component_type?.map((cmt) => decodeURIComponent(cmt)) || [];
  }
};

export const getSetAssetComponentType = async () => {
  var component_type = [];
  try {
    const res = await axios({
      method: 'get',
      url: '/resource/Component Type?fields=["name"]',
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    component_type = res.data.data.map((ct) => encodeURIComponent(ct.name));
    // save data in storage
    setStorage(constant.ASSET_COMPONENT_TYPE, JSON.stringify(component_type));
  } catch (e) {
    // get data from localstorage
    component_type = await getStorage(constant.ASSET_COMPONENT_TYPE, []);
  } finally {
    return component_type?.map((cmt) => decodeURIComponent(cmt)) || [];
  }
};

export const getAssetComponentName = async () => {
  var component_name = [];
  try {
    const res = await axios({
      method: 'get',
      url: '/resource/Asset Components?fields=["name"]',
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    component_name = res.data.data.map((cn) => encodeURIComponent(cn.name));
    // save data in storage
    setStorage(constant.COMPONENT_NAME, JSON.stringify(component_name));
  } catch (e) {
    console.log(e);
    // get data from localstorage
    component_name = await getStorage(constant.COMPONENT_NAME, []);
  } finally {
    return component_name?.map((cmt) => decodeURIComponent(cmt)) || [];
  }
};

export const getSetAssetName = async (currentPage, searchText) => {
  var asset_name = [];
  try {
    let url = `/resource/Asset?fields=["name"]&limit_page_length=${constant.MAX_LENGTH}&limit_start=${searchText ? 0 : currentPage * 10 + 0}`;
    if (searchText) {
      url = url + `&filters=[["Asset","name","like","%${searchText}%"]]`;
    }
    const res = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    asset_name = res.data.data.map((ct) => ct.name);
    // save data in storage
    setStorage(constant.ASSET_NAME, JSON.stringify(asset_name));
  } catch (e) {
    // get data from localstorage
    asset_name = await getStorage(constant.ASSET_NAME, []);
  } finally {
    return asset_name?.map((ast) => ast) || [];
  }
};

export const getSetAssetNameWithNoMaintenance = async (maintenance, searchText, currentPage) => {
  var asset_name = [];
  try {
    let url = `/method/preflet_asset.apis.maintenance.routes.load_unassigned_assets${maintenance ? '?name=' + maintenance : ''}?start=${searchText ? 0 : currentPage * 10 + 0}&limit=${constant.MAX_LENGTH}`;
    if (searchText) {
      url = url + `&search=${searchText}`
    }
    const res = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    asset_name = res?.data?.message?.map((ct) => ct?.name);
    // save data in storage
    setStorage(constant.ASSET_NAME, JSON.stringify(asset_name));
  } catch (e) {
    // get data from localstorage
    asset_name = await getStorage(constant.ASSET_NAME, []);
  } finally {
    return asset_name?.map((ast) => ast) || [];
  }
};

export const getSetAssetNameWithNoModelo = async (currentPage, searchText ) => {
  var asset_name = [];
  try {
    let url = `/method/preflet_asset.apis.model937.routes.get_assets?start=${searchText ? 0 : currentPage * 10 + 0}&limit=${constant.MAX_LENGTH}`;
    if (searchText) {
      url = url + `&search=${searchText}`
    }

    const res = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    asset_name = res?.data?.message?.map((ct) => ct?.name);
    // save data in storage
    setStorage(constant.ASSET_NAME, JSON.stringify(asset_name));
  } catch (e) {
    // get data from localstorage
    asset_name = await getStorage(constant.ASSET_NAME, []);
  } finally {
    return asset_name?.map((ast) => ast) || [];
  }
};

export const getSetAssetNameWithNoService = async (service, searchText, currentPage) => {
  var asset_name = [];
  try {
    let url = `/method/preflet_asset.apis.repair.routes.load_unassigned_assets${service ? '?name=' + service : ''}?start=${searchText ? 0 : currentPage * 10 + 0}&limit=${constant.MAX_LENGTH}`;
    if (searchText) {
      url = url + `&search=${searchText}`
    }
    const res = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    asset_name = res?.data?.message?.map((ct) => ct?.name);
    // save data in storage
    setStorage(constant.ASSET_NAME, JSON.stringify(asset_name));
  } catch (e) {
    // get data from localstorage
    console.log(e?.response)
    asset_name = await getStorage(constant.ASSET_NAME, []);
  } finally {
    return asset_name?.map((ast) => ast) || [];
  }
};


export const getSetAssetCategory = async (searchText, currentPage) => {
  var asset_category = [];
  try {
    let url = `/resource/Asset Category?fields=["category_name"]`
    // &limit_start=${searchText ? 0 : currentPage * 10 + 0}&limit_page_length=${constant.MAX_LENGTH}`
    const res = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    asset_category = res.data.data.map((ct) => ct.category_name);
    // save data in storage
    setStorage(constant.ASSET_CATEGORY, JSON.stringify(asset_category));
  } catch (e) {
    // get data from localstorage
    asset_category = await getStorage(constant.ASSET_CATEGORY, []);
  }
  return asset_category?.map((ast) => ast) || [];
};

export const getProjectStatus = async () => {
  var project_status = [];
  try {
    const res = await axios({
      method: 'get',
      url: 'resource/Project Status',
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    project_status = res.data.data.map((ct) => ct.name);
    // save data in storage
    setStorage(constant.PROJECT_STATUS, JSON.stringify(project_status));
  } catch (e) {
    // get data from localstorage
    project_status = await getStorage(constant.PROJECT_STATUS, []);
  }
  return project_status?.map((ast) => ast) || [];
};

export const getProjectList = async () => {
  var project_list = [];
  try {
    const res = await axios({
      method: 'get',
      url: 'resource/Project',
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    project_list = res.data.data.map((ct) => ct.name);
    // save data in storage
    setStorage(constant.PROJECT_LIST, JSON.stringify(project_list));
  } catch (e) {
    // get data from localstorage
    project_list = await getStorage(constant.PROJECT_LIST, []);
  }
  return project_list?.map((ast) => ast) || [];
};

export const getTaskType = async () => {
  var task_type = [];
  try {
    const res = await axios({
      method: 'get',
      url: 'resource/Task Type',
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    task_type = res.data.data.map((ct) => ct.name);
    // save data in storage
    setStorage(constant.TASK_TYPE, JSON.stringify(task_type));
  } catch (e) {
    // get data from localstorage
    task_type = await getStorage(constant.TASK_TYPE, []);
  }
  return task_type?.map((ast) => ast) || [];
};

export const getAssigneeList = async () => {
  var task_type = [];
  try {
    const res = await axios({
      method: 'get',
      url: 'resource/User',
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    task_type = res.data.data.map((ct) => ct.name);
    // save data in storage
    setStorage(constant.TASK_TYPE, JSON.stringify(task_type));
  } catch (e) {
    // get data from localstorage
    task_type = await getStorage(constant.TASK_TYPE, []);
  }
  return task_type?.map((ast) => ast) || [];
};





export const getProjectPriority = async () => {
  var project_priority = [];
  try {
    const res = await axios({
      method: 'get',
      url: 'resource/Project Priority',
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    project_priority = res.data.data.map((ct) => ct.name);
    // save data in storage
    setStorage(constant.PROJECT_PRIORITY, JSON.stringify(project_priority));
  } catch (e) {
    // get data from localstorage
    project_priority = await getStorage(constant.PROJECT_PRIORITY, []);
  }
  return project_priority?.map((ast) => ast) || [];
};

export const getProjectLocation = async () => {
  var project_location = [];
  try {
    const res = await axios({
      method: 'get',
      url: 'resource/Location',
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    project_location = res.data.data.map((ct) => ct.name);
    // save data in storage
    setStorage(constant.PROJECT_LOCATION, JSON.stringify(project_location));
  } catch (e) {
    // get data from localstorage
    project_location = await getStorage(constant.PROJECT_LOCATION, []);
  }
  return project_location?.map((ast) => ast) || [];
};


export const getAssetComponentByLabel = async (searchText, currentPage) => {
  var asset_comp_list = [];
  try {
    let url = `/resource/Asset Components?fields=["*"]&filters=[["Asset Components","external_component","=",1]]&limit_start=${searchText ? 0 : currentPage * 10 + 0}&limit_page_length=${constant.MAX_LENGTH}`;
    if (searchText) {
      url += `&search=${searchText || ''}`;
    }
    const res = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    asset_comp_list = []
    // eslint-disable-next-line array-callback-return
    res.data.data.map((ct) => {
      asset_comp_list.push({
        id: ct.name,
        label: ct.component_name
      })
    })
    // save data in storage
    setStorage(constant.ASSET_CPMNT, JSON.stringify(asset_comp_list));
  } catch (e) {
    // get data from localstorage
    asset_comp_list = await getStorage(constant.ASSET_CPMNT, []);
  }
  return asset_comp_list?.map((ast) => ast) || [];
};

export const getAssetComponent = async () => {
  var asset_comp_list = [];
  try {
    const res = await axios({
      method: 'get',
      url: '/resource/Asset Components?fields=["*"]&filters=[["Asset Components","external_component","=",1]]',
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    asset_comp_list = res.data.data.map((ct) => ct.name);
    // save data in storage
    setStorage(constant.ASSET_CPM, JSON.stringify(asset_comp_list));
  } catch (e) {
    // get data from localstorage
    asset_comp_list = await getStorage(constant.ASSET_CPM, []);
  }
  return asset_comp_list?.map((ast) => ast) || [];
};

export const getSetAssetType = async () => {
  var asset_type = [];
  try {
    const res = await axios({
      method: 'get',
      url: '/resource/Asset Type?fields=["asset_type"]',
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    asset_type = res.data.data.map((ct) => ct.asset_type);
    // save data in storage
    setStorage(constant.ASSET_TYPE, JSON.stringify(asset_type));
  } catch (e) {
    // get data from localstorage
    asset_type = await getStorage(constant.ASSET_TYPE, []);
  }
  return asset_type?.map((ast) => ast) || [];
};

export const getSetProjectList = async (currentPage) => {
  var project_name_list = [];
  try {
    let url = `/method/preflet_asset.apis.project.routes.projects?start=${currentPage * 10 + 0}&limit=${constant.MAX_LENGTH}`;
    const res = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    project_name_list = res?.data?.message
    // save data in storage
    setStorage(constant.PROJECT_NAME_LIST, JSON.stringify(project_name_list));
  } catch (e) {
    // get data from localstorage
    project_name_list = await getStorage(constant.PROJECT_NAME_LIST, []);
  }
  return project_name_list?.map((ast) => ast) || [];
};

export const getTaskByProject = async (project) => {
  var taskList = [];
  try {
    const res = await axios({
      method: 'get',
      url: `/resource/Task?filters=[["Task","project","=","${project}"]]`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    taskList = res.data.data.map((ct) => ct.name);
    // save data in storage
    setStorage(constant.TASK_LIST, JSON.stringify(taskList));
  } catch (e) {
    // get data from localstorage
    taskList = await getStorage(constant.TASK_LIST, []);
  }
  return taskList?.map((ast) => ast) || [];
};

export const getSetLocation = async () => {
  var location = [];
  try {
    const res = await axios({
      method: 'get',
      url: '/resource/Location?fields=["name"]',
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    location = res.data.data.map((ct) => ct.name);
    // save data in storage
    setStorage(constant.LOCATION, JSON.stringify(location));
  } catch (e) {
    // get data from localstorage
    location = await getStorage(constant.LOCATION, []);
  }
  return location?.map((ast) => ast) || [];
};

export const getSetAssetComponent = async () => {
  var asset_component = [];
  try {
    const res = await axios({
      method: 'get',
      url: '/resource/?fields=["name"]',
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    asset_component = res.data.data.map((ct) => ct.category_name);
    // save data in storage
    setStorage(constant.ASSET_COMPONENT, JSON.stringify(asset_component));
  } catch (e) {
    // get data from localstorage
    asset_component = await getStorage(constant.ASSET_COMPONENT, []);
  }
  return asset_component?.map((ast) => ast) || [];
};

export const cleanTextIfAny = (text, fieldType) => {
  // check for boolean values
  if (fieldType === 'checkbox') {
    if (text === 1) return 'yes';
    if (text === 0) return 'no';
  }

  text = text ? text.toString() : '';

  if (text.includes('&lt;')) {
    text = text.replace(/&lt;/g, ' < ');
  }
  if (text.includes('&gt;')) {
    text = text.replace(/&gt;/g, ' > ');
  }
  return text;
};

/* Body according to backend need */
export const surveyPostData = (api_data) => {
  for (const item in api_data) {
    if (api_data[item].type === 'select' && !api_data[item].multiple) {
      if (api_data[item].value === '< 1 year') {
        api_data[item].value = '&lt;1 year';
      } else if (api_data[item].value === '1 year < age < 10year') {
        api_data[item].value = '1 year&lt;age&lt;10year';
      } else if (api_data[item].value === '> 20year') {
        api_data[item].value = '&gt;20year';
      } else if (api_data[item].value === '10 year < age < 20year') {
        api_data[item].value = '10 year&lt;age&lt;20year';
      }
    } else if (api_data[item].type === 'select' && api_data[item].multiple) {
      if (api_data[item].value) {
        const tempArr = api_data[item].value;
        api_data[item].value = [];
        for (const index in tempArr) {
          api_data[item].value.push({ option: tempArr[index].option ? tempArr[index].option : tempArr[index] });
        }
      }
    } else if (api_data[item].type === 'checkbox') {
      if (api_data[item].value === 'false') {
        api_data[item].value = '0';
      } else if (api_data[item].value === 'true') {
        api_data[item].value = '1';
      }
    }
  }
  return api_data;
};

export const startFileUpload = async (fileDict, docType, fieldName) => {
  try {
    const response = await fetch(fileDict.src);
    const blob = await response.blob();

    const formData = new FormData();

    formData.append(
      'file',
      new File([blob], `${docType}-${fieldName}.${fileDict.format}`, {
        type: `image/${fileDict.format}`,
      })
    );
    formData.append('is_private', '0');
    formData.append('folder', 'Home');
    formData.append('doctype', docType);
    var config = {
      method: 'post',
      url: '/method/upload_file',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: formData,
    };
    const res = await axios(config);
    return res.data.message.file_url;
  } catch (e) { }
  return '';
};

export const resetForm = (formentry) => {
  // var x: any;
  for (var x in formentry) {
    if (formentry[x].type === 'select') {
      formentry[x].value = '';
    } else if (formentry[x].type === 'file') {
      formentry[x].value = '';
      formentry[x].src = '';
      formentry[x].format = '';
    } else {
      formentry[x].value = '';
    }
  }
  return formentry;
};

export const createFormJsonData = (data) => {
  const api_data = {};
  for (var index in data) {
    if (data[index].field) {
      if (data[index].type === 'file') {
        if (data[index].src)
          api_data[data[index].field] = data[index].value
      } else {
        api_data[data[index].field] = data[index].value;
      }
    }
  }
  return api_data;
};

export const getFaultType = async () => {
  var fault_type = [];
  try {

    const res = await axios({
      method: 'get',
      url: '/resource/Fault Type?fields=["fault_type"]',
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    fault_type = res.data.data.map((ct) => ct.fault_type);
    // save data in storage
    setStorage(constant.FAULT_TYPE, JSON.stringify(fault_type));
  } catch (e) {
    // get data from localstorage
    fault_type = await getStorage(constant.FAULT_TYPE, []);
  } finally {
    return fault_type?.map((flt) => flt) || [];
  }
};

export const getCheckList = async () => {
  var checkList = [];
  try {

    const res = await axios({
      method: 'get',
      url: '/resource/Checklist',
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    });
    checkList = res.data.data.map((ct) => ct.name);
    // save data in storage
    setStorage(constant.CHECKLIST, JSON.stringify(checkList));
  } catch (e) {
    // get data from localstorage
    checkList = await getStorage(constant.CHECKLIST, []);
  } finally {
    return checkList?.map((flt) => flt) || [];
  }
};



export const getDashboard = async (status, filterByStatus = '') => {
  var response = {};
  const formData = new FormData();
  const filters = {}
  if (filterByStatus)
    filters.status = filterByStatus

  //@ts-ignore
  formData.append('filters', JSON.stringify(filters));

  const url = `/method/preflet_asset.api.get_analytics${status ? `?cloc=${status}` : ''}`;
  let config = {
    method: 'post',
    url,
    data: formData,
    headers: {
      Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
    },
  };
  const res = await axios(config)
  response = res?.data?.data
  return response;
};

export const getStatusColor = (status) => {
  switch (status) {
    case 'Installed':
      return '#7AABE5';
    case 'Operational':
      return '#61DBA7';
    case 'Installed But Not Operational':
      return "#FCB454";
    case 'Need Repairing':
      return "#e81c0e";
    case 'In Progress':
      return "#FF9088";
    case 'Need Installation':
      return "#C2F9EE";
    case 'Need Maintenance And Repair':
      return "#eba063";
    case 'Need Maintenance':
      return '#9c0927';
    case 'Need Replacement':
      return "#FCC98B";
    case 'Replaced':
      return "#D4EEFF";
    case 'Inactive':
      return "#ccc8c4";
    default:
      return '#000000'; // Default color if status is not recognized
  }
};

export const isFormDataEmpty = (formData) => {
  const entries = formData.entries();
  return entries.next().done === true;
};

export const checkIfArraysAreSame = (array1, array2) => {
  var assetComponent = []
  array1.length && array1.map((item) => {
    assetComponent.push({ asset_component: item?.asset_component })
  })

  // Check if the arrays have the same length
  if (assetComponent.length !== array2.length) {
    return false;
  }

  const strArray1 = JSON.stringify(assetComponent);
  const strArray2 = JSON.stringify(array2);

  // Compare the JSON strings
  return strArray1 === strArray2;
}