import { IonContent, IonPage, useIonToast, IonLoading, useIonRouter } from '@ionic/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../axios';
import { useHistory } from 'react-router-dom';
import Scan from '../components/Scan';

const SmartScan: React.FC = () => {
  const history = useHistory();
  const [present] = useIonToast();

  const { t } = useTranslation();

  const [showLoading, setShowLoading] = useState(false);

  const [isScan, setIsScan] = useState(true);

  const router = useIonRouter();

  const backToHome = () => {
    router.push('/');
  }


  const sendIdBackToPage = async (id: any) => {
    console.log(id);
    var config: any = {
      method: 'get',
      url: `resource/Asset?fields=["*"]&filters=[["Asset","barcode_id","=","${id}"]]`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    

    try {
      setShowLoading(true);
      const temp = await axios(config);
      if(temp.data.data.length){
        history.push({
          pathname: '/assetDetail',
          state: temp.data.data[0].name,
        });
      }else{
        present({
          message: t('Utils.AssetNotExist'),
          duration: 2000,
        });
        backToHome();
      }
     

    } catch (error) {
      console.log(error);
      //@ts-ignore
      if (error.response && error.response.status) {
        checkService(id);
      }
    } finally {
      setShowLoading(false);
    }
  };

  const checkService = async (id: any) => {
    const config: any = {
      method: 'get',
      url: `/resource/Repair Request/${id}`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    try {
      setShowLoading(true);
      await axios(config);
      history.push({
        pathname: '/serviceDetail',
        state: id,
      });
    } catch (error) {
      console.log(error);
      //@ts-ignore
      if (error.response.status) {
        checkMaintenance(id);
      }
    } finally {
      setShowLoading(false);
    }
  };

  const checkMaintenance = async (id: any) => {
    const config: any = {
      method: 'get',
      url: `/resource/Maintenance Request/${id}`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    try {
      setShowLoading(true);
      await axios(config);
      history.push({
        pathname: '/maintainanceDetail',
        state: id,
      });
    } catch (error) {
      console.log(error);
      present({
        message: t('Utils.noScanFound'),
        duration: 2000,
      });
      history.push({
        pathname: '/home',
      });
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <IonPage>
      {isScan ? <Scan setIsScan={setIsScan} sendIdBackToPage={sendIdBackToPage} /> : null}
      <IonContent>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Processing...'}
        />
      </IonContent>
    </IonPage>
  );
};

export default SmartScan;
