/* eslint-disable array-callback-return */
import { IonContent, IonPage, useIonToast, IonLoading, useIonViewWillEnter, useIonRouter, IonSkeletonText, IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonList, IonItem, IonLabel, IonCheckbox, IonInfiniteScroll, IonInfiniteScrollContent, } from '@ionic/react';
import { useState, useEffect } from 'react';
import Header from '../../components/Header';
import axios from '../../axios';
import { useTranslation } from 'react-i18next';
import { Network } from '@capacitor/network';

import { MenuItem, Select } from '@material-ui/core';
import {
  getProjectList,
  getTaskType,
  getAssigneeList,
  getSetProjectList,
} from '../../utils';
import { createTaskField } from '../../models/createTaskFields';

const CreateTask: React.FC = () => {
  const router = useIonRouter();
  const [present] = useIonToast();

  const { t } = useTranslation();

  const [showLoading, setShowLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(Boolean);

  const [data, setData] = useState(createTaskField);

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState();
  const [projectModal, setProjectModal] = useState(false);
  const [shimmer, setShimmer] = useState(false);
  const [infiniteScroll, setInfiniteScroll] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);



  const handleChange = (event: React.ChangeEvent<{ value: unknown; id: any }>) => {
    var formentry: any = [...data];
    const f_index = formentry.findIndex((v: any) => v.field === event.target.id);
    var change_form_object = formentry[f_index];
    change_form_object.value = event.target.value;
    formentry[f_index] = change_form_object;
    setData(formentry);
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    var formentry: any = [...data];
    // @ts-ignore
    const select = event.target.name.replaceAll('-', '_');
    const f_index = formentry.findIndex((v: any) => v.field === select);
    formentry[f_index].value = event.target.value;
    setData(formentry);
  };

  const url = '/resource/Task';

  useIonViewWillEnter(async () => {
    var Data = [...data];
    /* Get Project Status */
    const pname = Data.findIndex((d) => d.field === 'project');
    const projectList = await getSetProjectList(currentPage);
    Data[pname].options = projectList;

    if (projectList.length < 10) {
      setInfiniteScroll(false);
    }
    setProjects(projectList || []);

    const tname = Data.findIndex((d) => d.field === 'type');
    const type = await getTaskType();
    Data[tname].options = type;

    const aname = Data.findIndex((d) => d.field === 'task_assign_user');
    const assignee = await getAssigneeList();
    Data[aname].options = assignee;

    setData(Data);
  });

  useEffect(() => {
    (async () => {
      const internetStatus = await Network.getStatus();
      setIsOnline(internetStatus.connected);
      Network.addListener('networkStatusChange', (status) => {
        setIsOnline(status.connected);
      });
    })();
  }, [isOnline]);

  const Submit = (e: any) => {
    e.preventDefault();
    hitCreateAsset();
  };


  const hitCreateAsset = () => {
    const api_data: any = {};
    data.map((_entry: any) => {
      if (_entry.field === 'task_assign_user') {
        api_data[_entry.field] = [{ "user": _entry.value }]
      }
      else
        api_data[_entry.field] = _entry.value;
      return null;
    });

    var config: any = {
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: api_data,
    };
    setShowLoading(true);
    axios(config)
      .then((res) => {
        present({
          message: t(`Task.successCreate`),
          duration: 2000,
        });
        reset();
        router.goBack();
      })
      .catch(function (error) {
        console.log(error?.response?.data);
        const raw = JSON.parse(error?.response?.data?._server_messages);
        console.log(JSON.parse(raw[0])?.message);
        present({
          message: JSON.parse(raw[0]).message,
          color: 'danger',
          duration: 2000,
        });
      })
      .finally(() => {
        setShowLoading(false);
      });
  }

  const reset = () => {
    for (const x in data) {
      data[x].value = '';
    }
  };

  async function searchNextProject($event: CustomEvent<void>) {
    try {
      const res = await getSetProjectList(currentPage + 1);
      if (res?.length) {
        //@ts-ignore  
        setProjects([...projects, ...res]);
      }
    } catch (error) {
      console.log(error)
    } finally {
      setShimmer(false)
      setCurrentPage(currentPage + 1);
    }
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  const setProjectInValue = (project: any) => {
    var Data = [...data];
    const aname = Data.findIndex((d) => d.field === 'project');
    Data[aname].value = project;
    setSelectedProject(project);
  }

  const Shimmer = () => {
    return (
      <div>
        {
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((element) => {
            return (
              <div
                key={element}
                id="shimmer-body"
                style={{ padding: '1rem' }}
                className="w-50 data-list shimmer-container"
              >
                <div className="flex justify-between">
                  <IonSkeletonText animated style={{ width: '50%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };

  return (
    <IonPage>
      <Header enableBackButton='goBack' title={t('Task.create')} />
      <IonContent>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          duration={5000}
        />

        <form onSubmit={(e) => Submit(e)}>
          {data.map((form_field, index) => {
            if (form_field.title) {
              return (
                <p className="form-title" key={`${form_field.title}-${index}`}>
                  {t(form_field.title)}
                </p>
              );
            } else if (form_field.type === 'text') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(`Task.${form_field.field}`)}
                    {form_field.required ? '*' : null}
                  </label>
                  <input
                    required={form_field.required}
                    className="basic-input"
                    id={form_field.field}
                    placeholder={t(`Task.${form_field.field}`)}
                    onChange={handleChange}
                    value={form_field.value}
                  />
                </div>
              );
            } else if (form_field.type === 'textarea') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(`Task.${form_field.field}`)}
                    {form_field.required ? '*' : null}
                  </label>
                  <textarea
                    className="textarea"
                    required={form_field.required}
                    id={form_field.field}
                    placeholder={t(`Task.${form_field.field}`)}
                    onChange={handleChange}
                    value={form_field.value}
                  />
                </div>
              );
            }
            else if (form_field.field === 'project') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(`Task.${form_field.field}`)}
                    {form_field.required ? '*' : null}
                  </label>
                  <p onClick={() => setProjectModal(!projectModal)} className='basic-input' style={{ marginTop: '0px' }}>{form_field.value || <span style={{ color: 'gray' }}>Select Project</span>}</p>
                </div>)
            }
            else if (form_field.type === 'select') {
              return (
                <div
                  id={`${form_field.field}-${index}`}
                  className="input-container"
                  key={`${form_field.field}-${index}`}
                >
                  <label id={`label-${form_field.field}`} className="label">
                    {t(`Task.${form_field.field}`)}
                    {form_field.required ? '*' : null}{' '}
                  </label>
                  <Select
                    className="custom-select"
                    required={form_field.required}
                    id={`${form_field.field}_choice`}
                    name={form_field.field}
                    onChange={handleSelectChange}
                    value={
                      form_field.field === 'asset_component' && form_field.value.length
                        ? //@ts-ignore
                        form_field.value[0]['asset_component']
                        : form_field.value
                    }
                    // @ts-ignore
                    disabled={form_field.disabled || false}
                  >
                    {form_field.options &&
                      form_field.options.map(function (opt, indexSelect) {
                        return (
                          <MenuItem
                            value={`${opt}`}
                            key={`${form_field.field}-${indexSelect}`}
                            id={`${opt}_item_${indexSelect}`}
                          >
                            <section
                              id={`section-${form_field.field}-${index}`}
                              style={{ display: 'flex', height: '1rem' }}
                            >
                              <p
                                id={`value-${form_field.field}-${index}`}
                                style={{
                                  color: 'rgba(31, 87, 148, 1)',
                                  marginLeft: '0.2rem',
                                  fontSize: '12px',
                                  marginTop: '0px',
                                }}
                              >
                                {form_field.translate ? t(`Task.${opt}`) : opt}
                              </p>
                            </section>
                          </MenuItem>
                        );
                      })}
                  </Select>
                </div>
              );
            } else if (form_field.type === 'date') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(`Task.${form_field.field}`)}
                    {form_field.required ? '*' : null}
                  </label>
                  <input
                    required={form_field.required}
                    className="date"
                    id={form_field.field}
                    placeholder={t(`Task.${form_field.field}`)}
                    onChange={handleChange}
                    value={form_field.value}
                    type="date"
                  />
                </div>
              );
            } else {
              return '';
            }
          })}
          <button className="submit-button" type="submit">
            {t('Task.create')}
          </button>
        </form>
        <div style={{ marginBottom: '1rem' }}></div>

        <IonModal
          isOpen={projectModal}
          onDidDismiss={() => setProjectModal(false)}
        ><IonHeader>
            <IonToolbar>
              <IonTitle>Projects</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setProjectModal(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            {shimmer && <Shimmer />}
            <IonList>
              {projects && projects.length ? projects.map((project: any, index: number) => (
                <IonItem
                  key={index}
                  onClick={() => {
                    setProjectModal(false);
                    setProjectInValue(project)
                  }}
                >
                  <IonLabel>{project}</IonLabel>
                  <IonCheckbox
                    slot="end"
                    value={project}
                    checked={project === selectedProject} />
                </IonItem>
              )) :
                <div style={{ margin: 'auto', display: 'block' }}>
                  <p className='text-center' style={{ color: 'gray' }}>No Asset found</p>
                </div>}
            </IonList>
            {infiniteScroll ?
              <IonInfiniteScroll onIonInfinite={(e: CustomEvent<void>) => searchNextProject(e)}>
                <IonInfiniteScrollContent
                  loadingSpinner="bubbles"
                  loadingText="Loading more data..."
                ></IonInfiniteScrollContent>
              </IonInfiniteScroll> : ''}
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default CreateTask;
