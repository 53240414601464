import {
  IonContent,
  IonPage,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonLoading,
  useIonToast,
  IonCheckbox,
  IonSkeletonText,
  IonAlert,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonSearchbar,
  IonItem,
  IonLabel,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { createMaintenanceFields } from '../../models/createMaintenanceFields';
import axios from '../../axios';
import { MenuItem, Select } from '@material-ui/core';
import Down from '../../assets/cheveron.svg';
import Signature from '../../assets/icons/signature.png';
import SignatureCanvas from 'react-signature-canvas'
import { addCircleOutline, chatbox, checkmarkCircle, clipboard, close, createOutline } from 'ionicons/icons';
import {
  getAssetComponentName,
  getSetAssetNameWithNoMaintenance,
  getSetComponentType,
  isFormDataEmpty,
} from '../../utils';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

const MaintenanceDetail: React.FC = () => {
  const location = useLocation();
  let history = useHistory();
  const [data, setData] = useState({
    name: '',
  });

  const [show1, setShow1] = useState(true);
  const [show2, setShow2] = useState(false);


  const [checked, setChecked] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [assets, setAssets] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [assetModalToggle, setSetAssetModalToggle] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState();


  const { t } = useTranslation();

  const [present] = useIonToast();

  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [_data, set_Data] = useState(createMaintenanceFields);
  const [selectedItem, setSelectedItem] = useState('');

  const [showAlert, setShowAlert] = useState({
    alert: false,
    indexValue: '',
  });


  const [shimmer, setShimmer] = useState(false);
  const [chLoader, setChLoader] = useState(false); // Checklist Create Loader
  const [signaturePadShow, setSignaturePadShow] = useState(false);
  const [remarkToggle, setRemarkToggle] = useState(false);
  const [remark, setRemark] = useState('');

  const [primaryData, setPrimaryData] = useState({});

  useEffect(() => {
    if (history?.location?.state)
      setShow1(true);
    getAssetIndividual('');
  }, [history.location.state]);

  const getAssetIndividual = async (type: any) => {

    var res: any = history.location.state;
    const url = `/resource/Maintenance Request/${res}`;
    var config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    try {
      if (!type) {
        setShimmer(true);
      }
      const res = await axios(config);
      setData(res?.data?.data);
      setPrimaryData(res?.data?.data);

      const tempCheckList = { ...checked };
      if (res.data.data['maintenance_checklist_details1'].length) {
        const mList = res.data.data['maintenance_checklist_details1'];
        for (const item in mList) {
          var value = false;
          if (mList[item]['yes_or_no'] === 'Sim' || mList[item]['yes_or_no'] === 'Yes') {
            value = true;
          }
          //@ts-ignore
          tempCheckList[mList[item]['name']] = value;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShimmer(false);
    }
  };



  const handleChange = (event: React.ChangeEvent<{ value: unknown; id: any }>) => {
    var formentry: any = [..._data];
    const f_index = formentry.findIndex((v: any) => v.field === event.target.id);
    var change_form_object = formentry[f_index];
    change_form_object.value = event.target.value;
    formentry[f_index] = change_form_object;
    set_Data(formentry);
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    var formentry: any = [..._data];
    // @ts-ignore
    const select = event.target.name.replaceAll('-', '_');
    const f_index = formentry.findIndex((v: any) => v.field === select);
    formentry[f_index].value = event.target.value;
    set_Data(formentry);
  };

  const Submit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();

    for (const item in _data) {
      const field = _data[item]?.field;
      const value = _data[item]?.value;
      if (_data[item]?.type === 'file' && _data[item]?.src) {
        try {
          //@ts-ignore
          const response = await fetch(_data[item]?.src?.toString());
          const blob = await response.blob();
          //@ts-ignore
          formData.append(field, new File([blob], `${data.name}-attachment.${_data[item].format}`, {
            // @ts-ignore
            type: `image/${_data[item].format}`,
          })
          );
        } catch (e) {
          console.log(e)
        }
      }
      //@ts-ignore
      else if (_data[item].field === 'asset' && primaryData[field] !== data.asset) {
        //@ts-ignore
        formData.append('asset', data.asset);
      } else if (typeof field === 'string' && typeof value === 'string' && value !== '' && value !== undefined && value !== null && value !== 'null' && value !== 'undefined' && value !== 'NaN') {
        //@ts-ignore
        if (field && (primaryData[field] !== value)) {
          formData.append(field, value);
        }
      }
    }

    var config: any = {
      method: 'put',
      url: `/method/preflet_asset.api.update_maintenance_request/${data.name}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: formData,
    };
    if (!isFormDataEmpty(formData)) {
      setShowLoading(true);
      try {
        await axios(config);
        reset();
        getAssetIndividual('');
      } catch (error) {
        // @ts-ignore
        console.log(error?.response?.data);
        // @ts-ignore
        present({
          message: 'Try Again',
          color: 'danger',
          duration: 2000,
        });
      } finally {
        setShowLoading(false);
        setShowModal(false);
      }
    } else {
      setShowModal(false);
    }
  };


  const reset = () => {
    for (const x in _data) {
      if (_data[x].type === 'file') {
        _data[x].src = '';
        _data[x].format = '';
        _data[x].value = '';
      } else
        if (_data[x].type === 'select') {
          _data[x].value = '';
        } else {
          _data[x].value = '';
        }
    }

    // Create Maintenance Asset Name changed back to orignal state for CREATE form
    createMaintenanceFields.map((element: any, index: any) => {
      if (element.field === 'asset') {
        element.type = 'select';
        element.disabled = false;
      }
      set_Data(createMaintenanceFields);
    });
    setShowModal(false);
  };

  // const toggleCollapse = (title: any) => {
  //   console.log(title);
  //   var collabsible = { ...show };
  //   console.log(collabsible);
  //   if (title in collabsible) {
  //     // @ts-ignore
  //     collabsible[title] = !collabsible[title];
  //   } else {
  //     // @ts-ignore
  //     collabsible[title] = true;
  //   }
  //   setShow(collabsible);
  //   console.log(show);
  //   // @ts-ignore
  //   return collabsible[title] || false;
  // };

  const toogleCheck = async (event: CustomEvent, item: any) => {
    var tempValue = 'Não';
    if (event.detail.checked) {
      tempValue = 'Sim';
    }
    //@ts-ignore
    const tempData = data['maintenance_checklist_details1']
    for (const counter in tempData) {
      if (tempData[counter].name === item) {
        tempData[counter]['yes_or_no'] = tempValue;
      }
    }
    updateCheckBox(tempData);
  };

  const addRemarks = async (item: any, actionType: any) => {
    setSelectedItem(item)
    setRemarkToggle(true);
    setRemark(item);
    if (actionType === 'add') {
      setRemark('');
    } else {
      // @ts-ignore
      for (const counter in data['maintenance_checklist_details1']) {
        //@ts-ignore
        if (data['maintenance_checklist_details1'][counter].name === item) {
          // @ts-ignore
          setRemark(data['maintenance_checklist_details1'][counter]['remarks']);
        }
      }
    }
  }

  const updateRemarkInData = async (value: any) => {
    //@ts-ignore
    const tempData = data['maintenance_checklist_details1']
    for (const counter in tempData) {
      if (tempData[counter].name === selectedItem) {
        tempData[counter]['remarks'] = value?.trim();
      }
    }
    updateCheckBox(tempData);
  }

  const updateCheckBox = async (body: any) => {
    const formData = new FormData();
    formData.append('maintenance_checklist_details1', JSON.stringify(body));

    var config: any = {
      method: 'put',
      url: `/method/preflet_asset.api.update_maintenance_request/${data.name}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: formData,
    };
    try {
      await axios(config);
      present({
        message: t('Utils.updatedChecklist'),
        color: 'success',
        duration: 800,
      });
    } catch (error) {
      console.log(error);
      // @ts-ignore
      console.log(error?.response?.data);
      present({
        message: 'Something went wrong',
        color: 'danger',
        duration: 2000,
      });
    } finally {
      getAssetIndividual('checklist');
    }
  };

  // const toggleCheckListTitle = async (title: any) => {
  //   var ct = { ...checklist };
  //   if (title in ct) {
  //     // @ts-ignore
  //     ct[title] = !ct[title];
  //   } else {
  //     // @ts-ignore
  //     ct[title] = true;
  //   }
  //   setChecklist(ct);
  //   // @ts-ignore
  //   return ct[title] || false;
  // };

  /* Set EDIT FORM for MAINTENANCE excluding CHECKLIST */
  const setEditForm = async () => {
    setShowModal(true);
    setShowLoading(true);
    const component_type = await getSetComponentType();
    const component_name = await getAssetComponentName();
    const assetOptions = await getSetAssetNameWithNoMaintenance(data.name, '', currentPage); // Filter maintenance done assets
    setAssets(assetOptions);
    createMaintenanceFields.map((element: any, index: any) => {
      if (element.field === 'project') {
        //@ts-ignore
        element.value = data[element.field];
        element.type = 'text';
        element.disabled = true;
      }
      if (element.field === 'asset') {
        // @ts-ignore
        element.value = data[element.field];
        element.options = assetOptions;
      } else if (element.field === 'component_type') {
        element.options = component_type;
        // @ts-ignore
        element.value = data[element.field];
      } else if (element.field === 'component_name') {
        element.options = component_name;
        // @ts-ignore
        element.value = data[element.field];
      } else if (element.type === 'file') {
        // @ts-ignore
        element.value = data[element.field];
      }
      else {
        // @ts-ignore
        element.value = data[element.field];
      }
      set_Data(createMaintenanceFields);
    });
    setShowLoading(false);
  };

  async function searchNext($event: CustomEvent<void>) {
    const res = await getSetAssetNameWithNoMaintenance('', searchText, currentPage + 1);
    if (res.length) {
      //@ts-ignore  
      setAssets([...assets, ...res]);
    }
    setCurrentPage(currentPage + 1);
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  const handleSearch = async (text: any) => {
    setSearchText(text);
    const res = await getSetAssetNameWithNoMaintenance('', text, 1);
    if (res.length) {
      setAssets(res);
    } else {
      setAssets([])
    }
    setCurrentPage(1);
  }

  const setAssetInValue = (asset: any) => {
    // @ts-ignore
    data['asset'] = asset;
    setSelectedAsset(asset);
  }


  // const submitCheckListTitle = async (value: any, index: any) => {
  //   if (!value[index]) {
  //     present({
  //       message: 'Empty ' + index,
  //       color: 'danger',
  //       duration: 2000,
  //     });
  //   } else {
  //     //@ts-ignore
  //     data[index] = value[index];
  //     var config: any = {
  //       method: 'put',
  //       url: `/resource/Maintenance Request/${data.name}`,
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Accept: 'application/json',
  //         Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
  //       },
  //       data: data,
  //     };
  //     try {
  //       setSaveC(true);
  //       const res = await axios(config);
  //     } catch (error) {
  //       // @ts-ignore
  //       console.log(error.response.data);
  //       // @ts-ignore
  //       // const x = error.response.data._server_messages;
  //       present({
  //         message: 'Try Again',
  //         color: 'danger',
  //         duration: 2000,
  //       });
  //     } finally {
  //       setSaveC(false);
  //       getAssetIndividual();
  //     }
  //   }
  // };

  // CREATE NEW CHECKLIST ROW
  // const addCheckListDetail = async (values: any, index: any) => {
  //   if (values.activity && values.remarks) {
  //     var tempData = { ...data };
  //     const temp = {
  //       activity: values.activity,
  //       remarks: values.remarks,
  //       yes_or_no: 'Não',
  //     };
  //     if (index === 'checklist1') {
  //       //@ts-ignore
  //       tempData['maintenance_checklist_details1'].push(temp);
  //     } else if (index === 'checklist2') {
  //       //@ts-ignore
  //       tempData['maintenance_checklist_details2'].push(temp);
  //     } else {
  //       //@ts-ignore
  //       tempData['maintenance_checklist_details3'].push(temp);
  //     }
  //     //@ts-ignore
  //     var config: any = {
  //       method: 'put',
  //       url: `/resource/Maintenance Request/${data.name}`,
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Accept: 'application/json',
  //         Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
  //       },
  //       data: tempData,
  //     };
  //     try {
  //       setChLoader(true);
  //       const res = await axios(config);
  //       getAssetIndividual();
  //     } catch (error) {
  //       // @ts-ignore
  //       console.log(error.response.data);
  //       // @ts-ignore
  //       // const x = error.response.data._server_messages;
  //       // console.log(x.slice(18).slice(0, x.slice(18).indexOf('\\')));
  //       present({
  //         message: 'Try Again',
  //         color: 'danger',
  //         duration: 2000,
  //       });
  //     } finally {
  //       setChLoader(false);
  //     }
  //   } else {
  //     present({
  //       message: 'Empty ' + t(`Maintenance.CreateM.${index}`),
  //       color: 'danger',
  //       duration: 2000,
  //     });
  //   }
  // };

  const uploadSignature = () => {
    setSignaturePadShow(true);
  };
  const [canvas, setCanvas] = useState(null);

  const getSignature = () => {
    // @ts-ignore
    uploadSign(canvas?.toDataURL())
  }

  const uploadSign = async (base64: any) => {
    var config: any = {
      method: 'put',
      url: `/resource/Maintenance Request/${data.name}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: {
        sign: base64
      },
    };
    setChLoader(true);
    try {
      const res = await axios(config);
      setSignaturePadShow(false)
      getAssetIndividual('');
      present({
        message: 'Signature Uploaded',
        color: 'success',
        duration: 2000,
      });
    } catch (error) {
      // @ts-ignore
      console.log(error?.response?.data);
      // @ts-ignore
      present({
        message: 'Try Again',
        color: 'danger',
        duration: 2000,
      });
    } finally {
      setChLoader(false);
    }
  };
  const Shimmer = () => {
    return (
      <div>
        {
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((element) => {
            return (
              <div
                key={element}
                id="shimmer-body"
                style={{ padding: '1rem' }}
                className="w-50 data-list shimmer-container"
              >
                <div className="flex justify-between">
                  <IonSkeletonText animated style={{ width: '50%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };

  const takeQSPicture = async (field: any) => {
    try {
      const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
      });
      var imageUrl = image.webPath;

      // Can be set to the src of an image now
      var formentry: any = [..._data];
      const f_index = formentry.findIndex((v: any) => v.field === field);
      formentry[f_index].value = null;
      formentry[f_index].src = imageUrl;
      formentry[f_index].format = image.format;
      set_Data(formentry);
    } catch (e) {
      //@ts-ignore
      console.log('Error: ', e);
      // @ts-ignore
      if (e?.toString()?.includes('cancelled')) {
        //@ts-ignore
        present({
          message: t('Utils.cameraCancel'),
          color: 'danger',
          duration: 2000,
        });
      }
      //@ts-ignore
      else if (e?.toString()?.includes('denied') || e?.includes('access')) {
        //@ts-ignore
        present({
          message: t('Utils.cameraErrorPermission'),
          color: 'danger',
          duration: 2000,
        });
      } else {
        //@ts-ignore
        present({
          message: e,
          color: 'danger',
          duration: 2000,
        });
      }
    }
  };

  /* Remove Quick Status Image by cross click */
  const emptyQSImg = (field: any) => {
    var formentry: any = [..._data];
    const f_index = formentry.findIndex((v: any) => v.field === field);
    formentry[f_index].value = null;
    formentry[f_index].src = null;
    formentry[f_index].format = null;
    set_Data(formentry);
  };

  return (
    <IonPage>
      <Header enableBackButton={location.search ? '/' + location.search.replace("?back=", "") : '/maintenanceHistory'} title={t('Maintenance.Detail.Det')} />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={chLoader}
        onDidDismiss={() => setChLoader(false)}
        message={'Please wait...'}
      />
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          duration={5000}
        />
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>
              {t(`Assets.CreateA.edit`)} {data.name}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  reset();
                }}
              >
                <IonIcon name="close" slot="icon-only"></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <form onSubmit={(e) => Submit(e)}>
            {createMaintenanceFields.map((form_field, index) => {
              //  Exluding Checklist in this FORM
              //@ts-ignore
              if (!form_field.name) {
                if (form_field.title && form_field.title !== 'Maintenance.Detail.AC') {
                  return (
                    <p className="form-title" key={`${form_field.title}-${index}`}>
                      {t(form_field.title)}
                    </p>
                  );
                } else if (form_field.type === 'text') {
                  return (
                    <div className="input-container" key={`${form_field.field}`}>
                      <label className="label">
                        {t(form_field.label)}
                        {form_field.required ? '*' : null}
                      </label>
                      <input
                        required={form_field.required}
                        className="basic-input"
                        key={`${form_field.field}-${index}`}
                        id={form_field.field}
                        placeholder={t(form_field.label)}
                        onChange={handleChange}
                        value={form_field.value}
                        disabled={form_field.disabled}
                        style={{
                          background: form_field.disabled ? '#F1F7F5' : ''
                          }}
                      />
                    </div>
                  );
                } else if (form_field.type === 'textarea') {
                  return (
                    <div className="input-container" key={`${form_field.field}`}>
                      <label className="label">
                        {t(form_field.label)}
                        {form_field.required ? '*' : null}
                      </label>

                      <textarea
                        className="textarea"
                        required={form_field.required}
                        key={`${form_field.field}-${index}`}
                        id={form_field.field}
                        placeholder={t(form_field.label)}
                        onChange={handleChange}
                        value={form_field.value}
                      />
                    </div>
                  );
                } else if (form_field.field === 'asset') {
                  return (
                    <div className="input-container" key={`${form_field.field}-${index}`}>
                      <label className="label">
                        {t(form_field.label)}
                        {form_field.required ? '*' : null}
                      </label>
                      <p onClick={() => setSetAssetModalToggle(!assetModalToggle)} className='basic-input' style={{ marginTop: '0px' }}>{
                        // @ts-ignore
                        data['asset'] || <span style={{ color: 'gray' }}>Select Asset</span>}</p>
                    </div>)
                }
                else if (form_field.type === 'select') {
                  return (
                    <div className="input-container" key={`${form_field.field}`}>
                      <label className="label">
                        {t(form_field.label)}
                        {form_field.required ? '*' : null}
                      </label>

                      <Select
                        className="custom-select"
                        required={form_field.required}
                        id={`${form_field.field}_choice`}
                        name={form_field.field}
                        onChange={handleSelectChange}
                        value={form_field.value}
                        disabled={form_field.disabled || false}
                        key={`${form_field.field}-${index}`}
                      >
                        {form_field.options &&
                          form_field.options.map(function (opt, optIndex) {
                            return (
                              <MenuItem value={`${opt}`} key={`${opt}-${optIndex}`} id={`${opt}_item_${optIndex}`}>
                                <section style={{ display: 'flex', height: '1rem' }}>
                                  <p
                                    style={{
                                      color: 'rgba(31, 87, 148, 1)',
                                      marginLeft: '0.2rem',
                                      fontSize: '12px',
                                      marginTop: '0px',
                                    }}
                                  >
                                    {t(opt)}
                                  </p>
                                </section>
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  );
                }
                else if (form_field.type === 'file') {
                  return (
                    <div
                      id={`${form_field.field}-${index}`}
                      className="input-container"
                      key={`${form_field.field}-${index}`}
                      style={{ marginTop: '1.5rem' }}
                    >
                      <label id={`label-${form_field.field}`} className="label">
                        {t(form_field.label)}
                        {form_field.required ? '*' : null}{' '}
                      </label>
                      <IonButton
                        style={{ marginTop: '0.5rem', margin: 'auto', width: '40%', color: 'black', marginBottom: '1rem' }}
                        size="small"
                        color="light"
                        onClick={() => takeQSPicture(form_field.field)}
                      >
                        {//@ts-ignore
                          form_field.value || form_field['src'] ? t('Utils.change_image') : t('Utils.image')}
                      </IonButton>

                      <div className="flex justify-center items-center">
                        {//@ts-ignore
                          form_field['value'] ? (
                            <img
                              src={`${localStorage.getItem('baseUrl')?.replace('/api', '')}${
                                //@ts-ignore
                                form_field['value']}`}
                              alt=""
                              style={{
                                width: '60%',
                                margin: 'auto',
                                display: 'block',
                                border: '1px solid lightgray',
                                borderRadius: '12px',
                              }}
                            />
                          ) : (
                            ''
                          )}
                        {
                          form_field['src'] ? <img className="preview-survey-img" src={form_field['src']} alt="" /> : ''}
                        {
                          form_field['src'] ? (
                            <IonIcon className="close-icon" onClick={() => emptyQSImg(form_field.field)} src={close} />
                          ) : (
                            ''
                          )}
                      </div>
                    </div>
                  );
                }
                else if (form_field.type === 'date' || form_field.type === 'datetime-local') {
                  return (
                    <div className="input-container" key={`${form_field.field}-${index}`}>
                      <label className="label">
                        {t(form_field.label)}
                        {form_field.required ? '*' : null}
                      </label>

                      <input
                        required={form_field.required}
                        className="date"
                        key={`${form_field.field}-${index}`}
                        id={form_field.field}
                        placeholder={t(form_field.label)}
                        onChange={handleChange}
                        value={form_field.value}
                        type={form_field.type}
                      />
                    </div>
                  );
                } else {
                  return '';
                }
              }
            })}
            <button className="submit-button" type="submit">
              {t(`Service.ServiceCreate.save`)}{' '}
            </button>
          </form>
        </IonContent>
      </IonModal>
      <IonModal
        isOpen={assetModalToggle}
        onDidDismiss={() => setSetAssetModalToggle(false)}
      ><IonHeader>
          <IonToolbar>
            <IonTitle>Assets</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setSetAssetModalToggle(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSearchbar
            className="data-list"
            value={searchText}
            // @ts-ignore
            onIonChange={(e) => handleSearch(e.detail.value!)}
          ></IonSearchbar>
          {shimmer && <Shimmer />}
          <IonList>
            {assets && assets.length ? assets.map((asset, index) => (
              <IonItem
                key={index}
                onClick={() => {
                  setSetAssetModalToggle(false);
                  setAssetInValue(asset)
                }}
              >
                <IonLabel>{asset}</IonLabel>
                <IonCheckbox
                  slot="end"
                  value={asset}
                  checked={asset === selectedAsset} />
              </IonItem>
            )) :
              <div style={{ margin: 'auto', display: 'block' }}>
                <p className='text-center' style={{ color: 'gray' }}>No Asset found</p>
              </div>}
          </IonList>

          <IonInfiniteScroll onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Loading more data..."
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>
      </IonModal>
      <IonContent>
        <div className="preview-header">
          <p className="preview-title">{data.name}</p>
          <div>
            {//@ts-ignore
              data['sign'] ?
                <div>
                  <img src={Signature} alt='sign' style={{ width: '25px', cursor: 'not-allowed' }} />
                  <IonIcon src={checkmarkCircle} style={{ color: 'green', fontSize: '18px', marginLeft: '-11px', marginBottom: '-3px' }} />
                </div> :
                <img src={Signature} alt='sign' className='pointer' style={{ width: '25px' }}
                  onClick={() => uploadSignature()} />}
          </div>
        </div>

        <div onClick={() => setShow1(!show1)} className="collapsible flex data-list justify-between">
          <p>{t('Utils.basicD')}</p>
          <img
            style={
              /* @ts-ignore */
              !show1 ? { transform: 'rotate(0deg)' } : { transform: 'rotate(180deg)' }
            }
            src={Down}
            alt="down"
          />
        </div>

        <div hidden={!show1} className="data-list flex" style={{ justifyContent: 'flex-end' }}>
          <IonIcon
            onClick={() => setEditForm()}
            style={{ color: '#1d508d', fontSize: '30px', margin: '5px', cursor: 'pointer' }}
            src={createOutline}
          />
        </div>

        <div hidden={!show1} style={{ border: '1px solid lightgray', margin: '5px', borderRadius: '6px' }}>

          <div className="data-list">
            {// @ts-ignore
              data.sign && (
                <div className="preview-container">
                  <p className="preview-key">{t(`Maintenance.CreateM.sign`)}</p>
                  <p className="preview-value">
                    <img src={
                      // @ts-ignore
                      data['sign']} alt='sign' style={{ border: '1px solid lightgray', borderRadius: '8px', margin: 'auto', display: 'block' }} />
                  </p>
                </div>
              )}
          </div>
          {createMaintenanceFields.map((item: any, index) => {
            return (
              <div className="data-list" key={`maintenance-detail-${index}`}>
                {item.field && !item.name ? (
                  <div className="preview-container">
                    <p className="preview-key">{t(`Maintenance.CreateM.${item.field}`)}</p>
                    <p className="preview-value">
                      {
                        // @ts-ignore
                        item.type === 'file' ? data[item.field] && <img src={localStorage.getItem('baseUrl')?.replace('/api', '') + data[item.field]} style={{
                          width: '80%',
                          margin: 'auto',
                          display: 'block',
                          border: '1px solid lightgray',
                          borderRadius: '8px',
                        }} /> || '-' :
                          // @ts-ignore
                          typeof data[item.field] === 'object'
                            ? // @ts-ignore
                            data[item.field][0].activity
                            : // @ts-ignore
                            data[item.field]
                              ? // @ts-ignore
                              data[item.field]
                              : '-'
                      }
                    </p>
                  </div>
                ) : (
                  ''
                )}
              </div>
            );
          })}
        </div>


        { // @ts-ignore
          !shimmer && data && data['maintenance_checklist_details1'] && data['maintenance_checklist_details1'].length && (
            <div onClick={() => setShow2(!show2)} className="collapsible flex data-list justify-between">
              <p>{t('Utils.checkList')}</p>
              <img
                style={
                  /* @ts-ignore */
                  !show2 ? { transform: 'rotate(0deg)' } : { transform: 'rotate(180deg)' }
                }
                src={Down}
                alt="down"
              />
            </div>
          ) || ''}
        {!shimmer && show2 &&
          // @ts-ignore
          Object.keys(data).length && data['maintenance_checklist_details1'] && data['maintenance_checklist_details1'].map((item: any, index: any) => {
            return (
              <div
                className="flex justify-between"
                style={{
                  border: '1px solid lightgray',
                  borderRadius: '6px',
                  margin: '4px',
                  padding: '10px',
                  alignItems: 'center'
                }}
                key={`checklist-sub-detail-` + index}
              >
                <div>
                  <div className="flex"
                    style={{ alignItems: 'center' }}>
                    <IonIcon
                      style={{ color: '#243656', opacity: '0.5' }}
                      src={clipboard}
                    ></IonIcon>
                    <p className='checklist-title'>{item.activity}</p>
                  </div>
                  <div>
                    {item.remarks ? <div className="flex" style={{ alignItems: 'center' }}>
                      <IonIcon
                        style={{ color: '#243656', opacity: '0.5' }}
                        src={chatbox}
                      ></IonIcon><p className='checklist-remark'>{item.remarks}</p>
                      <span onClick={(e) => addRemarks(item.name, 'edit')} className='underline' style={{ marginLeft: '1rem', cursor: 'pointer', fontWeight: 'bolder', color: '#1d508d' }}>{t('Utils.edit')}</span>
                    </div> : <div className="flex" style={{ alignItems: 'center' }}>
                      <p style={{ color: 'gray', marginRight: '1rem' }}>{t('Utils.remarks')}</p>
                      <IonIcon
                        onClick={(e) => addRemarks(item.name, 'add')}
                        style={{ color: '#243656', opacity: '0.5', cursor: 'pointer', fontSize: '22px' }}
                        src={addCircleOutline}
                      ></IonIcon> </div>}
                  </div>

                </div>
                <div>
                  <IonCheckbox
                    color="danger"
                    id={item.name}
                    // @ts-ignore
                    checked={(item.yes_or_no && (item.yes_or_no === 'Sim' || item.yes_or_no === 'Yes')) ? true : false}
                    // @ts-ignore
                    // onClick={(e) => toogleCheck(e, item.name)}
                    onIonChange={(event) => toogleCheck(event, item.name)}
                  />
                </div>
              </div>
            )
          }) || ''
        }

        <IonModal isOpen={signaturePadShow}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Signature Pad</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setSignaturePadShow(false)}>{t('Utils.close')}</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <div style={{ marginTop: '2rem', border: '1px solid lightgray', margin: '5px', borderRadius: '5px' }}>
            <SignatureCanvas penColor='#1d508d'
              backgroundColor='rgba(255, 255, 255, 0)'
              ref={(ref: any) => { setCanvas(ref) }}
              dotSize={5}
              canvasProps={{ width: 500, height: 400, className: 'sigCanvas' }} />
          </div>
          <button className="submit-button" onClick={() => getSignature()}>
            {t(`Utils.addSignautre`)}
          </button>
        </IonModal>
      </IonContent>
      <IonAlert
        isOpen={remarkToggle}
        onDidDismiss={() => setRemarkToggle(false)}
        header="Enter Remark"
        inputs={[
          {
            type: 'textarea',
            placeholder: 'Add remark',
            value: remark,
          },
        ]}
        buttons={[
          {
            text: t('Utils.add'),
            handler: (event) => {
              updateRemarkInData(event[0]);
              setRemarkToggle(false);
            },
          },
        ]}
      ></IonAlert>
    </IonPage>
  );
};

export default MaintenanceDetail;
