import React, { useState } from 'react';
import { IonContent, IonRouterLink, IonList, IonPage, IonItem, IonGrid, IonRow, IonCol, IonPopover, IonAvatar } from '@ionic/react';
import Profileselector from "../assets/Profileselector.svg"
import defaultavatar from "../assets/avatardefault.svg"
import notifbell from "../assets/notifbell.svg"
import { NavLink } from 'react-router-dom';
import NoRec from "../assets/NoRec.svg"
import BackBlue from "../assets/BackBlue.svg"
import LogOut from "../assets/Logout.svg"
import P1 from "../assets/P1.svg"
import MenuItems from "../components/MenuItem"
import { useTranslation } from 'react-i18next';






const avatarcss = {
    width: "35px",
    height: "34px",
    top: '3rem'
}





const NoRecFound: React.FC = () => {

    const { t } = useTranslation();

    const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });



    return (
        <IonPage>
            <IonContent >

                <div style={{ backgroundImage: "radial-gradient(#0070BA, #1546A0)", boxShadow: "0px 16px 36px -16px rgba(21, 70, 160, 0.5)", height: "15vh" }}>



                    <IonGrid >
                        <IonRow className="ion-justify-content-around">

                            <IonCol size="4" style={{ margin: "auto" }}>

                                <MenuItems />

                            </IonCol>


                            <IonCol size="4" style={{ margin: "auto" }}>
                                <div style={{ textAlign: "center", margin: "auto" }}>
                                    <p style={{ color: "rgba(255, 255, 255, 1)", fontSize: "1.2rem" }}>Hyprism</p>
                                </div>
                            </IonCol>


                            <IonCol size="4">
                                <div style={{ float: "right", display: "flex", marginRight: "-10px" }}>



                                    <div className="dropdownnotif">


                                        <img src={notifbell} style={{ marginRight: "0px", marginTop: "1.3rem" }} className="ion-margin" alt="notifs" />

                                        <div className="dropdownnotif-content">
                                            <section style={{ display: "flex" }}>
                                                <p style={{ fontFamily: "Nunito sans, sans serif", margin: "auto", color: "rgba(29, 80, 141, 1)", marginTop: "0.5rem", marginLeft: "0.5rem", fontSize: "15px", textAlign: "left" }}>{t("Header.Notifs.Head")}</p>
                                                <p style={{ fontFamily: "inter", margin: "auto", color: "rgba(29, 80, 141, 1)", marginTop: "0.6rem", marginRight: "1rem", float: "right", fontSize: "13px", textAlign: "left" }}>{t("Header.Notifs.VA")}</p>


                                            </section>





                                            <section style={{ display: "flex", width: "100%" }}>
                                                <img src={P1} alt="rocket" style={{ height: "38px", marginLeft: "5px", marginTop: "1rem" }} />
                                                <section style={{ flex: "column" }}>
                                                    <p style={{ fontFamily: "Nunito sans,sans serif", marginLeft: "0.4rem", marginTop: "1.3rem", color: "rgba(29, 80, 141, 1)", fontSize: "13px" }}>User1 {t("Header.Notifs.Request")}</p>
                                                    <p style={{ fontFamily: "Inter", marginLeft: "0.5rem", color: "rgba(146, 155, 171, 1)", marginTop: "-0.9rem", fontSize: "11px" }}>{t("Header.Notifs.Desc")} Lorem Ipsum Hodor Niroa...</p>
                                                </section>
                                            </section>
                                            <p style={{ fontFamily: "Inter", color: "rgba(146, 155, 171, 1)", float: "right", marginTop: "-0.5rem", fontSize: "10px", paddingRight: "1rem" }}>7 {t("Header.Notifs.HO")}</p>




                                            <section style={{ display: "flex", width: "100%" }}>
                                                <img src={P1} alt="rocket" style={{ height: "38px", marginLeft: "5px", marginTop: "0.8rem" }} />
                                                <section style={{ flex: "column" }}>
                                                    <p style={{ fontFamily: "Nunito sans,sans serif", marginLeft: "0.4rem", marginTop: "1.3rem", color: "rgba(29, 80, 141, 1)", fontSize: "13px" }}>User1 {t("Header.Notifs.Request")}</p>
                                                    <p style={{ fontFamily: "Inter", marginLeft: "0.5rem", color: "rgba(146, 155, 171, 1)", marginTop: "-0.9rem", fontSize: "11px" }}>{t("Header.Notifs.Desc")} Lorem Ipsum Hodor Niroa...</p>
                                                </section>
                                            </section>
                                            <p style={{ fontFamily: "Inter", color: "rgba(146, 155, 171, 1)", float: "right", marginTop: "-0.5rem", fontSize: "10px", paddingRight: "1rem" }}>7 {t("Header.Notifs.HO")}</p>



                                            <section style={{ display: "flex", width: "100%" }}>
                                                <img src={P1} alt="rocket" style={{ height: "38px", marginLeft: "5px", marginTop: "0.8rem" }} />
                                                <section style={{ flex: "column" }}>
                                                    <p style={{ fontFamily: "Nunito sans,sans serif", marginLeft: "0.4rem", marginTop: "1.3rem", color: "rgba(29, 80, 141, 1)", fontSize: "13px" }}>User1 {t("Header.Notifs.Request")}</p>
                                                    <p style={{ fontFamily: "Inter", marginLeft: "0.5rem", color: "rgba(146, 155, 171, 1)", marginTop: "-0.9rem", fontSize: "11px" }}>{t("Header.Notifs.Desc")} Lorem Ipsum Hodor Niroa...</p>
                                                </section>
                                            </section>
                                            <p style={{ fontFamily: "Inter", color: "rgba(146, 155, 171, 1)", float: "right", marginTop: "-0.8rem", fontSize: "10px", paddingRight: "1rem" }}>7 {t("Header.Notifs.HO")}</p>



                                            <section style={{ display: "flex", width: "100%" }}>
                                                <img src={P1} alt="rocket" style={{ height: "38px", marginLeft: "5px", marginTop: "1rem" }} />
                                                <section style={{ flex: "column" }}>
                                                    <p style={{ fontFamily: "Nunito sans,sans serif", marginLeft: "0.4rem", marginTop: "1.3rem", color: "rgba(29, 80, 141, 1)", fontSize: "13px" }}>User1 {t("Header.Notifs.Request")}</p>
                                                    <p style={{ fontFamily: "Inter", marginLeft: "0.5rem", color: "rgba(146, 155, 171, 1)", marginTop: "-0.9rem", fontSize: "11px" }}>{t("Header.Notifs.Desc")} Lorem Ipsum Hodor Niroa...</p>
                                                </section>
                                            </section>
                                            <p style={{ fontFamily: "Inter", color: "rgba(146, 155, 171, 1)", float: "right", marginTop: "-0.5rem", fontSize: "10px", paddingRight: "1rem" }}>7 {t("Header.Notifs.HO")}</p>



                                        </div>



                                    </div>
                                    <IonPopover
                                        id="popover"
                                        cssClass='my-custom-class'
                                        event={popoverState.event}
                                        isOpen={popoverState.showPopover}
                                        onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
                                    >

                                        <IonList>
                                            <NavLink to="../profile" className="popovercont" style={{ color: "#1D508D" }} onClick={() => setShowPopover({ showPopover: false, event: undefined })}>
                                                <img style={{ width: "2rem", marginLeft: "1.8rem" }} src={Profileselector} alt="Profile"></img>
                                                <p style={{ marginLeft: "5px" }}>{t("Header.Avatar.Profile")}</p>
                                            </NavLink>

                                            <NavLink to="/" className="popovercont" style={{ color: "#E24444" }} onClick={() => setShowPopover({ showPopover: false, event: undefined })}>
                                                <img style={{ width: "2rem", marginLeft: "2rem" }} src={LogOut} alt="Profile"></img>
                                                <p style={{ marginLeft: "5px" }}>{t("Header.Avatar.LogOut")}</p>
                                            </NavLink>
                                        </IonList>



                                    </IonPopover>
                                    <IonAvatar onClick={
                                        (e: any) => {
                                            e.persist();
                                            setShowPopover({ showPopover: true, event: e })
                                        }}>
                                        <img src={defaultavatar} alt="my account" style={avatarcss} className="ion-margin" />
                                    </IonAvatar>
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                </div>



                <IonItem className="heading">
                    <p className="ion-margin">{t("Maintenance.Recent")} by Abhishek</p>
                </IonItem>

                <div style={{ width: "100%", textAlign: "center", marginTop: "10vh" }}>
                    <img src={NoRec} alt="No Record Found" style={{ width: "60vw", margin: "auto" }} />


                    <h3 style={{ fontFamily: "Nunito sans,sans serif", textAlign: "center", color: "rgba(29, 80, 141, 1)", marginTop: "2rem" }}>{t("Maintenance.Records.NoRec")}</h3>

                    <p style={{ fontFamily: "Inter", textAlign: "center", color: "rgba(29, 80, 141, 1)", fontSize: "smaller", marginTop: "-2px" }}>{t("Maintenance.Records.Norec1")}.<br />
                        {t("Maintenance.Records.Later")}.</p>

                    <IonRouterLink href="#" style={{ textDecoration: "none" }}>
                        <section style={{ display: "flex" }}>
                            <img src={BackBlue} alt="Back" style={{ marginLeft: "auto" }} />
                            <p style={{ fontFamily: "Inter", color: "rgba(29, 80, 141, 1)", fontSize: "smaller", marginLeft: "10px", marginRight: "auto" }}>{t("Maintenance.Records.home")}</p>
                        </section>
                    </IonRouterLink>



                </div>




            </IonContent>
        </IonPage>
    );
};

export default NoRecFound;