import { useState, useEffect } from 'react';
import {
    IonContent,
    IonPage,
    useIonToast,
    useIonViewWillEnter,
    IonLoading,
    useIonRouter,
    IonButton,
    IonIcon,
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonLabel,
    IonList,
    IonItem,
    IonCheckbox,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSkeletonText,
    IonSearchbar,
} from '@ionic/react';
import Header from '../../components/Header';
import axios from '../../axios';
import { useTranslation } from 'react-i18next';
import { Network } from '@capacitor/network';
import { createModel937 } from '../../models/model937.js';
import { MenuItem, Select } from '@material-ui/core';
import { getSetAssetNameWithNoModelo, getSetProjectList } from '../../utils';
import { fingerPrint } from 'ionicons/icons';
import { useHistory } from 'react-router';
import SignatureCanvas from 'react-signature-canvas'

const ModelCreate: React.FC = () => {
    const [showLoading, setShowLoading] = useState(false);
    const [isOnline, setIsOnline] = useState(Boolean);
    const history = useHistory();

    const router = useIonRouter();
    const [present] = useIonToast();
    const { t } = useTranslation();
    const url = '/resource/Model 937';
    const [data, setData] = useState(createModel937);
    const [canvas, setCanvas] = useState(null);
    const [signaturePadShow, setSignaturePadShow] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    const [assetModalToggle, setSetAssetModalToggle] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState();
    const [projectModal, setProjectModal] = useState(false);
    const [shimmer, setShimmer] = useState(false);
    const [infiniteScroll, setInfiniteScroll] = useState(true);

    const [assets, setAssets] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedAsset, setSelectedAsset] = useState();


    const handleChange = (event: React.ChangeEvent<{ value: unknown; id: any }>) => {
        var formentry: any = [...data];
        const f_index = formentry.findIndex((v: any) => v.key === event.target.id);
        var change_form_object = formentry[f_index];
        change_form_object.value = event.target.value;
        formentry[f_index] = change_form_object;
        setData(formentry);
    };

    const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        var formentry: any = [...data];
        // @ts-ignore
        const select = event.target.name.replaceAll('-', '_');
        const f_index = formentry.findIndex((v: any) => v.key === select);
        formentry[f_index].value = event.target.value;
        setData(formentry);
    };

    useIonViewWillEnter(async () => {
        var Data = [...data];

        /* Get Asset name */
        const aname = Data.findIndex((d) => d.key === 'asset');
        Data[aname].options = await getSetAssetNameWithNoModelo(currentPage, searchText);

        const assetsList = Data[aname].options;
        // @ts-ignore
        setAssets(assetsList);
        createModel937.map(item => {
            if (item.key === 'project') {
                item.type = 'select'
            }
        })
        /* get Project name */
        const pname = Data.findIndex((d) => d.key === 'project');
        Data[pname].options = await getSetProjectList(currentPage);
        const projectList = Data[pname].options || [];
        if (projectList.length < 10) {
            setInfiniteScroll(false);
        }
        // @ts-ignore
        setProjects(projectList || []);
        setData(Data);
    });

    useEffect(() => {
        (async () => {
            const internetStatus = await Network.getStatus();
            setIsOnline(internetStatus.connected);
            Network.addListener('networkStatusChange', (status) => {
                setIsOnline(status.connected);
            });
        })();
    }, []);


    const Submit = (e: any) => {
        e.preventDefault();
        let valid = true;

        if (valid) {
            hitCreateApi();
            if (!isOnline) {
                const allData = JSON.parse(localStorage.getItem('allData') || '[]');
                const newData = { data, url };
                allData.push(newData);
                localStorage.setItem('allData', JSON.stringify(allData));
            }
        }
    };

    const hitCreateApi = async () => {
        const formData = new FormData();
        const apiData: { [key: string]: any } = {};
        for (const item in data) {
            const field = data[item]?.key;
            const value = data[item]?.value;
            if (typeof field === 'string' && typeof value === 'string' && value !== '' && value !== undefined && value !== null && value !== 'null' && value !== 'undefined' && value !== 'NaN' && value !== 'NaN-NaN-NaN') {
                apiData[field] = value;
            }
        }
        formData.append('data', JSON.stringify(apiData));

        var config: any = {
            method: 'post',
            url,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
            },
            data: formData,
        };
        try {
            setShowLoading(true);
            axios(config)
                .then(async (res) => {
                    if (res.status === 200) {
                        present({
                            message: t('Model937.createSuccess'),
                            duration: 2000,
                        });
                        reset();
                        history.push({
                            pathname: '/model-home',
                            state: '',
                        });
                    }
                })
                .catch(function (error) {
                    setShowLoading(false);
                    console.log(error?.response?.data);
                    const x = error.response.data._server_messages;
                    console.log(x?.slice(18)?.slice(0, x?.slice(18)?.indexOf('\\')));
                    present({
                        message: x?.slice(18)?.slice(0, x?.slice(18)?.indexOf('\\')),
                        color: 'danger',
                        duration: 2000,
                    });
                })
                .finally(() => {
                    setShowLoading(false);
                });
        } catch (error) {
            console.log(error);
        }
    };

    const reset = () => {
        var formentry: any = [...data];
        var x: any;
        for (x in formentry) {
            if (data[x].type === 'select') {
                data[x].value = '';
            } else {
                data[x].value = '';
            }
        }
        setData(formentry);
    };


    const getSignature = () => {
        // @ts-ignore
        uploadSign(canvas?.toDataURL())
    }

    const uploadSignature = () => {
        setSignaturePadShow(true);
    };

    const uploadSign = async (base64: any) => {
        if (base64) {
            var Data = [...data];
            const sign = Data.findIndex((d) => d.type === 'signature');
            Data[sign].value = base64;
            setData(Data);
        }
        setSignaturePadShow(false);
    };

    async function searchNextProject($event: CustomEvent<void>) {
        try {
            const res = await getSetProjectList(currentPage + 1);
            if (res?.length) {
                //@ts-ignore  
                setProjects([...projects, ...res]);
            }
        } catch (error) {
            console.log(error)
        } finally {
            setShimmer(false)
            setCurrentPage(currentPage + 1);
        }
        ($event.target as HTMLIonInfiniteScrollElement).complete();
    }

    const setProjectInValue = (project: any) => {
        var Data = [...data];
        const aname = Data.findIndex((d) => d.key === 'project');
        Data[aname].value = project;
        setSelectedProject(project);
    }

    async function searchNext($event: CustomEvent<void>) {
        const res = await getSetAssetNameWithNoModelo(currentPage + 1, searchText);
        if (res.length) {
            //@ts-ignore  
            setAssets([...assets, ...res]);
        }
        setCurrentPage(currentPage + 1);
        ($event.target as HTMLIonInfiniteScrollElement).complete();
    }

    const setAssetInValue = (asset: any) => {
        var Data = [...data];
        const aname = Data.findIndex((d) => d.key === 'asset');
        Data[aname].value = asset;
        setSelectedAsset(asset);
    }

    const handleSearch = async (text: any) => {
        setSearchText(text);
        const res = await getSetAssetNameWithNoModelo(1, text);
        if (res.length) {
            setAssets(res);
        } else {
            setAssets([])
        }
        setCurrentPage(1);
    }

    const Shimmer = () => {
        return (
            <div>
                {
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((element) => {
                        return (
                            <div
                                key={element}
                                id="shimmer-body"
                                style={{ padding: '1rem' }}
                                className="w-50 data-list shimmer-container"
                            >
                                <div className="flex justify-between">
                                    <IonSkeletonText animated style={{ width: '50%' }} />
                                    <IonSkeletonText animated style={{ width: '20%' }} />
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    };


    return (
        <IonPage>
            <Header enableBackButton='/model-home' title={t('Service.ServiceCreate.createModel')} />
            <IonContent>
                <IonLoading
                    cssClass="my-custom-class"
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={t('Utils.pleasewait')}
                    duration={5000}
                />
                <form className="ion-margin-top" style={{ textAlign: 'center' }} onSubmit={(e) => Submit(e)}>
                    {data.map((form_field, index) => {
                        if (form_field.title) {
                            return (
                                <p className="form-title" key={`${form_field.title}-${index}`}>
                                    {t(`Model937.` + form_field.title)}
                                </p>
                            );
                        } else if (form_field.type === 'text' || form_field.type === 'number') {
                            return (
                                <div className="input-container" key={`${form_field.key}-${index}`}>
                                    <label className="label">
                                        {t(`Model937.` + form_field.key)}
                                    </label>
                                    <input
                                        type={form_field.type}
                                        className="basic-input"
                                        id={form_field.key}
                                        placeholder={t(`Model937.` + form_field.key)}
                                        onChange={handleChange}
                                        value={form_field.value as string | number | readonly string[] | undefined}
                                        required={form_field.required}
                                    />
                                </div>
                            );
                        } else if (form_field.type === 'textarea') {
                            return (
                                <div className="input-container" key={`${form_field.key}-${index}`}>
                                    <label className="label">
                                        {t(`Model937.` + form_field.key)}
                                    </label>
                                    <textarea
                                        className="textarea"
                                        id={form_field.key}
                                        placeholder={t(`Model937.` + form_field.key)}
                                        onChange={handleChange}
                                        value={form_field.value as string | number | readonly string[] | undefined}
                                    />
                                </div>
                            );
                        }
                        else if (form_field.key === 'asset') {
                            return (
                                <div className="input-container" key={`${form_field.key}-${index}`}>
                                    <label className="label">
                                        {t(`Model937.` + form_field.key)}
                                        {form_field.required ? '*' : null}
                                    </label>
                                    <p onClick={() => setSetAssetModalToggle(!assetModalToggle)} className='basic-input' style={{ marginTop: '0px' }}>{form_field.value || <span style={{ color: 'gray' }}>Select Asset</span>}</p>
                                </div>)
                        }
                        else if (form_field.key === 'project') {
                            return (
                                <div className="input-container" key={`${form_field.key}-${index}`}>
                                    <label className="label">
                                        {t(`Model937.` + form_field.key)}
                                    </label>
                                    <p onClick={() => setProjectModal(!projectModal)} className='basic-input' style={{ marginTop: '0px' }}>{form_field.value || <span style={{ color: 'gray' }}>Select Project</span>}</p>
                                </div>)
                        }
                        else if (form_field.type === 'select') {
                            return (
                                <div className="input-container" key={`${form_field.key}-${index}`}>
                                    <label className="label">
                                        {t(`Model937.` + form_field.key)}
                                    </label>
                                    <Select
                                        className="custom-select"
                                        id={`${form_field.key}_choice`}
                                        name={form_field.key}
                                        onChange={handleSelectChange}
                                        value={form_field.value}
                                    >
                                        {form_field.options &&
                                            form_field.options.map(function (opt, optIndex) {
                                                return (
                                                    <MenuItem
                                                        value={`${opt}`}
                                                        key={`${form_field.key}-${optIndex}`}
                                                        id={`${opt}_item_${optIndex}`}
                                                    >
                                                        <section style={{ display: 'flex', height: '1rem' }}>
                                                            {/* <img src={Man} alt="Users" style={{ width: '15px' }} /> */}
                                                            <p
                                                                style={{
                                                                    color: 'rgba(31, 87, 148, 1)',
                                                                    marginLeft: '0.2rem',
                                                                    fontSize: '12px',
                                                                    marginTop: '0px',
                                                                }}
                                                            >
                                                                {t(opt)}
                                                            </p>
                                                        </section>
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </div>
                            );
                        } else if (form_field.type === 'date') {
                            return (
                                <div className="input-container" key={`${form_field.key}-${index}`}>
                                    <label className="label">
                                        {t(`Model937.` + form_field.key)}
                                    </label>
                                    <input
                                        className="date"
                                        id={form_field.key}
                                        placeholder={t(`Model937.` + form_field.key)}
                                        onChange={handleChange}
                                        value={form_field.value as string}
                                        type="date"
                                    />
                                </div>
                            );
                        } else if (form_field.type === 'signature') {
                            return (
                                <div className="input-container" key={`${form_field.key}-${index}`}>
                                    <label className="label">
                                        {t(`Model937.` + form_field.key)}
                                    </label>
                                    <div className='flex justify-center'>
                                        <IonButton color="medium" className='center' onClick={() => uploadSignature()}>
                                            <IonLabel>{t(`Model937.uploadSignature`)}</IonLabel>
                                            <IonIcon slot="end" src={fingerPrint} className='pointer' style={{ width: '25px' }} />
                                        </IonButton>
                                    </div>
                                </div>)
                        } else {
                            return '';
                        }
                    })}
                    <button className="submit-button" type="submit">
                        {t(`Service.ServiceCreate.createModel`)}
                    </button>
                    <div style={{ marginBottom: '1rem' }}></div>
                </form>

                <IonModal isOpen={signaturePadShow}>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Signature Pad</IonTitle>
                            <IonButtons slot="end">
                                <IonButton onClick={() => setSignaturePadShow(false)}>Close</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <div style={{ marginTop: '2rem', border: '1px solid lightgray', margin: '5px', borderRadius: '5px' }}>
                        <SignatureCanvas penColor='#1d508d'
                            backgroundColor='rgba(255, 255, 255, 0)'
                            ref={(ref: any) => { setCanvas(ref) }}
                            dotSize={5}
                            canvasProps={{ width: 500, height: 400, className: 'sigCanvas' }} />
                    </div>
                    <button className="submit-button" onClick={() => getSignature()}>
                        {t(`Utils.addSignautre`)}
                    </button>
                </IonModal>

                <IonModal
                    isOpen={assetModalToggle}
                    onDidDismiss={() => setSetAssetModalToggle(false)}
                ><IonHeader>
                        <IonToolbar>
                            <IonTitle>Assets</IonTitle>
                            <IonButtons slot="end">
                                <IonButton onClick={() => setSetAssetModalToggle(false)}>Close</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <IonSearchbar
                            className="data-list"
                            value={searchText}
                            // @ts-ignore
                            onIonChange={(e) => handleSearch(e.detail.value!)}
                        ></IonSearchbar>
                        {shimmer && <Shimmer />}
                        <IonList>
                            {assets && assets.length ? assets.map((asset, index) => (
                                <IonItem
                                    key={index}
                                    onClick={() => {
                                        setSetAssetModalToggle(false);
                                        setAssetInValue(asset)
                                    }}
                                >
                                    <IonLabel>{asset}</IonLabel>
                                    <IonCheckbox
                                        slot="end"
                                        value={asset}
                                        checked={asset === selectedAsset} />
                                </IonItem>
                            )) :
                                <div style={{ margin: 'auto', display: 'block' }}>
                                    <p className='text-center' style={{ color: 'gray' }}>No Asset found</p>
                                </div>}
                        </IonList>

                        <IonInfiniteScroll onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
                            <IonInfiniteScrollContent
                                loadingSpinner="bubbles"
                                loadingText="Loading more data..."
                            ></IonInfiniteScrollContent>
                        </IonInfiniteScroll>
                    </IonContent>
                </IonModal>
                <IonModal
                    isOpen={projectModal}
                    onDidDismiss={() => setProjectModal(false)}
                ><IonHeader>
                        <IonToolbar>
                            <IonTitle>Projects</IonTitle>
                            <IonButtons slot="end">
                                <IonButton onClick={() => setProjectModal(false)}>Close</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        {shimmer && <Shimmer />}
                        <IonList>
                            {projects && projects.length ? projects.map((project: any, index: number) => (
                                <IonItem
                                    key={index}
                                    onClick={() => {
                                        setProjectModal(false);
                                        setProjectInValue(project)
                                    }}
                                >
                                    <IonLabel>{project}</IonLabel>
                                    <IonCheckbox
                                        slot="end"
                                        value={project}
                                        checked={project === selectedProject} />
                                </IonItem>
                            )) :
                                <div style={{ margin: 'auto', display: 'block' }}>
                                    <p className='text-center' style={{ color: 'gray' }}>No Asset found</p>
                                </div>}
                        </IonList>
                        {infiniteScroll ?
                            <IonInfiniteScroll onIonInfinite={(e: CustomEvent<void>) => searchNextProject(e)}>
                                <IonInfiniteScrollContent
                                    loadingSpinner="bubbles"
                                    loadingText="Loading more data..."
                                ></IonInfiniteScrollContent>
                            </IonInfiniteScroll> : ''}
                    </IonContent>
                </IonModal>
            </IonContent>
        </IonPage>
    );
};

export default ModelCreate;
