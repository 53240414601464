export const createServiceFields = [
  {
    title: 'Maintenance.CreateM.PrDet',
  },
  {
    label: 'Service.ServiceCreate.asset',
    field: 'asset',
    required: true,
    type: 'select',
    options: [],
    value: '',
    requiredText: 'Assets are required',
    disabled: false,
  },
  {
    label: 'Service.ServiceCreate.date',
    field: 'date',
    required: true,
    type: 'date',
    value: '',
    requiredText: 'Please select date',
  },
  {
    label: 'Service.ServiceCreate.repair_type',
    field: 'repair_type',
    required: false,
    type: 'select',
    options: ['New', 'Upgrade', 'Fix The Existing Component', 'Manufacturing Defect'],
    value: '',
    requiredText: 'Please select Repair Type',
  },
  {
    label: 'Service.ServiceCreate.target_date',
    field: 'target_date',
    required: false,
    type: 'date',
    value: '',
    requiredText: 'Please select date',
  },
  {
    title: 'Service.request',
  },
  {
    label: 'Service.ServiceCreate.name',
    field: 'name1',
    required: true,
    type: 'text',
    value: '',
    requiredText: 'Name is required',
    disabled: false,
  },
  {
    label: 'Service.ServiceCreate.type_of_employee',
    field: 'type_of_employee',
    required: true,
    type: 'select',
    options: ['Internal', 'External', 'Contractual'],
    value: '',
    requiredText: 'Please select Employee Type',
  },
  {
    label: 'Service.ServiceCreate.datetime_visit',
    field: 'datetime_visit',
    required: false,
    type: 'datetime-local',
    value: '',
    requiredText: 'Please select Date Visit',
  },
  {
    label: 'Service.ServiceCreate.component_type',
    field: 'component_type',
    required: false,
    type: 'select',
    options: [],
    value: '',
    requiredText: 'Please select Component Type',
    info: 'compInfo'
  },
  {
    label: 'Service.ServiceCreate.component_name',
    field: 'component_name',
    required: false,
    type: 'select',
    options: [],
    value: '',
    requiredText: 'Please select Component Name',
    info: 'compInfo'
  },
  {
    label: 'Service.ServiceCreate.warranty',
    field: 'warranty',
    required: false,
    type: 'text',
    value: '',
  },
  {
    label: 'Service.ServiceCreate.fault_type',
    field: 'fault_type',
    required: true,
    type: 'select',
    options: [],
    value: '',
    requiredText: 'Please select Fault Type',
  },
  {
    label: 'Service.ServiceCreate.amount',
    field: 'amount',
    required: false,
    type: 'number',
    value: ''
  },
  {
    label: 'Service.ServiceCreate.status',
    field: 'status',
    required: true,
    type: 'select',
    options: ['New', 'In Progress', 'Faulty', 'Completed'],
    value: '',
    requiredText: 'Please select Status',
  },
  {
    label: 'Service.ServiceCreate.priority',
    field: 'priority',
    required: true,
    type: 'select',
    options: ['Critical', 'High', 'Medium', 'Low'],
    value: '',
    requiredText: 'Please select Priority',
  },
  {
    title: 'Service.titleProject',
  },
  {
    label: 'Service.ServiceCreate.project',
    field: 'project',
    required: true,
    type: 'select',
    options: [],
    value: '',
    requiredText: 'Please select Project name',
    disabled: false,
    translate: false,
  },
  {
    label: 'Service.ServiceCreate.replaced_by',
    placeholder: 'Service.ServiceCreate.replaced_by_placeholder',
    field: 'replaced_by',
    required: false,
    type: 'barcode',
    value: '',
  },
  {
    label: 'Service.ServiceCreate.remarks',
    field: 'remarks',
    required: true,
    type: 'text',
    value: '',
  },
  {
    label: 'Service.ServiceCreate.upload_file',
    field: 'upload_attachment',
    required: false,
    type: 'file',
    openModal: false,
    value: '',
    src: '',
    format: '',
  },
];

export const updateServiceRequest = [
  {
    label: 'Service.ServiceCreate.status',
    field: 'status',
    required: true,
    type: 'select',
    options: ['New', 'In Progress', 'Faulty', 'Completed'],
    value: '',
    requiredText: 'Please select Status',
  },
  {
    label: 'Service.ServiceCreate.remarks',
    field: 'remarks',
    required: true,
    type: 'textarea',
    value: '',
  },
]