export const createMaintenanceFields = [
  {
    title: 'Maintenance.CreateM.PrDet',
  },
  {
    label: 'Maintenance.CreateM.asset',
    field: 'asset',
    required: true,
    type: 'select',
    options: [],
    value: '',
    requiredText: 'Asset name required',
    disabled: false,
  },
  {
    label: 'Maintenance.CreateM.maintenance_type',
    field: 'maintenance_type',
    required: true,
    type: 'select',
    options: ['Routine', 'Non-Routine'],
    value: '',
    requiredText: 'Please select Maintainance Type',
  },
  {
    label: 'Maintenance.CreateM.period',
    field: 'period',
    required: true,
    type: 'select',
    options: ['Daily', 'Weekly', 'Monthly', 'Yearly'],
    value: '',
    requiredText: 'Please select the Time Period',
    hide:true
  },
  {
    label: 'Maintenance.CreateM.target_date',
    field: 'target_date',
    required: true,
    type: 'date',
    value: '',
    requiredText: 'Please select date',
  },
  // {
  //   title: 'Maintenance.Detail.AC',
  // },
  // {
  //   label: 'Maintenance.CreateM.checklist1',
  //   field: 'checklist1',
  //   required: false,
  //   type: 'select',
  //   options: [],
  //   value: '',
  //   name: 'checklist',
  //   btnDisabled: true,
  //   requiredText: 'Please select Checklist 1',
  // },
  // {
  //   label: 'Maintenance.CreateM.checklist2',
  //   field: 'checklist2',
  //   required: false,
  //   type: 'select',
  //   options: [],
  //   value: '',
  //   name: 'checklist',
  //   btnDisabled: true,
  //   requiredText: 'Please select Checklist 2',
  // },
  // {
  //   label: 'Maintenance.CreateM.checklist3',
  //   field: 'checklist3',
  //   required: false,
  //   type: 'select',
  //   options: [],
  //   value: '',
  //   name: 'checklist',
  //   btnDisabled: true,
  //   requiredText: 'Please select Checklist 3',
  // },
  // {
  //   label: ' Maintenance.CreateM.maintenance_checklist_details1',
  //   field: 'maintenance_checklist_details1',
  //   value: [],
  //   type: 'hide',
  //   name: 'checklist1'
  // },
  // {
  //   label: ' Maintenance.CreateM.maintenance_checklist_details2',
  //   field: 'maintenance_checklist_details2',
  //   value: [],
  //   type: 'hide',
  //   name: 'checklist2'
  // },
  // {
  //   label: 'maintenance_checklist_details3',
  //   field: 'maintenance_checklist_details3',
  //   value: [],
  //   type: 'hide',
  //   name: 'checklist3'
  // },
  {
    title: 'Maintenance.Detail.Det',
  },
  {
    label: 'Maintenance.CreateM.name1',
    field: 'name1',
    placeholder: 'Name',
    required: true,
    type: 'text',
    value: '',
    requiredText: 'Name is required',
  },
  {
    label: 'Maintenance.CreateM.type_of_employee',
    field: 'type_of_employee',
    required: false,
    type: 'select',
    options: ['Internal', 'External', 'Contractual'],
    value: '',
    requiredText: 'Pleas select the employee type',
  },
  {
    label: 'Maintenance.CreateM.visit_datetime',
    field: 'visit_datetime',
    required: true,
    type: 'datetime-local',
    value: '',
    requiredText: 'Please select Visit DateTime',
  },
  {
    label: 'Maintenance.CreateM.component_type',
    field: 'component_type',
    required: false,
    type: 'select',
    options: [],
    value: '',
    requiredText: 'Please select Component Type',
    info: 'compInfo'
  },
  {
    label: 'Maintenance.CreateM.component_name',
    field: 'component_name',
    required: false,
    type: 'select',
    options: [],
    value: '',
    requiredText: 'Please select Component Name',
    info: 'compInfo'
  },
  {
    label: 'Maintenance.CreateM.functional_status',
    field: 'functional_status',
    required: true,
    type: 'select',
    options: ['Working', 'Not Working', 'Need Improvement', 'Not Applicable'],
    value: '',
    requiredText: 'Please select Functional Status',
  },
  {
    label: 'Maintenance.CreateM.status',
    field: 'status',
    required: true,
    type: 'select',
    options: ['New','In Progress','Completed', 'Good','Need Repair'],
    value: '',
    requiredText: 'Please select Status',
  },
  {
    label: 'Maintenance.CreateM.priority',
    field: 'priority',
    required: true,
    type: 'select',
    options: ['Critical', 'Medium', 'Low'],
    value: '',
    requiredText: 'Please select Priority',
  },
  {
    label: 'Maintenance.CreateM.temparature',
    field: 'temparature',
    required: false,
    type: 'text',
    value: '',
  },
  {
    label: 'Maintenance.CreateM.weather',
    field: 'weather',
    required: false,
    type: 'text',
    value: '',
  },
  {
    title: 'Service.titleProject',
  },
  {
    label: 'Maintenance.CreateM.project',
    field: 'project',
    required: true,
    type: 'select',
    options: [],
    value: '',
    requiredText: 'Please select Project name',
    disabled: false,
    translate: false,
  },
  {
    label: 'Maintenance.CreateM.remarks',
    field: 'remarks',
    required: false,
    type: 'text',
    value: '',
    requiredText: 'Remarks required',
  },
  {
    label: 'Maintenance.CreateM.upload',
    field: 'upload_attachment',
    required: false,
    type: 'file',
    value: '',
    disabled: false,
    src: '',
    format: '',
  },
  {
    label: 'Maintenance.CreateM.upload',
    field: 'upload_attachment_2',
    required: false,
    type: 'file',
    value: '',
    disabled: false,
    src: '',
    format: '',
  },{
    label: 'Maintenance.CreateM.upload',
    field: 'upload_attachment_3',
    required: false,
    type: 'file',
    value: '',
    disabled: false,
    src: '',
    format: '',
  }
];

export const createCheckList = [
  {
    title: 'Check List Details',
  },
  {
    label: 'Maintenance.CreateM.activity',
    field: 'activity',
    required: false,
    type: 'text',
    value: '',
    requiredText: 'Ativity name is required',
  },
  {
    label: 'Maintenance.CreateM.yes_or_no',
    field: 'yes_or_no',
    required: false,
    type: 'select',
    options: ['Yes', 'No'],
    value: '',
    requiredText: 'Please select Check Status',
  },
  {
    label: 'Maintenance.CreateM.remarks',
    field: 'remarks',
    required: false,
    type: 'text',
    value: '',
  },
];
