/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonModal,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import { createSurveyFields } from '../../models/createSurveyFields';
import { cleanTextIfAny, constant, createFormJsonData, surveyPostData } from '../../utils';
import { useHistory, useLocation } from 'react-router';
import { close, createOutline, image } from 'ionicons/icons';
import { MenuItem, Select } from '@material-ui/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

const ViewSurvey: React.FC = () => {
  const location = useLocation();
  const [present] = useIonToast();
  const history = useHistory();

  const [data, setdata] = useState({
    si_survey_code: '',
  });
  // Edited data stored inside this
  const [newData, setNewData] = useState(createSurveyFields);
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<{ value: unknown; id: any }>) => {
    var formentry: any = [...newData];
    const f_index = formentry.findIndex((v: any) => v.field === event.target.id);
    //@ts-ignore
    var change_form_object = formentry[f_index];
    //@ts-ignore
    if (f_index === 1) {
      //@ts-ignore
      if (event.target.value.length === 8 && event.nativeEvent.inputType === 'insertText') {
        //@ts-ignore
        event.target.value = event.target.value + '-';
      }
    }
    // @ts-ignore
    if (event.target.value.length < 14) {
      change_form_object.value = event.target.value;
    }
    formentry[f_index] = change_form_object;
    setNewData(formentry);
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    var formentry: any = [...newData];
    // @ts-ignore
    const select = event.target.name.replaceAll('-', '_');
    const f_index = formentry.findIndex((v: any) => v.field === select);
    formentry[f_index].value = event.target.value;
    setNewData(formentry);
  };

  const handleMultiSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    var formentry: any = [...newData];
    // @ts-ignore
    const select = event.target.name;
    const f_index = formentry.findIndex((v: any) => v.field === select);
    // @ts-ignore
    formentry[f_index].value = event.detail.value;
    setNewData(formentry);
  };

  const handleChangeCheck = (event: React.ChangeEvent<{ id: any; checked: any }>) => {
    var formentry: any = [...newData];
    const f_index = formentry.findIndex((v: any) => v.field === event.target.id);
    var change_form_object = formentry[f_index];
    if (event.target.checked) {
      change_form_object.value = 1;
    } else {
      change_form_object.value = 0;
    }
    formentry[f_index] = change_form_object;
    setNewData(formentry);
  };

  const takePicture = async (imageElementIndex: number) => {
    try {
      const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
      });

      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      var imageUrl = image.webPath;

      // Can be set to the src of an image now
      var formentry: any = [...newData];
      formentry[imageElementIndex].src = imageUrl;
      formentry[imageElementIndex].format = image.format;
      setNewData(formentry);
    } catch (e) {
      //@ts-ignore
      console.log('Error: ', e);
      // @ts-ignore
      if (e.toString().includes('cancelled')) {
        //@ts-ignore
        present({
          message: t('Utils.cameraCancel'),
          color: 'danger',
          duration: 2000,
        });
      }
      //@ts-ignore
      else if (e.toString().includes('denied') || e.includes('access')) {
        //@ts-ignore
        present({
          message: t('Utils.cameraErrorPermission'),
          color: 'danger',
          duration: 2000,
        });
      } else {
        //@ts-ignore
        present({
          message: e,
          color: 'danger',
          duration: 2000,
        });
      }
    }
  };

  /* Remove Image by cross click */
  const emptyImg = (index: number) => {
    var tempData = [...newData];
    if (tempData[index].src) {
      tempData[index].src = '';
    } else if (tempData[index].value) {
      tempData[index].value = '';
    }
    setNewData(tempData);
  };

  const getSurveyById = () => {
    var res: any = history.location.state;
    axios({
      method: 'get',
      url: `/resource/Survey/${res}`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    })
      .then((res) => {
        console.log(res);
        const temp = res.data.data;
        var Data = { ...temp };
        setdata(Data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
      });
  };

  useEffect(() => {
    if (history?.location?.state)
      getSurveyById();
  }, [history.location.state]);


  const { t, i18n } = useTranslation();

  const getValueFromData = (key: string) => {
    // @ts-ignore
    return data[key] || '';
  };

  const edit = () => {
    /* Map Survey Entry to Fields */
    // eslint-disable-next-line array-callback-return
    newData.map((item: any, key: any) => {
      if (item.field) {
        //@ts-ignore
        item.value = data[item.field];
      }
    });
    setShowModal(true);
  };

  const SubmitEdit = async (e: any) => {
    e.preventDefault();
    uploadFiles();
  };

  const uploadFiles = async () => {
    setShowLoading(true);
    const formData = new FormData();
    for (const item in newData) {
      if (newData[item].type === 'file') {
        try {
          // @ts-ignore
          if (newData[item].src?.length > 0) {
            // find survey unique id
            const f_index = newData.findIndex((v: any) => v.field === constant.SURVEY_UNIQUE_ID);
            //@ts-ignore
            const response = await fetch(newData[item].src?.toString());
            const blob = await response.blob();
            //@ts-ignore
            formData.append(
              'file',
              new File([blob], `${newData[f_index].value}-${new Date().getTime()}.${newData[item].format}`, {
                type: `image/${newData[item].format}`,
              })
            );

            formData.append('is_private', '0');
            formData.append('folder', 'Home');
            formData.append('doctype', 'Survey');
            var config: any = {
              method: 'post',
              url: '/method/upload_file',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
              },
              data: formData,
            };
            const res = await axios(config);
            newData[item].value = res.data.message.file_url;
            formData.delete('file');
            setNewData(newData);
          }
        } catch (e) {
          newData[item].value = '';
        }
      }
    }
    hitCreateSurvey(newData);
  };

  const hitCreateSurvey = async (body: any) => {
    const tempData = surveyPostData(body);
    const dataAllSet = createFormJsonData(tempData);
    //@ts-ignore
    const url = `/resource/Survey/${data['name']}`;
    var config: any = {
      method: 'put',
      url,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: JSON.stringify(dataAllSet),
    };
    try {
      const res = await axios(config);
      console.log(res);
      setShowModal(false);
      getSurveyById();
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <IonPage>
      <Header enableBackButton={location.search ? '/' + location.search.replace("?back=", "") : '/show-survey'} title={t('Survey.detail')} />
      <IonContent>
        <div className="preview-header">
          <p className="preview-title">{data['si_survey_code']} </p>
          <div className="flex">
            {
              //@ts-ignore
              data['owner'] === localStorage.getItem('email') ? (
                <IonIcon
                  className="action-icon"
                  onClick={() => {
                    edit();
                  }}
                  src={createOutline}
                />
              ) : (
                <IonIcon style={{ opacity: '.2' }} className="action-icon" src={createOutline} />
              )
            }
          </div>
        </div>

        <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={t('Utils.pleasewait')}
            duration={showModal ? 1000 : 5000}
          />
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>
                {t(`Assets.CreateA.edit`)} {data['si_survey_code']}
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="line-button"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <IonIcon name="close" slot="icon-only"></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <form onSubmit={(e) => SubmitEdit(e)}>
              {createSurveyFields.map((form_field, index) => {
                if (form_field.title) {
                  return (
                    <p key={index} className="form-title">
                      {t(form_field.title)}
                    </p>
                  );
                }
                if (form_field.subtitle) {
                  return (
                    <p className="form-subtitle" key={`${form_field['title']}-${index}`}>
                      {t(form_field['subtitle'])}
                    </p>
                  );
                } else if (form_field.type === 'text') {
                  return (
                    <div key={index} className="input-container">
                      <label className="label">
                        {t(form_field['label'])}
                        {form_field.required ? '*' : null}
                      </label>
                      <input
                        required={form_field.required}
                        className="basic-input"
                        key={`${form_field.field}-${index}`}
                        id={form_field.field}
                        placeholder={t(`Assets.CreateA.${form_field.field}`)}
                        onChange={handleChange}
                        //@ts-ignore
                        value={form_field.value}
                      />
                    </div>
                  );
                } else if (form_field.type === 'textarea') {
                  return (
                    <div key={index} className="input-container">
                      <label className="label">
                        {t(form_field['label'])}
                        {form_field.required ? '*' : null}
                      </label>
                      <textarea
                        className="textarea"
                        required={form_field.required}
                        key={`${form_field.field}-${index}`}
                        id={form_field.field}
                        placeholder={t(`Assets.CreateA.${form_field.field}`)}
                        onChange={handleChange}
                        //@ts-ignore
                        value={form_field.value}
                      />
                    </div>
                  );
                } else if (form_field.type === 'select' && !form_field.multiple) {
                  return (
                    <div key={index} className="input-container">
                      <label className="label">
                        {t(form_field['label'])}
                        {form_field.required ? '*' : null}
                      </label>
                      <Select
                        className="custom-select"
                        required={form_field.required}
                        id={`${form_field.field}_choice`}
                        name={form_field.field}
                        onChange={handleSelectChange}
                        value={form_field.value}
                        disabled={form_field.disabled || false}
                        key={`${form_field.field}-${index}`}
                      >
                        {form_field.options &&
                          form_field.options.map(function (opt, indexSelect) {
                            return (
                              <MenuItem
                                value={`${opt}`}
                                key={`${form_field.field}-${indexSelect}`}
                                id={`${opt}_item_${indexSelect}`}
                              >
                                <section
                                  key={`${form_field.field}-${indexSelect}`}
                                  style={{ display: 'flex', height: '1rem' }}
                                >
                                  <p
                                    style={{
                                      color: 'rgba(31, 87, 148, 1)',
                                      marginLeft: '0.2rem',
                                      fontSize: '12px',
                                      marginTop: '0px',
                                    }}
                                  >
                                    {
                                      //@ts-ignore
                                      form_field.translate ? t(`Survey.${opt}`) : opt
                                    }
                                  </p>
                                </section>
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  );
                } else if (form_field.type === 'select' && form_field.multiple === true) {
                  return (
                    <div className="input-container" key={`${form_field['field']}-${index}`}>
                      <label className="label-survey">
                        {t(form_field['label'])}
                        {form_field.required ? '*' : null}
                      </label>
                      <IonSelect
                        style={{ padding: '11px' }}
                        className="custom-select"
                        value={form_field.value}
                        name={form_field['field']}
                        okText={t('Utils.ok')}
                        cancelText={t('Utils.cancel')}
                        multiple={true}
                        // @ts-ignore
                        onIonChange={handleMultiSelectChange}
                      >
                        {form_field['options'] &&
                          // @ts-ignore
                          form_field['options'].map(function (opt: any, indexSelect: any) {
                            return (
                              <IonSelectOption
                                key={`${opt}-${indexSelect}`}
                                id={`${opt}_item_${indexSelect}`}
                                value={`${opt}`}
                              >
                                {t(`Survey.${opt}`)}
                              </IonSelectOption>
                            );
                          })}
                      </IonSelect>
                    </div>
                  );
                } else if (form_field.type === 'file') {
                  return (
                    <div style={{ marginBottom: '1rem' }} key={`${form_field['field']}-${index}`}>
                      {form_field.showlabel ? (
                        <label className="label-survey">{t(`Survey.${form_field['field']}`)}</label>
                      ) : (
                        ''
                      )}
                      <IonButton
                        style={{ display: 'block', marginTop: '1rem', margin: 'auto', width: '40%', color: 'black' }}
                        size="small"
                        color="light"
                        onClick={() => takePicture(index)}
                      >
                        {form_field['src'] ? t('Utils.change_image') : t('Utils.image')}
                      </IonButton>
                      <div className="flex justify-center items-center">
                        {form_field.value ? (
                          <img
                            src={`${localStorage.getItem('baseUrl')?.replace('/api', '')}${form_field.value}`}
                            alt=""
                            style={{
                              width: '40%',
                              margin: 'auto',
                              display: 'block',
                              border: '1px solid lightgray',
                              borderRadius: '12px',
                            }}
                          />
                        ) : (
                          ''
                        )}
                        {form_field['src'] ? <img className="preview-survey-img" src={form_field['src']} alt="" /> : ''}
                        {form_field.value || form_field['src'] ? (
                          <IonIcon className="close-icon" onClick={() => emptyImg(index)} src={close} />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  );
                } else if (form_field.type === 'date') {
                  return (
                    <div key={index} className="input-container">
                      <label className="label">
                        {t(form_field['label'])}
                        {form_field.required ? '*' : null}
                      </label>
                      <input
                        required={form_field.required}
                        className="date"
                        key={`${form_field.field}-${index}`}
                        id={form_field.field}
                        placeholder={t(`Assets.CreateA.${form_field.field}`)}
                        onChange={handleChange}
                        //@ts-ignore
                        value={form_field.value}
                        type="date"
                      />
                    </div>
                  );
                } else if (form_field.type === 'checkbox') {
                  return (
                    <div className="input-container" key={`${form_field['field']}-${index}`}>
                      <input
                        required={form_field['required']}
                        className="check-box"
                        id={form_field['field']}
                        placeholder={t(form_field['label'])}
                        onChange={handleChangeCheck}
                        checked={form_field.value.toString() === '1' ? true : false}
                        value={form_field.value.toString()}
                        type="checkbox"
                      />
                      <label className="checkbox">{t(form_field['label'])}</label>&nbsp;&nbsp;
                    </div>
                  );
                } else {
                  return '';
                }
              })}
              <button className="submit-button" type="submit">
                {t(`Assets.CreateA.save`)}
              </button>
            </form>
          </IonContent>
        </IonModal>
        {createSurveyFields.map((item: any, index) => {
          return (
            <div className="data-list" key={`survey-item-${index}`}>
              {item.title ? (
                <p
                  style={{
                    marginLeft: '10px',
                    color: '#1d508d',
                    textDecoration: 'underline',
                  }}
                >
                  <b>{t(item.title)}</b>
                </p>
              ) : (
                ''
              )}
              {item.subtitle ? (
                <p style={{ marginLeft: '10px', color: '#929bab', fontSize: '14px' }}>{t(item.subtitle)}</p>
              ) : (
                ''
              )}
              {item.field ? (
                <div>
                  {item.type === 'file' &&
                    getValueFromData(
                      // @ts-ignore
                      item.field
                    ) && (
                      <div className="flex">
                        <IonIcon style={{ marginLeft: '0.5rem', color: 'darkblue' }} src={image} />
                        <img
                          src={`${localStorage.getItem('baseUrl')?.replace('/api', '')}${getValueFromData(
                            // @ts-ignore
                            item.field
                          )}`}
                          alt=""
                          style={{
                            width: '40%',
                            margin: 'auto',
                            display: 'block',
                            border: '1px solid lightgray',
                            borderRadius: '12px',
                          }}
                        />
                      </div>
                    )}
                  {item.type !== 'file' && (
                    <div className="preview-container">
                      <p className="preview-key">{t(`${item.label}`)}</p>
                      <p className="preview-value">
                        {Array.isArray(
                          // @ts-ignore
                          data[item.field]
                        ) &&
                          // @ts-ignore
                          data[item.field].length > 0
                          ? // @ts-ignore
                          data[item.field].map((item) => t(`Survey.${item.option}`)).join(', ')
                          : // @ts-ignore
                          i18n.exists(`Survey.${cleanTextIfAny(data[item.field], item.type)}`)
                            ? // @ts-ignore
                            t(`Survey.${cleanTextIfAny(data[item.field], item.type)}`)
                            : // @ts-ignore
                            cleanTextIfAny(data[item.field]) || '-'}
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          );
        })}
      </IonContent>
    </IonPage>
  );
};

export default ViewSurvey;
