import React, { useContext, useEffect, useState } from 'react';
import {
  IonMenu,
  IonContent,
  IonList,
  IonRow,
  IonItem,
  IonMenuToggle,
  useIonAlert,
  IonActionSheet,
  IonIcon,
} from '@ionic/react';
import Homeicon from '../assets/homeicon.svg';
import calender from '../assets/calender.svg';
import model937 from '../assets/model937.svg';
import bulb from '../assets/icons/bulb.svg';
import Lang from '../assets/icons/translation.png';
import toolkit from '../assets/toolkit.svg';
import LogOut from '../assets/LogoutWhite.svg';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../providers/AppContext';
import map from '../assets/icons/map.svg';
import packageJson from '../../package.json';
import { Device } from '@capacitor/device';
import { prism } from 'ionicons/icons';
// import { AppUpdate, AppUpdateAvailability } from '@robingenz/capacitor-app-update';

const item = {
  color: '#F5F7FA',
  marginTop: '1.3rem',
  marginLeft: '2rem',
  fontSize: '12px',
};

const menuItem = {
  width: '70vw',
  height: '4rem',
  borderBottom: '1px solid #F5F7FA',
  margin: 'auto',
};

const Menu: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [present] = useIonAlert();
  const { dispatch, state } = useContext(AppContext);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [version, setVersion] = useState('');

  const getDeviceInfo = async () => {
    const info = await Device.getInfo();
    if (info.platform === 'web') {
      setVersion(packageJson.version);
    } else if (info.platform === 'android') {
      // const currentV = await AppUpdate.getAppUpdateInfo();
      // console.log('Current Version', currentV.currentVersion);
      // const availableV = await AppUpdate.getAppUpdateInfo();
      // console.log('available Version:', availableV.availableVersion);
      // await AppUpdate.openAppStore();
      // const result = await AppUpdate.getAppUpdateInfo();
    }
  };

  useEffect(() => {
    getDeviceInfo();
  }, []);

  return (
    <>
      <IonMenu side="start" contentId="main" menuId="first">
        <IonContent id="content">
          <h1
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              textShadow: '1px 1px black',
              fontSize: '2rem',
              background: 'inherit',
              color: 'rgba(239, 241, 243, 1)',
              marginTop: '3rem',
              marginBottom: '0',
            }}
          >
            Hyprism
          </h1>

          <IonList style={{ background: 'inherit' }}>
            {state.permissions.home ? (
              <IonMenuToggle auto-hide="false">
                <IonItem lines="none" routerLink="/home" color="#1546A0" routerDirection="none">
                  <div style={menuItem}>
                    <IonRow>
                      <img src={Homeicon} alt="home" style={{ marginLeft: '1rem' }}></img>
                      <p style={item}>{t('Menu.Home')}</p>
                    </IonRow>
                  </div>
                </IonItem>
              </IonMenuToggle>
            ) : (
              <div></div>
            )}

            <IonMenuToggle auto-hide="false">
              <IonItem lines="none" routerLink="/project" color="#1546A0" routerDirection="none">
                <div style={menuItem}>
                  <IonRow>
                    <IonIcon icon={prism} style={{ marginLeft: '1rem', fontSize: '1.6rem', marginTop: '1rem', color: 'white' }}></IonIcon>
                    <p style={item}>{t('Menu.Project')}</p>
                  </IonRow>
                </div>
              </IonItem>
            </IonMenuToggle>

            {state.permissions.assets ? (
              <IonMenuToggle auto-hide="false">
                <IonItem lines="none" routerLink="/assets" color="#1546A0" routerDirection="none">
                  <div style={menuItem}>
                    <IonRow>
                      <img src={bulb} alt="calender" style={{ marginLeft: '0.7rem' }}></img>
                      <p style={item}>{t('Menu.AC')}</p>
                    </IonRow>
                  </div>
                </IonItem>
              </IonMenuToggle>
            ) : (
              <div></div>
            )}

            {state.permissions.service ? (
              <IonMenuToggle auto-hide="false">
                <IonItem lines="none" routerLink="/serviceHome" color="#1546A0" routerDirection="none">
                  <div style={menuItem}>
                    <IonRow>
                      <img src={toolkit} alt="toolkit" style={{ marginLeft: '1rem' }}></img>
                      <p style={item}>{t('Menu.Service')}</p>
                    </IonRow>
                  </div>
                </IonItem>
              </IonMenuToggle>
            ) : (
              <div></div>
            )}

            {state.permissions.maintenance ? (
              <IonMenuToggle auto-hide="false">
                <IonItem lines="none" routerLink="/maintenanceHome" color="#1546A0" routerDirection="none">
                  <div style={menuItem}>
                    <IonRow>
                      <img src={calender} alt="map" style={{ marginLeft: '1rem' }}></img>
                      <p style={item}>{t('Menu.Maintenance')}</p>
                    </IonRow>
                  </div>
                </IonItem>
              </IonMenuToggle>
            ) : (
              <div></div>
            )}

            {state.permissions.model ? (
              <IonMenuToggle auto-hide="false">
                <IonItem lines="none" routerLink="/model-home" color="#1546A0" routerDirection="none">
                  <div style={menuItem}>
                    <IonRow>
                      <img src={model937} alt="map" style={{ marginLeft: '12px' ,marginTop: '8px' }}></img>
                      <p style={item}>{t('Menu.Model937')}</p>
                    </IonRow>
                  </div>
                </IonItem>
              </IonMenuToggle>
            ) : null}

            {state.permissions.survey ? (
              <IonMenuToggle auto-hide="false">
                <IonItem lines="none" routerLink="/survey-home" color="#1546A0">
                  <div style={menuItem}>
                    <IonRow>
                      <img src={map} alt="home" style={{ marginLeft: '1rem' }}></img>
                      <p style={item}>{t('Survey.name')}</p>
                    </IonRow>
                  </div>
                </IonItem>
              </IonMenuToggle>
            ) : (
              <div></div>
            )}
            <IonMenuToggle auto-hide="false">
              <IonItem
                style={{ cursor: 'pointer' }}
                lines="none"
                onClick={() => setShowActionSheet(true)}
                color="#1546A0"
              >
                <div style={menuItem}>
                  <IonRow>
                    <img
                      src={Lang}
                      alt="change lang"
                      style={{ marginLeft: '1rem', width: '1.5rem', height: '1.5rem', marginTop: '1.2rem' }}
                    ></img>
                    <p style={item}>{t('Utils.change_lang')}</p>
                  </IonRow>
                </div>
              </IonItem>
            </IonMenuToggle>

            <IonMenuToggle
              auto-hide="false"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                var message = t('Utils.are_you_sure');
                present(message, [
                  {
                    text: t('Utils.no'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => { },
                  },
                  {
                    text: t('Utils.yes'),
                    cssClass: 'danger',
                    handler: () => {
                      dispatch({ type: 'logOut' });
                    },
                  },
                ]);
              }}
            >
              <IonItem lines="none" color="#1546A0">
                <div style={menuItem}>
                  <IonRow>
                    <img src={LogOut} alt="logout" style={{ marginLeft: '1.3rem' }}></img>
                    <p style={item}>{t('Header.Avatar.LogOut')}</p>
                  </IonRow>
                </div>
              </IonItem>
            </IonMenuToggle>
          </IonList>
          <p
            style={{
              marginTop: '3rem',
              opacity: '0.5',
              color: '#FFFFFF',
              fontSize: '12px',
              textAlign: 'center',
              lineHeight: '17px',
            }}
          >
            Hyprism &copy; {new Date().getFullYear()}
            <br />
            {t('Footer')}
          </p>
          <p
            style={{
              marginTop: '3rem',
              opacity: '0.5',
              color: '#FFFFFF',
              fontSize: '12px',
              textAlign: 'center',
              lineHeight: '17px',
            }}
          >
            {packageJson.version ? `v${packageJson.version}` : ''}
          </p>
        </IonContent>
      </IonMenu>
      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        buttons={[
          {
            text: t('Utils.en'),
            handler: () => {
              i18n.changeLanguage('en');
              localStorage.setItem('lang', 'en');
            },
          },
          {
            text: t('Utils.pt'),
            handler: () => {
              i18n.changeLanguage('pt');
              localStorage.setItem('lang', 'pt');
            },
          },
        ]}
      ></IonActionSheet>
    </>
  );
};

export default Menu;
