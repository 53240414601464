export const createTaskField = [
    {
        title: 'Project.info',
    },
    {
        field: 'project',
        required: true,
        type: 'select',
        options: [],
        value: '',
        requiredText: 'Project is required',
    },
    {
        field: 'subject',
        required: true,
        type: 'text',
        value: '',
        requiredText: 'Please fill subject',
    },
    {
        field: 'status',
        required: true,
        type: 'select',
        options: ["Open",
            "Working",
            "Pending Review",
            "Overdue",
            "Template",
            "Completed",
            "Cancelled"],
        value: '',
        requiredText: 'Please select Status',
        translate: false,
    },
    {
        field: 'type',
        required: false,
        type: 'select',
        options: [],
        value: '',
        requiredText: 'Please select type',
    },
    {
        field: 'amount',
        required: false,
        type: 'text',
        value: ''
    },
    {
        field: 'exp_start_date',
        required: false,
        type: 'date',
        value: ''
    },
    {
        field: 'exp_end_date',
        required: false,
        type: 'date',
        value: ''
    },
    {
        field: 'expected_time',
        required: false,
        type: 'text',
        value: ''
    },
    {
        field: 'progress',
        required: false,
        type: 'text',
        value: ''
    },
    {
        field: 'expected_time',
        required: false,
        type: 'datetime',
        value: ''
    },
    {
        field: 'description',
        required: false,
        type: 'textarea',
        value: ''
    },
    {
        field: 'task_assign_user',
        required: false,
        type: 'select',
        options: [],
        value: ''
    },
];
