/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import {
  IonContent,
  IonPage,
  IonItem,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent,
  IonCardTitle,
  IonIcon,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  useIonToast,
  IonLoading,
  IonSkeletonText,
  IonRow,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import Header from '../../components/Header';
import Clock from '../../assets/Clock.svg';
import CreateAsset from '../../assets/CreateAsset.svg';
import CreateComp from '../../assets/CreateComp.svg';
import ViewComp from '../../assets/ViewComp.svg';
import AssetButton from '../../components/ScanButton';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import { barcodeOutline, close, constructOutline } from 'ionicons/icons';
import { createAssetFields } from '../../models/createAssetFields';
import { MenuItem, Select } from '@material-ui/core';
import NoRecords from '../../components/NoRecords';
import { checkIfArraysAreSame, getAssetComponentByLabel, getStatusColor, isFormDataEmpty } from '../../utils';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';

const Assets: React.FC = () => {
  const { t } = useTranslation();
  const [present] = useIonToast();

  const [highlights, setHighlights] = useState([]);
  const [pname, setPname] = useState('');
  const [fabModal, setFabModal] = useState(false);
  const [loaderM, setLoaderM] = useState(false);
  const [loader, setLoader] = useState(false);

  const [_data, set_Data] = useState(createAssetFields);
  const [scanToggle, setScanToggle] = useState(false);

  const [primaryData, setPrimaryData] = useState({});

  const [update, setUpdate] = useState({
    status: '',
    asset_state: '',
    brand: '',
    model: '',
    year: '',
    comment: '',
    asset_component: [],
    attachment: {},
    barcode_id: '',
  });

  const handleUpdateStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    var formentry: any = { ...update };
    //@ts-ignore
    if (event.target.name in formentry) {
      //@ts-ignore
      formentry[event.target.name] = event.target.value;
    }
    setUpdate(formentry);
  };

  const handleMultiSelectChange = (event: string) => {
    var formentry: any = { ...update };
    formentry['asset_component'] = event
    setUpdate(formentry);
  };

  const handleChangeQuickUpdate = (event: React.ChangeEvent<{ value: unknown; id: any }>) => {
    var formentry: any = { ...update };
    if (event.target.id in formentry) {
      formentry[event.target.id] = event.target.value;
    }
    setUpdate(formentry);
  };

  const getData = () => {
    let url = `method/preflet_asset.api.get_assets_active_tasks?limit_page_length=5&limit_start=0`
    var config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    setLoader(true);
    axios(config)
      .then((res: any) => {
        setHighlights(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const modalFunc = async (param: any) => {
    setFabModal(true);
    var Data = [..._data];
    const acname = Data.findIndex((d) => d.field === 'asset_component');
    Data[acname].options = await getAssetComponentByLabel();
    set_Data(Data);

    var config: any = {
      method: 'get',
      url: `resource/Asset/${param}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };

    try {
      const statusData = await axios(config)
      var assetComponent: { asset_component: any; }[] = []
      statusData?.data?.data?.asset_component.map((item: any) => {
        assetComponent.push(item?.asset_component)
      })
      setPrimaryData(statusData?.data?.data);

      for (const x in highlights) {
        // @ts-ignore
        if (highlights[x].name === param) {
          var formentry: any = { ...update };
          formentry.status = statusData?.data?.data?.status
          formentry.asset_state = statusData?.data?.data?.asset_state
          formentry.brand = statusData?.data?.data?.brand
          formentry.model = statusData?.data?.data?.model
          formentry.year = statusData?.data?.data?.year
          formentry.comment = statusData?.data?.data?.comment
          formentry.attachment.value = statusData?.data?.data?.attachment
          formentry.barcode_id = statusData?.data?.data?.barcode_id
          formentry.asset_component = assetComponent
          setUpdate(formentry);
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const SubmitStatusUpdate = async (e: any) => {
    e.preventDefault();
    hitQSupdate();
  };

  const hitQSupdate = async () => {
    const formData = new FormData();
    const temp = { ...update }
    for (const item in temp) {
      //@ts-ignore
      if (item === 'attachment' && temp['attachment']?.src) {
        try {
          //@ts-ignore
          const response = await fetch(temp['attachment']?.src?.toString());
          const blob = await response.blob();
          formData.append(
            'attachment',
            // @ts-ignore
            new File([blob], `${pname}-attachment.${temp['attachment'].format}`, {
              // @ts-ignore
              type: `image/${temp['attachment'].format}`,
            })
          );
        } catch (e) {
          console.log(e)
        }
      } else if (item === 'asset_component') {
        if (temp[item].length > 0) {
          var assComp = [] as any;
          for (const element in temp['asset_component']) {
            assComp.push({
              asset_component: temp['asset_component'][element]
            })
          }
        }
        // @ts-ignore
        if (!checkIfArraysAreSame(primaryData[item], assComp))
          formData.append('asset_component', JSON.stringify(assComp));
      } // @ts-ignore
      else if (item === 'attachment' && (primaryData[item] !== temp[item].value)) {
        // @ts-ignore
        formData.append('attachment', temp[item].value);
        // @ts-ignore
      } else if (temp[item] !== '' && temp[item] !== null && temp[item] !== undefined && temp[item] !== 'undefined') {
        // @ts-ignore
        if ((primaryData[item] !== temp[item]) && item !== 'attachment')
          // @ts-ignore
          formData.append(item, temp[item]);
      }
    }

    var config: any = {
      method: 'put',
      url: `method/preflet_asset.api.update_asset/${pname}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: formData,
    };
    if (!isFormDataEmpty(formData)) {
      // Update status api
      try {
        setLoaderM(true);
        await axios(config);
      } catch (error) {
        console.log(error);
      }

      // Update comment
      try {
        setLoaderM(true);
        var config_comment: any = {
          method: 'post',
          url: 'resource/Comment',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
          },
          data: {
            comment_type: 'Comment',
            reference_doctype: 'Asset',
            reference_name: pname,
            content: update.comment,
          },
        };
        await axios(config_comment);
      } catch (error) {
        console.log(error);
      } finally {
        setLoaderM(false);
        setFabModal(false);
        present({
          message: t('Assets.CreateA.assetUpdateSuccesss'),
          duration: 2000,
        });
        // reset modal
        setUpdate({
          status: '',
          asset_state: '',
          brand: '',
          model: '',
          year: '',
          comment: '',
          asset_component: [],
          attachment: {},
          barcode_id: '',
        });
        getData();
      }
    } else {
      setFabModal(false);
    }
  }



  const takeQSPicture = async (imageElementIndex: number) => {
    try {
      const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
      });
      var imageUrl = image.webPath;

      // Can be set to the src of an image now
      var formentry: any = { ...update }
      formentry['attachment'].value = null;
      formentry['attachment'].src = imageUrl;
      formentry['attachment'].format = image.format;
      setUpdate(formentry);
    } catch (e) {
      //@ts-ignore
      console.log('Error: ', e);
      // @ts-ignore
      if (e.toString().includes('cancelled')) {
        //@ts-ignore
        present({
          message: t('Utils.cameraCancel'),
          color: 'danger',
          duration: 2000,
        });
      }
      //@ts-ignore
      else if (e.toString().includes('denied') || e.includes('access')) {
        //@ts-ignore
        present({
          message: t('Utils.cameraErrorPermission'),
          color: 'danger',
          duration: 2000,
        });
      } else {
        //@ts-ignore
        present({
          message: e,
          color: 'danger',
          duration: 2000,
        });
      }
    }
  };

  /* Remove Quick Status Image by cross click */
  const emptyQSImg = (index: number) => {
    var tempData = { ...update };
    tempData.attachment = {};
    setUpdate(tempData);
  };


  const handleScan = (data: any) => {
    if (data) {
      try {
        var tempData = { ...update };
        tempData.barcode_id = data;
        setUpdate(tempData);
      } catch {
        present({
          message: t('Utils.fetchFail'),
          color: 'danger',
          duration: 4000,
        });
        console.log('failed sending id to the parent');
      } finally {
        setScanToggle(false);
      }
    }
  };

  const Shimmer = () => {
    return (
      <div>
        {
          [1, 2, 3, 4, 5, 6, 7, 8, 9].map((element) => {
            return (
              <div
                key={element}
                id="shimmer-body"
                style={{ padding: '1rem' }}
                className="w-50 data-list shimmer-container"
              >
                <div className="flex justify-between">
                  <IonSkeletonText animated style={{ width: '50%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
                <IonSkeletonText animated style={{ width: '30%' }} />
              </div>
            );
          })
        }
      </div>
    );
  };

  const Highlights = ({ highlights }: { highlights: any }) => {
    return (
      <div>
        {highlights &&
          highlights.map((element: any, key: number) => {
            return (
              <IonCard id="card-container" key={key}>
                <IonCardHeader>
                  <IonCardTitle className="card-heading">
                    <div className="flex justify-between items-center">
                      <Link className="underline capitalize" to={{ pathname: '/assetDetail', state: element.name, search: 'back=assets' }}>
                        <span>{element.name}</span>
                      </Link>
                      <div
                        onClick={() => {
                          modalFunc(element.name);
                          setPname(element.name);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <IonIcon className="view-detail-highight-icon" src={constructOutline} />
                      </div>
                    </div>
                  </IonCardTitle>
                  <IonCardSubtitle className="flex items-center">
                    <div style={{ width: '12px', height: '12px', borderRadius: '1rem', backgroundColor: getStatusColor(element.status), margin: '4px' }}></div>
                    {t(`Utils.${element.status}`)}
                  </IonCardSubtitle>
                </IonCardHeader>
              </IonCard>
            );
          })}
        {highlights.length > 0 ? <Link to="/all" style={{ margin: 'auto', display: 'block' }} className="text-center underline pointer">{t('Utils.viewAll')}</Link> : ''}
      </div>
    );
  };

  return (
    <IonPage>
      <Header enableBackButton="" title="" />
      <IonContent>
        <div style={{ display: 'flex', margin: 'auto', paddingTop: '2rem', marginLeft: '12vw', marginRight: '12vw' }}>
          <Link to="/createAsset" style={{ textDecoration: 'none' }}>
            <section
              style={{
                width: '23vw',
                height: '6rem',
                marginLeft: '5px',
                marginRight: '5px',
                textAlign: 'center',
                boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
              }}
            >
              <img src={CreateAsset} alt="scan" style={{ paddingTop: '1rem' }} />
              <p style={{ margin: 'auto', color: 'rgba(29, 80, 141, 1)', marginTop: '2px', fontSize: '11px' }}>
                {t('Assets.Create')}
              </p>
            </section>
          </Link>
          <Link to="/createComp" style={{ textDecoration: 'none' }}>
            <section
              style={{
                width: '23vw',
                height: '6rem',
                marginLeft: '5px',
                marginRight: '5px',
                textAlign: 'center',
                boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
              }}
            >
              <img src={CreateComp} alt="scan" style={{ paddingTop: '1rem', width: '2rem' }} />
              <p style={{ margin: 'auto', color: 'rgba(29, 80, 141, 1)', marginTop: '12px', fontSize: '11px' }}>
                {t('Component.Create')}
              </p>
            </section>
          </Link>
          <Link to="/compDetails" style={{ textDecoration: 'none' }}>
            <section
              style={{
                width: '23vw',
                height: '6rem',
                marginLeft: '5px',
                marginRight: '5px',
                textAlign: 'center',
                boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
              }}
            >
              <img src={ViewComp} alt="scan" style={{ paddingTop: '1rem', width: '2rem' }} />
              <p
                style={{
                  margin: 'auto',
                  color: 'rgba(29, 80, 141, 1)',
                  marginTop: '12px',
                  fontSize: '11px',
                  padding: '1px',
                }}
              >
                {t('Component.View')}
              </p>
            </section>
          </Link>
        </div>
        <div className="flex justify-between w-50 data-list" style={{ marginTop: '1rem' }}>
          <IonItem lines="none" className="heading">
            <p>{t('Assets.highlights')}</p>
          </IonItem>
          <Link to="/all" style={{ textDecoration: 'underline' }}>
            <IonItem lines="none" className="heading">
              <img src={Clock} alt="scan" style={{ width: '0.8rem', marginRight: '4px' }} />
              <p style={{ color: 'rgba(29, 80, 141, 1)' }}> {t('Assets.history')}</p>
            </IonItem>
          </Link>
        </div>
        {/* {loader &&
          )} */}

        <div className="w-50 data-list">
          {loader && <Shimmer />}
          {highlights && <Highlights highlights={highlights} />}
        </div>
        <IonModal isOpen={fabModal} onDidDismiss={() => setFabModal(false)}>
          <IonHeader>
            <IonToolbar color="primary">
              &nbsp; {pname}
              <IonButtons slot="end">
                <IonButton
                  className="line-button"
                  onClick={() => {
                    setFabModal(false);
                  }}
                >
                  <IonIcon name="close" slot="icon-only"></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonCardContent style={{ overflowY: 'scroll' }}>
            <IonLoading
              cssClass="my-custom-class"
              isOpen={loaderM}
              onDidDismiss={() => setLoaderM(false)}
              message={t('Utils.pleasewait')}
            />
            <form onSubmit={(e) => SubmitStatusUpdate(e)}>
              {_data.map((form_field, index) => {
                if (form_field.field === 'status' || form_field.field === 'asset_state') {
                  return (
                    <div key={index} className="input-container">
                      <label className="label">{t(`Assets.CreateA.${form_field.field}`)}</label>
                      <Select
                        className="custom-select"
                        required={form_field.required}
                        id={`${form_field.field}_choice`}
                        name={form_field.field}
                        onChange={handleUpdateStatus}
                        value={update[form_field.field]}
                        disabled={form_field.disabled || false}
                        key={`${form_field.field}-${index}`}
                      >
                        {form_field.options &&
                          form_field.options.map(function (opt, index) {
                            return (
                              <MenuItem value={`${opt}`} key={`${form_field.field}-${index}`}>
                                <section
                                  key={`${form_field.field}-${index}`}
                                  style={{ display: 'flex', height: '1rem' }}
                                >
                                  {/* <img src={Man} alt="Users" style={{ width: '15px' }} /> */}
                                  <p
                                    key={`${form_field.field}-${index}`}
                                    style={{
                                      color: 'rgba(31, 87, 148, 1)',
                                      marginLeft: '0.2rem',
                                      fontSize: '12px',
                                      marginTop: '0px',
                                    }}
                                  >
                                    {form_field.translate ? t(`Assets.CreateA.${opt}`) : opt}
                                  </p>
                                </section>
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  );
                } else if (form_field.field === 'asset_component') {
                  return (
                    <div key={index} className="input-container">
                      <label className="label">{t(`Assets.CreateA.${form_field.field}`)}</label>
                      <IonSelect
                        id={`${form_field.field}_choice`}
                        className="custom-ion-select"
                        multiple
                        interface='popover' style={{ color: '#1d508d', fontSize: '14px' }} placeholder='Change Status'
                        onIonChange={(e) => handleMultiSelectChange(e.detail.value)}
                        value={update[form_field.field]}
                      >
                        {form_field.options &&
                          form_field.options.map(function (opt, indexSelect) {
                            return (
                              <IonSelectOption value={
                                //@ts-ignore
                                opt.id}
                                key={`${form_field.field}-${indexSelect}`}
                                id={//@ts-ignore
                                  `${opt.id}_item_${indexSelect}`}>{opt.label}</IonSelectOption>
                            );
                          })}
                      </IonSelect>
                    </div>
                  );
                } else if (
                  form_field.field === 'brand' ||
                  form_field.field === 'model' ||
                  form_field.field === 'year'
                ) {
                  return (
                    <div key={index} className="input-container">
                      <label className="label">{t(`Assets.CreateA.${form_field.field}`)}</label>
                      <input
                        className="basic-input"
                        required={false}
                        key={`${form_field.field}-${index}`}
                        id={form_field.field}
                        placeholder={t(`Assets.CreateA.${form_field.field}`)}
                        onChange={handleChangeQuickUpdate}
                        value={update[form_field.field]}
                      />
                    </div>
                  );
                }
                else if (form_field.type === 'file') {
                  return (
                    <div
                      id={`${form_field.field}-${index}`}
                      className="input-container"
                      key={`${form_field.field}-${index}`}
                    >
                      <label id={`label-${form_field.field}`} className="label">
                        {t(`Assets.CreateA.${form_field.field}`)}
                        {form_field.required ? '*' : null}{' '}
                      </label>
                      <IonButton
                        style={{ marginTop: '1rem', margin: 'auto', width: '40%', color: 'black', marginBottom: '1rem' }}
                        size="small"
                        color="light"
                        onClick={() => takeQSPicture(index)}
                      >
                        {//@ts-ignore
                          update[form_field.field]['value'] ? t('Utils.change_image') : t('Utils.image')}
                      </IonButton>
                      <div className="flex justify-center items-center">
                        {//@ts-ignore
                          update[form_field.field]['value'] ? (
                            <img
                              src={`${localStorage.getItem('baseUrl')?.replace('/api', '')}${
                                //@ts-ignore
                                update[form_field.field]['value']}`}
                              alt=""
                              style={{
                                width: '60%',
                                margin: 'auto',
                                display: 'block',
                                border: '1px solid lightgray',
                                borderRadius: '12px',
                              }}
                            />
                          ) : (
                            ''
                          )}
                        {//@ts-ignore
                          update['attachment']['src'] ? <img className="preview-survey-img" src={update['attachment']['src']} alt="" /> : ''}
                        {//@ts-ignore
                          update['attachment']['src'] ? (
                            <IonIcon className="close-icon" onClick={() => emptyQSImg(index)} src={close} />
                          ) : (
                            ''
                          )}
                      </div>
                    </div>
                  );
                } else if (form_field.type === 'barcode') {
                  return (
                    <div
                      id={`${form_field.field}-${index}`}
                      className="input-container"
                      key={`${form_field.field}-${index}`}
                    >
                      <label id={`label-${form_field.field}`} style={{ marginTop: '1rem' }} className="label">
                        {t(`Assets.CreateA.${form_field.field}`)}
                        {form_field.required ? '*' : null}
                      </label>
                      <div className="flex items-center justify-between basic-input">
                        <input
                          required={form_field.required}
                          className="gps-input"
                          id={form_field.field}
                          placeholder={t(`Assets.CreateA.${form_field.field}`)}
                          value={update['barcode_id']}
                          disabled={true}
                          style={{width: '80%'}}
                        />
                        <IonIcon onClick={() => setScanToggle(true)} className="gps-icon" src={barcodeOutline} />
                      </div>
                      <IonModal isOpen={scanToggle} onDidDismiss={() => setScanToggle(false)}>
                        <IonIcon name="close" slot="icon-only"
                          style={{ position: 'absolute', right: '1rem', top: '1rem', color: 'black', fontSize: '2rem', curosor: 'pointer' }}
                          onClick={() => {
                            setScanToggle(false);
                          }}></IonIcon>
                        {scanToggle ?
                          <BarcodeScannerComponent
                            torch={true}
                            onUpdate={(err, result) => {
                              //@ts-ignore
                              if (result && result.text) handleScan(result.text);
                            }}
                          /> : ''}
                        <div className="submit-button" style={{ textAlign: 'center' }} onClick={() => setScanToggle(false)}>
                          {t('Utils.cancel')}
                        </div>
                      </IonModal>
                    </div>
                  );
                }
                else {
                  return '';
                }
              })}
              <div className="input-container">
                <label className="label">{t(`Utils.Comment`)}</label>
                <textarea
                  className="textarea"
                  required={false}
                  key="comment"
                  id="comment"
                  placeholder={t('Utils.Add your comment')}
                  onChange={handleChangeQuickUpdate}
                  value={update.comment}
                />
              </div>
              <button className="submit-button" type="submit">
                {t(`Assets.CreateA.update`)}
              </button>
            </form>
          </IonCardContent>
        </IonModal>
        <br />
        <br />
        {!loader && highlights.length === 0 ? (
          <IonRow>
            <NoRecords width="30%" smallText={true} />
          </IonRow>
        ) : (
          <div></div>
        )}
        <AssetButton />
      </IonContent>
    </IonPage>
  );
};

export default Assets;
