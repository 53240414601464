export const createProjectFields = [
  {
    title: 'Project.info',
  },
  {
    field: 'project_name',
    required: true,
    type: 'text',
    value: '',
    requiredText: 'Project Name is required',
    disabled: false
  },
  {
    field: 'expected_start_date',
    required: false,
    type: 'date',
    value: '',
    requiredText: 'Please select Start Date',
    disabled: false
  },
  {
    field: 'expected_end_date',
    required: false,
    type: 'date',
    value: '',
    requiredText: 'Please select End Date',
    disabled: false
  },
  {
    field: 'location',
    required: false,
    type: 'select',
    options: [],
    value: '',
    requiredText: 'Please specify location',
    disabled: false
  },
  {
    field: 'priority',
    required: false,
    type: 'select',
    options: [],
    value: '',
    requiredText: 'Please select Priority',
    disabled: false
  },
  {
    field: 'status',
    required: true,
    type: 'select',
    options: [],
    value: '',
    requiredText: 'Please select Status',
    disabled: false,
    translate: true
  },
  {
    field: 'comment',
    required: false,
    type: 'textarea',
    value: ''
  },
  {
    field: 'amount',
    required: false,
    type: 'text',
    value: ''
  }
];
