import { IonContent, IonPage, useIonToast, IonLoading, useIonRouter, IonCheckbox, IonLabel, IonAlert } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import logo from '../assets/logo3.png';
import { useTranslation } from 'react-i18next';
import axios from '../axios';
import { AppContext } from '../providers/AppContext';
import CryptoJS from 'crypto-js';
import PushService from '../utils/pushService';
import { Link } from 'react-router-dom';

const Login: React.FC = () => {
  const [present] = useIonToast();
  const [showLoading, setShowLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const { t, i18n } = useTranslation();
  const url = '/method/preflet_asset.api.get_base_url';

  const [data, setData] = useState({
    usr: '',
    pwd: '',
  });
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const router = useIonRouter();
  const { dispatch, state } = useContext(AppContext);

  const handleChange = (event: React.ChangeEvent<{ value: unknown; id: any }>) => {
    const formentry: any = { ...data };
    //@ts-ignore
    formentry[event.target.id] = event.target.value.replace(/\s/g, '');
    setData(formentry);
  };

  const pushNotification = async () => {
    try {
      const temp = await PushService.checkPushPermission();
      if (temp) {
        PushService.register();
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    if (localStorage.getItem('remember') === 'true') {
      setChecked(true);
      //@ts-ignore
      var usr = CryptoJS.AES.decrypt(localStorage.getItem('username'), 'username');
      //@ts-ignore
      var pwd = CryptoJS.AES.decrypt(localStorage.getItem('password'), 'password');

      const formentry: any = { ...data };
      formentry.usr = usr.toString(CryptoJS.enc.Utf8);
      formentry.pwd = pwd.toString(CryptoJS.enc.Utf8);
      setData(formentry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var config: any = {
    method: 'post',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };

  const Submit = async (e: any) => {
    e.preventDefault();
    try {
      await axios.get(`${localStorage.getItem('baseUrl') ? localStorage.getItem('baseUrl') : ''}/method/logout`, {
        withCredentials: true,
      });

      // Protect Users Choice
      const temp = localStorage.getItem('remember');
      // Decrypt the token
      //@ts-ignore
      var usr = CryptoJS.AES.decrypt(localStorage.getItem('username'), 'username').toString(CryptoJS.enc.Utf8);
      //@ts-ignore
      var pwd = CryptoJS.AES.decrypt(localStorage.getItem('password'), 'password').toString(CryptoJS.enc.Utf8);
      // Perform the logout
      localStorage.clear();
      sessionStorage.clear();

      // Set Users Choice
      //@ts-ignore
      localStorage.setItem('remember', temp);
      // Encrypt the token
      const username = CryptoJS.AES.encrypt(usr, 'username').toString();
      const password = CryptoJS.AES.encrypt(pwd, 'password').toString();
      localStorage.setItem('username', username);
      localStorage.setItem('password', password);

      //@ts-ignore  // Set Remember Me
      localStorage.setItem('remember', checked);
    } catch (e) { }

    localStorage.setItem('baseUrl', state.originalBaseUrl);
    axios.defaults.baseURL = state.originalBaseUrl;
    setShowLoading(true);

    axios(config)
      .then(async (res) => {
        const lang = res?.data?.message?.key_details?.language;
        if (lang === 'en') {
          i18n.changeLanguage('en');
          localStorage.setItem('lang', 'en');
        } else {
          i18n.changeLanguage('pt');
          localStorage.setItem('lang', 'pt');
        }
        localStorage.setItem('fullName', res?.data?.message?.key_details?.full_name);
        localStorage.setItem('email', res?.data?.message?.user);
        const baseUrl = `${res?.data?.message?.base_url}/api`;
        localStorage.setItem('baseUrl', baseUrl);
        axios.defaults.baseURL = baseUrl;

        dispatch({ type: 'loggedIn', payload: res?.data });

        // set permissions
        dispatch({ type: 'permissions', payload: res?.data?.message?.access_level });
        present({
          message: t('Utils.loginS'),
          duration: 2000,
        });
        // pushNotification();

        if (res?.data?.message?.access_level?.home) {
          router.push('/home');
        } else {
          router.push('/survey-home');
        }
      })
      .catch((e) => {
        console.log(e);
        present({
          message: t('Utils.loginWrong'),
          color: 'danger',
          duration: 2000,
        });
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const sendEmailLink = async (event: any) => {
    try {
      const email = event[0];
      const res = await axios.post(`/method/preflet_asset.api.reset_password?email=${email}`);
      present({
        message: t('Login.sentEmail'),
        duration: 2000,
      });
    } catch (error) {
      // @ts-ignore
      console.log('error', error?.response?.data?.exc_type);
      // @ts-ignore
      if (error?.response?.data?.exc_type === 'DoesNotExistError') {
        present({
          message: t('Login.notexist'),
          color: 'danger',
          duration: 2000,
        });
      }
    }
  };


  return (
    <IonPage>
      <IonContent className="ion-text-center">
        <img src={logo} alt="Logo" style={{ maxWidth: '10rem', width: '35%', margin: 'auto', display: 'block', marginTop: '2rem' }}></img>
        <p
          style={{
            fontFamily: 'Nunito Sans Extra-bold, sans-serif',
            color: '#1D508D',
            fontSize: 'xx-large',
            marginTop: '0px',
            fontWeight: 'bolder',
          }}
        >
          <b>Hyprism</b>
        </p>
        <p style={{ color: '#1D508D', marginTop: '-10px', fontSize: 'small' }}>{t('Login.Login')}</p>

        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={t('Utils.pleasewait')}
          duration={5000}
        />
        <form onSubmit={Submit}>
          <div className="input-container">
            <input
              className="basic-input"
              style={{ padding: '15px' }}
              id="usr"
              required
              placeholder={t('Login.Name')}
              onChange={handleChange}
              value={data.usr}
            />
            <input
              className="basic-input"
              style={{ padding: '15px' }}
              id="pwd"
              required
              type="password"
              placeholder={t('Login.Pass')}
              onChange={handleChange}
              value={data.pwd}
            />
            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
              <IonCheckbox
                style={{ marginLeft: '15px', marginRight: '15px' }}
                checked={checked}
                onIonChange={(e) => setChecked(e.detail.checked)}
              />
              <p style={{ color: '#1D508D', fontSize: 'small' }}>{t('Login.Remember')}</p>
            </div>
          </div>
          <button className="submit-button" type="submit">
            {t('Login.L')}
          </button>
        </form>
        <IonLabel onClick={
          () => setShowEmailPopup(true)
        } className='underline' style={{ color: '#1D508D', fontSize: 'small' }}>
          {t('Login.Forgot')}
        </IonLabel>
        <IonAlert
          isOpen={showEmailPopup}
          onDidDismiss={() => setShowEmailPopup(false)}
          header="Enter Email"
          message={'We will send you an email with a link to reset your password.'}
          inputs={[
            {
              type: 'email',
              placeholder: 'Email',
            },
          ]}
          buttons={[
            {
              text: t('Utils.changePassword'),
              handler: (event) => {
                sendEmailLink(event);
              },
            },
          ]}
        ></IonAlert>
      </IonContent>
    </IonPage>
  );
};

export default Login;
