import {IonSkeletonText, withIonLifeCycle } from '@ionic/react';
import React, { useState } from 'react';

const Shimmer = React.forwardRef((props: any,ref) => {

    const [arrV] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  return (
      <div key={'78'} className="data-list">
        {arrV.map((index:any) => {
            return (
                <div key={index} className="preview-container">
                        <IonSkeletonText key={index + 'shimmer'} animated className="preview-key"
                         style={{ width: '30%', height: '1rem', borderRadius:'2px',marginBottom:'1.5rem' }}  />
                        <IonSkeletonText key={index + index} animated className="preview-value"
                         style={{ width: '20%', height: '1rem', borderRadius:'2px',marginBottom:'1.5rem' }}  />
                </div>
            )
        })}
        
      </div>
  );
});

export default withIonLifeCycle(Shimmer);
