import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import axios from '../axios';
import * as firebase from '@firebase/app';
import config from '../firebase';
import { getMessaging, getToken, isSupported } from "firebase/messaging";



const PushService = {
     // Check Permission
     checkPushPermission: async () => {
        const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
        if(isPushNotificationsAvailable) {
        const res = await PushNotifications.checkPermissions();
            if (res.receive !== 'granted') {
              PushNotifications.requestPermissions().then((res) => {
                if (res.receive === 'denied') {
                //   showToast('Push Notification permission denied');
                return false;
                }
                else {
                //   showToast('Push Notification permission granted');
                return true;
                }
              });
            }
            else {
              return true;
            }
        } else {
            const permission = await Notification.requestPermission();
            if(permission === 'granted') {
                return true;
            } else {
                return false;
            }
        }
    }, 
    register:async() => {
        const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
        if(!isPushNotificationsAvailable) {
            PushService.registerWebPlugin();
            return;
        }
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();

        // On success, we should be able to receive notifications
        var counter = 0;
        PushNotifications.addListener('registration',
            (token: Token) => {
                counter ++;
                if(counter === 1) {
                    PushService.saveToken(token.value);
                }
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error: any) => {
                alert('Error on registration: ' + JSON.stringify(error));
            }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                // setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
                // setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
            }
        );
    },
    registerWebPlugin: async () => {
        const initializedFirebaseApp = firebase.initializeApp(config);
        isSupported().then(async (supported) => {
            if(supported) {
                try {
                    const messaging = getMessaging(initializedFirebaseApp);
                    const temp = await getToken(messaging)
                    PushService.saveToken(temp);
                } catch (error) {
                    console.log('error', error);
                }
            }
            else {
                console.log('Firebase Messaging is not supported by this browser');
            }
        });
    },

    saveToken: async (token: string) => {
        try {
           await axios({
                method: 'post',
                url: `resource/fcm token`,
                headers: {
                  Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
                },
                data: {
                    token: token,
                    user: localStorage.getItem('email'),
                },
              });
        } catch (error) {
            console.log(error);
        }
       
    }
}

export default PushService;
