/* eslint-disable array-callback-return */
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonModal,
  IonPage,
  IonPopover,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { createAssetFields } from '../../models/createAssetFields';
import axios from '../../axios';
import { MenuItem, Select } from '@material-ui/core';
import { barcodeOutline, barcodeSharp, close, constructOutline, createOutline, download, informationCircle, print } from 'ionicons/icons';
import TimeAgo from 'timeago-react';
import { checkIfArraysAreSame, getAssetComponentByLabel, getSetAssetCategory, getSetAssetType, isFormDataEmpty } from '../../utils';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
//@ts-ignore
import Barcode from 'react-barcode';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { el } from 'date-fns/locale';

const AssetDet: React.FC = () => {
  const location = useLocation();
  const [present] = useIonToast();
  const [showLoading, setShowLoading] = useState(false);

  const [timeline, setTimeline] = useState([]);
  const [quickupdate, setQuickupdate] = useState(false);
  const [pname, setPname] = useState('');
  const [loaderM, setLoaderM] = useState(false);
  const [showQr, setShowQr] = useState(false);
  const [color, setColor] = useState('');
  const [asToggle, setAsToggle] = useState(false);
  const [scanToggle, setScanToggle] = useState(false);

  const [data, setData] = useState({
    asset_name: '',
    name: '',
  });

  const [primaryData, setPrimaryData] = useState({});

  const [update, setUpdate] = useState({
    status: '',
    asset_state: '',
    brand: '',
    model: '',
    year: '',
    comment: '',
    asset_component: [],
    attachment: {},
    barcode_id: ''
  });

  const { t } = useTranslation();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [_data, set_Data] = useState(createAssetFields);

  const handleUpdateStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    var formentry: any = { ...update };
    //@ts-ignore
    if (event.target.name in formentry) {
      //@ts-ignore
      formentry[event.target.name] = event.target.value;
    }
    setUpdate(formentry);
  };

  const handleChangeQuickUpdate = (event: React.ChangeEvent<{ value: unknown; id: any }>) => {
    var formentry: any = { ...update };
    if (event.target.id in formentry) {
      formentry[event.target.id] = event.target.value;
    }
    setUpdate(formentry);
  };

  const handleMultiSelectChange = (event: string) => {
    var formentry: any = { ...update };
    formentry['asset_component'] = event
    setUpdate(formentry);
  };


  const handleMultiSelectChangeEdit = (event: React.ChangeEvent<{ value: unknown }>) => {
    var formentry: any = [..._data];
    const f_index = formentry.findIndex((v: any) => v.field === 'asset_component');
    formentry[f_index].value = event;
    set_Data(formentry);
  };


  const getAssetIndividual = () => {
    var res: any = history.location.state;
    axios({
      method: 'get',
      url: `resource/Asset/${res}`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    })
      .then((res) => {
        const temp = res.data.data;
        if (temp?.asset_category) {
          getAssetCategoryColor(temp.asset_category || '')
        }
        setData(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (history?.location?.state)
      getAssetIndividual();
  }, [history.location.state]);


  const getAssetCategoryColor = (asset_category: string) => {
    axios({
      method: 'get',
      url: `resource/Asset Category/${asset_category}`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    })
      .then((res) => {
        setColor(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getTimeline = () => {
    const res: any = history.location.state;
    const url = `/resource/Version?fields=["*"]&filters=[["Version","docname","=","${res}"]]&limit_start=0&order_by=creation%20desc`;
    var config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    axios(config)
      .then((res) => {
        setTimeline(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => { });
  };

  useEffect(() => {
    getTimeline();
  }, []);

  const edit = () => {
    callAssetById();
    setShowModal(true);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown; id: any }>) => {
    var formentry: any = [..._data];
    const f_index = formentry.findIndex((v: any) => v.field === event.target.id);
    var change_form_object = formentry[f_index];
    change_form_object.value = event.target.value;
    formentry[f_index] = change_form_object;
    set_Data(formentry);
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    var formentry: any = [..._data];
    // @ts-ignore
    const select = event.target.name.replaceAll('-', '_');
    const f_index = formentry.findIndex((v: any) => v.field === select);
    formentry[f_index].value = event.target.value;
    set_Data(formentry);
  };

  // Mapping fields after scan
  const callAssetById = async () => {
    setShowLoading(true);
    /* Get Project by User */
    // const projectList = await getSetProjectList(1);
    /* Get Asset Category */
    const assetCategory = await getSetAssetCategory();
    /* Get Asset Type */
    const assetType = await getSetAssetType();

    var config: any = {
      method: 'get',
      url: `resource/Asset/${data.name}`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };

    try {
      const res = await axios(config);
      setPrimaryData(res?.data?.data);
      const temp = res.data.data;
      for (const x in createAssetFields) {
        const f = createAssetFields[x].field ? createAssetFields[x].field : '';
        if (f === 'project') {
          createAssetFields[x].value = temp['project'];
          createAssetFields[x].type = 'text';
          createAssetFields[x].disabled = true;
        } else if (f === 'asset_component') {
          var assetComponent: { asset_component: any; }[] = []
          temp.asset_component.map((item: any) => {
            assetComponent.push(item?.asset_component)
          })
          // @ts-ignore
          createAssetFields[x].value = assetComponent
        } else if (f === 'asset_category' || f === 'asset_type') {
          createAssetFields[x].value = temp[f];
          createAssetFields[x].options = f === 'asset_category' ? assetCategory : assetType;
        } else if (f) {
          createAssetFields[x].value = temp[f];
        }
      }
      set_Data(createAssetFields);
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }

    var Data = [..._data];

    /* Get Asset Component to link Asset */
    const acname = Data.findIndex((d) => d.field === 'asset_component');
    Data[acname].options = await getAssetComponentByLabel();
    set_Data(Data);
  };

  const Submit = (e: any) => {
    e.preventDefault();
    hitEditAsset();
  };

  const hitEditAsset = async () => {
    const formData = new FormData();
    for (const _entry of _data) {
      if (_entry.field === 'attachment' && _entry['src']) {
        try {
          const response = await fetch(_entry?.src?.toString());
          const blob = await response.blob();
          formData.append(
            'attachment',
            new File([blob], `${data.name}-attachment.${_entry.format}`, {
              type: `image/${_entry.format}`,
            })
          );
        } catch (e) {
          console.log(e)
        }
      }
      else if (_entry.field === 'asset_component') {
        if (_entry.value?.length > 0) {
          var assComp = [] as any;
          for (const element in _entry.value as any) {
            assComp.push({
              // @ts-ignore
              asset_component: _entry.value[element]
            })
          }
          //@ts-ignore
          if (!checkIfArraysAreSame(primaryData[_entry.field], assComp))
            formData.append('asset_component', JSON.stringify(assComp));
        }
      } // @ts-ignore
      else if (_entry.field && (primaryData[_entry.field] !== _entry.value)) {
        formData.append(_entry.field, _entry.value as any);
      }
    }

    var config: any = {
      method: 'put',
      url: `method/preflet_asset.api.update_asset/${data.name}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: formData,
    };
    if (!isFormDataEmpty(formData)) {
      setShowLoading(true);
      axios(config)
        .then(() => {
          present({
            message: t('Assets.CreateA.assetUpdateSuccesss'),
            duration: 2000,
          });
          reset();
          setShowModal(false);
          getAssetIndividual();
          getTimeline();
        })
        .catch(function (error) {
          console.log(error?.response?.data);
          present({
            message: 'Try Again',
            color: 'danger',
            duration: 2000,
          });
        })
        .finally(() => {
          setShowLoading(false);
        });
    } else {
      setShowModal(false);
    }
  }


  const reset = () => {
    for (const x in _data) {
      if (_data[x].field === 'attachment') {
        _data[x].src = '';
        _data[x].format = '';
        _data[x].value = '';
      } else if (_data[x].field === 'asset_component') {
        _data[x].value = [];
      } else _data[x].value = '';
    }

    for (const x in createAssetFields) {
      const f = createAssetFields[x].field;
      if (f === 'project') {
        createAssetFields[x].value = '';
        createAssetFields[x].options = [];
        createAssetFields[x].disabled = false;
      } else if (f === 'task') {
        createAssetFields[x].value = '';
        /* Get TASK by PROJECT */
        createAssetFields[x].options = [];
      }
    }
  };

  const returnTimeline = (item: any) => {
    if (JSON.parse(item.data).created_by && JSON.parse(item.data).created_by.length) {
      return (
        <div key={JSON.parse(item.data).created_by.length} className="timeline-container">
          <div className="timeline-dot"></div>
          {/* <span>{name}</span> */}
          <span>
            <b className="text-black">{JSON.parse(item.data).created_by}</b>&nbsp; {t('Utils.created')}{' '}
            <b>{data.asset_name}</b>
          </span>
          <span>
            {' '}
            -{' '}
            <TimeAgo
              datetime={new Date(JSON.parse(item.data).creation.split(' ').join('T') + 'Z').toLocaleString('lt-LT')}
            />
          </span>
        </div>
      );
    } else if (JSON.parse(item.data).added && JSON.parse(item.data).added.length) {
      return JSON.parse(item.data).added.map((element: any, index: number) => {
        return (
          <div key={index} className="timeline-container">
            <div className="timeline-dot"></div>
            {/* @ts-ignore */}
            <span>
              <b className="text-black">{element[1].owner}</b>&nbsp;{t('Utils.added')}&nbsp;
            </span>
            <span>
              <b className="text-black">{t(`Assets.CreateA.${element[0]}`)}</b>&nbsp;
            </span>
            <span>
              : <b className="text-black">{element[1].component_name}</b>&nbsp;
            </span>
            <span>
              {' '}
              -{' '}
              <b>
                {' '}
                <TimeAgo datetime={new Date(item.modified.split(' ').join('T') + 'Z').toLocaleString('lt-LT')} />
              </b>
            </span>
          </div>
        );
      });
    } else if (JSON.parse(item.data).changed && JSON.parse(item.data).changed.length) {
      return JSON.parse(item.data).changed.map((element: any, key: number) => {
        return (
          <div key={key} className="timeline-container">
            <div className="timeline-dot"></div>
            <span>
              <b className="text-black">{element[1] && element[1].owner}</b>&nbsp;{t('Utils.changed')}&nbsp;
            </span>
            <span>
              <b className="text-black">{t(`Assets.CreateA.${element[0]}`)}</b>&nbsp;{t('Utils.from')}&nbsp;
            </span>
            <span>
              <b className="text-black">{element[1] ? element[1] : '--'}</b>&nbsp;{t('Utils.to')}&nbsp;
            </span>
            <span>
              <b className="text-black"> {element[2]}</b>
            </span>
            -
            <TimeAgo datetime={new Date(item.modified.split(' ').join('T') + 'Z').toLocaleString('lt-LT')} />
          </div>
        );
      });
    }
  };
  const downloadQR = () => {
    const svg = document.getElementById('download-qr-code');
    //@ts-ignore
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = function () {
      canvas.width = parseInt(canvas.width as any);
      canvas.height = parseInt(canvas.height as any);
      //@ts-ignore
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');

      const downloadLink = document.createElement('a');
      downloadLink.download = 'barCode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
  };

  const quickStatus = async () => {
    setQuickupdate(true);
    var Data = [..._data];
    setPrimaryData(data);
    /* Get Asset Component to link Asset */
    const acname = Data.findIndex((d) => d.field === 'asset_component');
    Data[acname].options = await getAssetComponentByLabel();
    set_Data(Data);

    var assetComponent: { asset_component: any; }[] = []
    //@ts-ignore
    data?.asset_component.map((item: any) => {
      assetComponent.push(item?.asset_component)
    })

    var formentry: any = { ...update };
    //@ts-ignore
    formentry.status = data.status
    //@ts-ignore
    formentry.asset_state = data.asset_state
    //@ts-ignore
    formentry.brand = data.brand
    //@ts-ignore
    formentry.model = data.model
    //@ts-ignore
    formentry.year = data.year
    //@ts-ignore
    formentry.comment = data.comment
    //@ts-ignore
    formentry.attachment.value = data.attachment
    //@ts-ignore
    formentry.barcode_id = data.barcode_id
    //@ts-ignore
    formentry.asset_component = assetComponent
    setUpdate(formentry);
  };

  const SubmitStatusUpdate = async (e: any) => {
    e.preventDefault();
    hitQSupdate();
  };

  const hitQSupdate = async () => {
    const formData = new FormData();
    const temp = { ...update }
    for (const item in temp) {
      //@ts-ignore
      if (item === 'attachment' && temp['attachment']?.src) {
        try {
          //@ts-ignore
          const response = await fetch(temp['attachment']?.src?.toString());
          const blob = await response.blob();
          formData.append(
            'attachment',
            // @ts-ignore
            new File([blob], `${pname}-attachment.${temp['attachment'].format}`, {
              // @ts-ignore
              type: `image/${temp['attachment'].format}`,
            })
          );
        } catch (e) {
          console.log(e)
        }
      } else if (item === 'asset_component') {
        if (temp[item].length > 0) {
          var assComp = [] as any;
          for (const element in temp['asset_component']) {
            assComp.push({
              asset_component: temp['asset_component'][element]
            })
          }
          // @ts-ignore
          if (!checkIfArraysAreSame(primaryData[item], assComp))
            formData.append('asset_component', JSON.stringify(assComp));
        }
        // @ts-ignore
      } else if (item === 'attachment' && (primaryData[item] !== temp[item].value)) {
        // @ts-ignore
        formData.append('attachment', temp[item].value);
        // @ts-ignore
      } else if (temp[item] !== '' && temp[item] !== null && temp[item] !== undefined && temp[item] !== 'undefined') {
        // @ts-ignore
        if ((primaryData[item] !== temp[item]) && item !== 'attachment')
          // @ts-ignore
          formData.append(item, temp[item]);
      }
    }

    var config: any = {
      method: 'put',
      url: `method/preflet_asset.api.update_asset/${pname}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: formData,
    };
    if (!isFormDataEmpty(formData)) {
      // Update status api
      try {
        setLoaderM(true);
        await axios(config);
      } catch (error) {
        console.log(error);
      }

      // Update comment
      try {
        setLoaderM(true);
        var config_comment: any = {
          method: 'post',
          url: 'resource/Comment',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
          },
          data: {
            comment_type: 'Comment',
            reference_doctype: 'Asset',
            reference_name: pname,
            content: update.comment,
          },
        };
        await axios(config_comment);
      } catch (error) {
        console.log(error);
      } finally {
        getAssetIndividual();
        getTimeline();
        setLoaderM(false);
        setQuickupdate(false);
        present({
          message: t('Assets.CreateA.assetUpdateSuccesss'),
          duration: 2000,
        });
        // reset modal
        setUpdate({
          status: '',
          asset_state: '',
          brand: '',
          model: '',
          year: '',
          comment: '',
          asset_component: [],
          attachment: {},
          barcode_id: ''
        });
      }
    } else {
      setQuickupdate(false);
    }
  }

  const openMap = (coordinates: any) => {
    let url = 'https://www.google.com.sa/maps/search/' + coordinates;
    window.open(url, '_blank');
  };

  const closeModal = () => {
    setShowModal(false);
    for (const x in createAssetFields) {
      const f = createAssetFields[x].field;
      if (f === 'project') {
        createAssetFields[x].value = '';
        createAssetFields[x].options = [];
        createAssetFields[x].disabled = false;
      } else if (f === 'task') {
        createAssetFields[x].value = '';
        /* Get TASK by PROJECT */
        createAssetFields[x].options = [];
      }
    }
  };

  const takePicture = async (imageElementIndex: number) => {
    try {
      const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
      });

      var imageUrl = image.webPath;

      // Can be set to the src of an image now
      var formentry: any = [..._data];
      formentry[imageElementIndex].value = null;
      formentry[imageElementIndex].src = imageUrl;
      formentry[imageElementIndex].format = image.format;
      set_Data(formentry);
    } catch (e) {
      //@ts-ignore
      console.log('Error: ', e);
      // @ts-ignore
      if (e.toString().includes('cancelled')) {
        //@ts-ignore
        present({
          message: t('Utils.cameraCancel'),
          color: 'danger',
          duration: 2000,
        });
      }
      //@ts-ignore
      else if (e.toString().includes('denied') || e.includes('access')) {
        //@ts-ignore
        present({
          message: t('Utils.cameraErrorPermission'),
          color: 'danger',
          duration: 2000,
        });
      } else {
        //@ts-ignore
        present({
          message: e,
          color: 'danger',
          duration: 2000,
        });
      }
    }
  };

  const takeQSPicture = async (imageElementIndex: number) => {
    try {
      const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
      });
      var imageUrl = image.webPath;

      // Can be set to the src of an image now
      var formentry: any = { ...update }
      formentry['attachment'].value = null;
      formentry['attachment'].src = imageUrl;
      formentry['attachment'].format = image.format;
      setUpdate(formentry);
    } catch (e) {
      //@ts-ignore
      console.log('Error: ', e);
      // @ts-ignore
      if (e.toString().includes('cancelled')) {
        //@ts-ignore
        present({
          message: t('Utils.cameraCancel'),
          color: 'danger',
          duration: 2000,
        });
      }
      //@ts-ignore
      else if (e.toString().includes('denied') || e.includes('access')) {
        //@ts-ignore
        present({
          message: t('Utils.cameraErrorPermission'),
          color: 'danger',
          duration: 2000,
        });
      } else {
        //@ts-ignore
        present({
          message: e,
          color: 'danger',
          duration: 2000,
        });
      }
    }
  };

  /* Remove Image by cross click */
  const emptyImg = (index: number) => {
    var tempData = [..._data];
    if (tempData[index].src) {
      tempData[index].src = '';
    } else if (tempData[index].value) {
      tempData[index].value = '';
    }
    set_Data(tempData);
  };

  /* Remove Quick Status Image by cross click */
  const emptyQSImg = (index: number) => {
    var tempData = { ...update };
    tempData.attachment = {};
    setUpdate(tempData);
  };

  const getScan = () => {
    setScanToggle(true);
  };

  const handleScan = (data: any) => {
    if (data) {
      try {
        var tempData = { ...update };
        tempData.barcode_id = data;
        setUpdate(tempData);
      } catch {
        present({
          message: t('Utils.fetchFail'),
          color: 'danger',
          duration: 4000,
        });
        console.log('failed sending id to the parent');
      } finally {
        setScanToggle(false);
      }
    }
  };

  return (
    <IonPage>
      <Header enableBackButton={location.search ? '/' + location.search.replace("?back=", "") : '/all'} title={t('Service.ADet')} />
      <IonModal isOpen={quickupdate} onDidDismiss={() => setQuickupdate(false)}>
        <IonHeader style={{ marginBottom: '2rem' }}>
          <IonToolbar color="primary">
            <p className="text-center">
              <b>{pname}</b>
            </p>
            <IonButtons slot="end">
              <IonButton
                className="line-button"
                onClick={() => {
                  setQuickupdate(false);
                }}
              >
                <IonIcon name="close" slot="icon-only"></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonLoading
            cssClass="my-custom-class"
            isOpen={loaderM}
            onDidDismiss={() => setLoaderM(false)}
            message={t('Utils.pleasewait')}
          />
          <form onSubmit={(e) => SubmitStatusUpdate(e)}>
            {_data.map((form_field, index) => {
              if (form_field.field === 'asset_component') {
                return (
                  <div key={index} className="input-container">
                    <label className="label">{t(`Assets.CreateA.${form_field.field}`)}</label>
                    <IonSelect
                      id={`${form_field.field}_choice`}
                      className="custom-ion-select"
                      multiple
                      interface='popover' style={{ color: '#1d508d', fontSize: '14px' }} placeholder='Change Status'
                      onIonChange={(e) => handleMultiSelectChange(e.detail.value)}
                      value={update[form_field.field]}
                    >
                      {form_field.options &&
                        form_field.options.map(function (opt, indexSelect) {
                          return (
                            <IonSelectOption value={
                              //@ts-ignore
                              opt.id}
                              key={`${form_field.field}-${indexSelect}`}
                              id={//@ts-ignore
                                `${opt.id}_item_${indexSelect}`}>{opt.label}</IonSelectOption>
                          );
                        })}
                    </IonSelect>
                  </div>
                );
              } else if (form_field.field === 'status' || form_field.field === 'asset_state') {
                return (
                  <div key={index} className="input-container">
                    <label className="label">{t(`Assets.CreateA.${form_field.field}`)}</label>
                    <Select
                      className="custom-select"
                      required={form_field.required}
                      id={`${form_field.field}_choice`}
                      name={form_field.field}
                      onChange={handleUpdateStatus}
                      value={update[form_field.field]}
                      disabled={form_field.disabled || false}
                      key={`${form_field.field}-${index}`}
                    >
                      {form_field.options &&
                        form_field.options.map(function (opt, index) {
                          return (
                            <MenuItem value={`${opt}`} key={`${form_field.field}-${index}`}>
                              <section key={`${form_field.field}-${index}`} style={{ display: 'flex', height: '1rem' }}>
                                <p
                                  key={`${form_field.field}-${index}`}
                                  style={{
                                    color: 'rgba(31, 87, 148, 1)',
                                    marginLeft: '0.2rem',
                                    fontSize: '12px',
                                    marginTop: '0px',
                                  }}
                                >
                                  {form_field.translate ? t(`Assets.CreateA.${opt}`) : opt}
                                </p>
                              </section>
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </div>
                );
              } else if (form_field.field === 'brand' || form_field.field === 'model' || form_field.field === 'year') {
                return (
                  <div key={index} className="input-container">
                    <label className="label">{t(`Assets.CreateA.${form_field.field}`)}</label>
                    <input
                      className="basic-input"
                      required={false}
                      key={`${form_field.field}-${index}`}
                      id={form_field.field}
                      placeholder={t(`Assets.CreateA.${form_field.field}`)}
                      onChange={handleChangeQuickUpdate}
                      value={update[form_field.field]}
                    />
                  </div>
                );
              } else if (form_field.type === 'file') {
                return (
                  <div
                    id={`${form_field.field}-${index}`}
                    className="input-container"
                    key={`${form_field.field}-${index}`}
                  >
                    <label id={`label-${form_field.field}`} className="label">
                      {t(`Assets.CreateA.${form_field.field}`)}
                      {form_field.required ? '*' : null}{' '}
                    </label>
                    <IonButton
                      style={{ marginTop: '1rem', margin: 'auto', width: '40%', color: 'black', marginBottom: '1rem' }}
                      size="small"
                      color="light"
                      onClick={() => takeQSPicture(index)}
                    >
                      {//@ts-ignore
                        update[form_field.field]['value'] ? t('Utils.change_image') : t('Utils.image')}
                    </IonButton>
                    <div className="flex justify-center items-center">
                      {//@ts-ignore
                        update[form_field.field]['value'] ? (
                          <img
                            src={`${localStorage.getItem('baseUrl')?.replace('/api', '')}${
                              //@ts-ignore
                              update[form_field.field]['value']}`}
                            alt=""
                            style={{
                              width: '60%',
                              margin: 'auto',
                              display: 'block',
                              border: '1px solid lightgray',
                              borderRadius: '12px',
                            }}
                          />
                        ) : (
                          ''
                        )}
                      {//@ts-ignore
                        update['attachment']['src'] ? <img className="preview-survey-img" src={update['attachment']['src']} alt="" /> : ''}
                      {//@ts-ignore
                        update['attachment']['src'] ? (
                          <IonIcon className="close-icon" onClick={() => emptyQSImg(index)} src={close} />
                        ) : (
                          ''
                        )}
                    </div>
                  </div>
                );
              } else if (form_field.type === 'barcode') {
                return (
                  <div
                    id={`${form_field.field}-${index}`}
                    className="input-container"
                    key={`${form_field.field}-${index}`}
                  >
                    <label id={`label-${form_field.field}`} style={{ marginTop: '1rem' }} className="label">
                      {t(`Assets.CreateA.${form_field.field}`)}
                      {form_field.required ? '*' : null}{' '}
                    </label>
                    <div className="flex items-center justify-between basic-input">
                      <input
                        required={form_field.required}
                        className="gps-input"
                        id={form_field.field}
                        placeholder={t(`Assets.CreateA.${form_field.field}`)}
                        value={//@ts-ignore
                          update[form_field.field]}
                        disabled={true}
                        style={{width: '80%'}}
                      />
                      <IonIcon onClick={() => getScan()} className="gps-icon" src={barcodeOutline} />
                    </div>
                    <IonModal key={index} isOpen={scanToggle} onDidDismiss={() => setScanToggle(false)}>
                      <IonHeader>
                        <IonToolbar color="primary">
                          <IonButtons slot="end">
                            <IonButton
                              className="line-button"
                              onClick={() => {
                                setScanToggle(false);
                              }}
                            >
                              <IonIcon name="close" slot="icon-only"></IonIcon>
                            </IonButton>
                          </IonButtons>
                        </IonToolbar>
                      </IonHeader>
                      <BarcodeScannerComponent
                        torch={true}
                        onUpdate={(err, result) => {
                          //@ts-ignore
                          if (result && result.text) handleScan(result.text);
                        }}
                      />
                      <div className="submit-button" style={{ textAlign: 'center' }} onClick={() => setScanToggle(false)}>
                        {t('Utils.cancel')}
                      </div>
                    </IonModal>
                  </div>
                );
              }
              else {
              }
            })}
            <div className="input-container">
              <label className="label">{t(`Utils.Comment`)}</label>
              <textarea
                className="textarea"
                required={false}
                key="comment"
                id="comment"
                placeholder={t('Utils.Add your comment')}
                onChange={handleChangeQuickUpdate}
                value={update.comment}
              />
            </div>
            <button className="submit-button" type="submit">
              {t(`Assets.CreateA.update`)}
            </button>
          </form>
        </IonContent>
      </IonModal>

      <IonModal isOpen={showModal} onDidDismiss={() => closeModal()}>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={t('Utils.pleasewait')}
          duration={showModal ? 1000 : 5000}
        />
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>
              {t(`Assets.CreateA.edit`)} {data.asset_name}{' '}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="line-button"
                onClick={() => {
                  for (const x in _data) {
                    _data[x].value = '';
                  }
                  setShowModal(false);
                }}
              >
                <IonIcon name="close" slot="icon-only"></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <form onSubmit={(e) => Submit(e)}>
            {_data.map((form_field, index) => {
              if (form_field.title) {
                return (
                  <p key={index} className="form-title">
                    {t(form_field.title)}
                  </p>
                );
              } else if (form_field.type === 'text') {
                return (
                  <div key={index} className="input-container">
                    <label className="label">
                      {t(`Assets.CreateA.${form_field.field}`)}
                      {form_field.required ? '*' : null}
                    </label>
                    <input
                      required={form_field.required}
                      className="basic-input"
                      key={`${form_field.field}-${index}`}
                      id={form_field.field}
                      placeholder={t(`Assets.CreateA.${form_field.field}`)}
                      onChange={handleChange}
                      value={form_field.value}
                      disabled={form_field.disabled || false}
                      style={{
                      background: form_field.disabled ? '#F1F7F5' : ''
                      }}
                    />
                  </div>
                );
              } else if (form_field.type === 'textarea') {
                return (
                  <div key={index} className="input-container">
                    <label className="label">
                      {t(`Assets.CreateA.${form_field.field}`)}
                      {form_field.required ? '*' : null}
                    </label>
                    <textarea
                      className="textarea"
                      required={form_field.required}
                      key={`${form_field.field}-${index}`}
                      id={form_field.field}
                      placeholder={t(`Assets.CreateA.${form_field.field}`)}
                      onChange={handleChange}
                      value={form_field.value}
                    />
                  </div>
                );
              } else if (form_field.type === 'select') {
                return (
                  <div key={index} className="input-container">
                    <label className="label">
                      {t(`Assets.CreateA.${form_field.field}`)}
                      {form_field.required ? '*' : null}
                    </label>
                    <Select
                      className="custom-select"
                      required={form_field.required}
                      id={`${form_field.field}_choice`}
                      name={form_field.field}
                      onChange={handleSelectChange}
                      value={form_field.value}
                      disabled={form_field.disabled || false}
                      key={`${form_field.field}-${index}`}
                    >
                      {form_field.options &&
                        form_field.options.map(function (opt, indexSelect) {
                          return (
                            <MenuItem
                              value={`${opt}`}
                              key={`${form_field.field}-${indexSelect}`}
                              id={`${opt}_item_${indexSelect}`}
                            >
                              <section
                                key={`${form_field.field}-${indexSelect}`}
                                style={{ display: 'flex', height: '1rem' }}
                              >
                                <p
                                  style={{
                                    color: 'rgba(31, 87, 148, 1)',
                                    marginLeft: '0.2rem',
                                    fontSize: '12px',
                                    marginTop: '0px',
                                  }}
                                >
                                  {form_field.translate ? t(`Assets.CreateA.${opt}`) : opt}
                                </p>
                              </section>
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </div>
                );
              } else if (form_field.type === 'multi-select') {
                return (
                  <div
                    id={`${form_field.field}-${index}`}
                    className="input-container"
                    key={`${form_field.field}-${index}`}
                  >
                    <label id={`label-${form_field.field}`} className="label">
                      {t(`Assets.CreateA.${form_field.field}`)}
                      {form_field.required ? '*' : null}{' '}
                    </label>
                    {form_field.info ? (
                      <div className="tooltip">
                        <IonIcon style={{ marginBottom: '-4px' }} src={informationCircle} />
                        <span className="tooltiptext">{t(`Utils.${form_field.info}`)}</span>
                      </div>
                    ) : null}
                    <div style={{ display: 'flex', flexWrap: 'wrap', padding: '2px', marginLeft: '14px' }}>
                      {Object.values(form_field.value).map((val: any, key) => {
                        return (
                          <p key={key} style={{ fontSize: '10px', color: 'blue', textDecoration: 'underline', cursor: 'pointer', marginRight: '0.6rem' }}>
                            <Link to={{ pathname: '/comDetail', state: val.id }}>{val.label}</Link>
                          </p>
                        )
                      })}
                    </div>
                    <IonSelect
                      id={`${form_field.field}_choice`}
                      className="custom-ion-select"
                      multiple
                      interface='popover' style={{ color: '#1d508d', fontSize: '14px' }} placeholder='Asset components'
                      onIonChange={(e) => handleMultiSelectChangeEdit(e.detail.value)}
                      value={form_field.value || []}
                    >
                      {form_field.options &&
                        form_field.options.map(function (opt, indexSelect) {
                          return (
                            <IonSelectOption value={
                              //@ts-ignore
                              opt.id}
                              key={`${form_field.field}-${indexSelect}`}
                              id={//@ts-ignore
                                `${opt.id}_item_${indexSelect}`}>{opt.label}</IonSelectOption>
                          );
                        })}
                    </IonSelect>
                  </div>
                );
              }
              else if (form_field.type === 'file') {
                return (
                  <div
                    id={`${form_field.field}-${index}`}
                    className="input-container"
                    key={`${form_field.field}-${index}`}
                  >
                    <label id={`label-${form_field.field}`} className="label">
                      {t(`Assets.CreateA.${form_field.field}`)}
                      {form_field.required ? '*' : null}{' '}
                    </label>
                    <IonButton
                      style={{ marginTop: '1rem', margin: 'auto', width: '40%', color: 'black', marginBottom: '1rem' }}
                      size="small"
                      color="light"
                      onClick={() => takePicture(index)}
                    >
                      {form_field.src || form_field.value ? t('Utils.change_image') : t('Utils.image')}
                    </IonButton>
                    <div className="flex justify-center items-center">
                      {form_field.value ? (
                        <img
                          src={`${localStorage.getItem('baseUrl')?.replace('/api', '')}${form_field.value}`}
                          alt=""
                          style={{
                            width: '40%',
                            margin: 'auto',
                            display: 'block',
                            border: '1px solid lightgray',
                            borderRadius: '12px',
                          }}
                        />
                      ) : (
                        ''
                      )}
                      {form_field['src'] ? <img className="preview-survey-img" src={form_field['src']} alt="" /> : ''}
                      {form_field['src'] ? (
                        <IonIcon className="close-icon" onClick={() => emptyImg(index)} src={close} />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                );
              }
              else if (form_field.type === 'date' || form_field.type === 'datetime-local') {
                return (
                  <div key={index} className="input-container">
                    <label className="label">
                      {t(`Assets.CreateA.${form_field.field}`)}
                      {form_field.required ? '*' : null}
                    </label>
                    <input
                      required={form_field.required}
                      className="date"
                      key={`${form_field.field}-${index}`}
                      id={form_field.field}
                      placeholder={t(`Assets.CreateA.${form_field.field}`)}
                      onChange={handleChange}
                      value={form_field.value}
                      type={form_field.type}
                    />
                  </div>
                );
              } else {
                return '';
              }
            })}
            <button className="submit-button" type="submit">
              {t(`Assets.CreateA.save`)}
            </button>
          </form>
        </IonContent>
      </IonModal>
      <IonContent>
        <div className="preview-header">
          <p className="preview-title" style={{ width: '50%' }}>{data.asset_name}</p>
          <div className="flex" style={{ width: '50%', justifyContent: 'flex-end' }}>
            <IonIcon
              onClick={() => {
                setPname(data.asset_name);
                quickStatus();
              }}
              className="action-icon"
              src={constructOutline}
            />
            <IonIcon
              className="action-icon"
              onClick={() => {
                edit();
              }}
              src={createOutline}
            />
            {//@ts-ignore
              data.barcode_id ?
                <IonIcon className="action-icon" onClick={() => setShowQr(true)} src={barcodeOutline} /> : ''}
          </div>
        </div>

        {createAssetFields.map((item: any, index) => {
          return (
            <div key={index} className="data-list">
              {item.field ? (
                <div className="preview-container">
                  <p className="preview-key">{t(`Assets.CreateA.${item.field}`)}</p>
                  {item.field === 'northing_easting_point' ? (
                    //@ts-ignore
                    <p onClick={() => openMap(data[item.field])} className="preview-value pointer">
                      <u>
                        {
                          // @ts-ignore
                          data[item.field] ? data[item.field] : '-'
                        }
                      </u>
                    </p>
                  ) : null}
                  {item.field === 'asset_component'
                    ? // @ts-ignore
                    data[item.field] &&
                    // @ts-ignore
                    data[item.field].map((val: any, key: any) => {
                      return (
                        <p key={key} className="preview-value pointer">
                          <Link to={{ pathname: '/comDetail', state: val.asset_component }}>
                            <u style={{ textTransform: 'capitalize' }}>{val.component_name}</u>
                          </Link>
                        </p>
                      );
                    })
                    : null}
                  {item.field === 'asset_category'
                    ? // @ts-ignore
                    <div className='flex items-center'>
                      <p className="preview-value pointer underline" onClick={() => setAsToggle(true)}>
                        {/* @ts-ignore */}
                        {data[item.field] && item.translate
                          ? //@ts-ignore
                          t(`Assets.CreateA.${data[item.field]}`)
                          : //@ts-ignore
                          data[item.field]
                            ? //@ts-ignore
                            data[item.field]
                            : '-'}
                      </p>
                      {color ? <div className="color-box" style={{
                        background: `${
                          //@ts-ignore
                          color.color}`
                      }} onClick={() => setAsToggle(true)} id="trigger-button"></div> : ''}

                      <IonPopover isOpen={asToggle}
                        mode="ios"
                        backdropDismiss={false}
                        onDidDismiss={() => setAsToggle(false)}
                        //@ts-ignore
                        trigger="trigger-button">
                        <div style={{ marginTop: '.5rem' }} className="preview-container">
                          <p></p>
                          <IonIcon className="close-icon" onClick={() => setAsToggle(false)} src={close} />
                        </div>
                        <div className="preview-container">
                          <p className="preview-key ">
                            {t(`Assets.CreateA.category_name`)}
                          </p>
                          <p className="preview-value pointer">
                            {//@ts-ignore
                              color.category_name}
                          </p>
                        </div>
                        <div className="preview-container">
                          <p className="preview-key ">
                            {t(`Assets.CreateA.color`)}
                          </p>
                          <p className="preview-value pointer">
                            {//@ts-ignore
                              color.color}
                          </p>
                        </div>
                      </IonPopover>
                    </div>
                    : null}
                  {item.field !== 'asset_component' && item.field !== 'northing_easting_point' && item.field !== 'attachment' && item.field !== 'asset_category' ? (
                    // @ts-ignore
                    <p className="preview-value pointer">
                      {/* @ts-ignore */}
                      {data[item.field] && item.translate
                        ? //@ts-ignore
                        t(`Assets.CreateA.${data[item.field]}`)
                        : //@ts-ignore
                        data[item.field]
                          ? //@ts-ignore
                          data[item.field]
                          : '-'}
                    </p>
                  ) : null}
                  {item.field === 'attachment' ? (
                    <p className="preview-value pointer">
                      {/* @ts-ignore */}
                      {data[item.field] && item.translate
                        ? //@ts-ignore
                        t(`Assets.CreateA.${data[item.field]}`)
                        : //@ts-ignore
                        data[item.field]
                          ? //@ts-ignore
                          <img
                            src={`${localStorage.getItem('baseUrl')?.replace('/api', '')}${
                              //@ts-ignore
                              data[item.field]}`}
                            alt=""
                            style={{
                              borderRadius: '5px',
                            }}
                          />
                          : '-'}
                    </p>
                  ) : null}
                </div>
              ) : (
                ''
              )}
            </div>
          );
        })}
        <IonModal isOpen={showQr} onDidDismiss={() => setShowQr(false)}>
          <IonHeader style={{ marginBottom: '2rem' }}>
            <IonToolbar color="primary">
              <IonTitle style={{ display: 'flex', itemAlign: 'center' }}>
                <IonIcon src={barcodeSharp} /> <span>{data.asset_name}</span>
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="line-button"
                  onClick={() => {
                    setShowQr(false);
                  }}
                >
                  <IonIcon name="close" slot="icon-only"></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div className="flex justify-center">
              <div id="download-qr-code">
                <Barcode className="qr-code" style={{ margin: '1rem' }} value={//@ts-ignore
                  data.barcode_id || ""}
                  width={1} />
              </div>
            </div>
            <div className="flex justify-center">
              <button onClick={() => downloadQR()} className="download-qr">
                {t('Utils.download-qr')} <IonIcon src={download} />
              </button>
              <button onClick={() => window.print()} className="print-qr">
                {t('Utils.print-qr')} <IonIcon src={print} />
              </button>
            </div>
          </IonContent>
        </IonModal>

        {/* Timeline content  */}
        {timeline.map((item: any, index: number) => {
          return returnTimeline(item);
        })}
        <div style={{ height: '3rem' }}></div>
      </IonContent>
    </IonPage>
  );
};

export default AssetDet;
