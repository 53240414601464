import React, { useState, useEffect } from 'react';
import {
  IonContent,
  IonPage,
  IonItem,
  IonToolbar,
  IonSkeletonText,
  IonRefresher,
  IonRefresherContent,
  IonRow,
} from '@ionic/react';
import Header from '../../components/Header';
import CreateAsset from '../../assets/CreateAsset.svg';
import Clock from '../../assets/Clock.svg';
import AssetButton from '../../components/ScanButton';
import { RefresherEventDetail } from '@ionic/core';
import { chevronDownCircleOutline } from 'ionicons/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import NoRecords from '../../components/NoRecords';

const MaintenanceHome: React.FC = () => {
  const [data, setdata] = useState({
    dataSet: [],
  });

  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    const url = '/method/preflet_asset.api.get_assigned_maintenance?limit=5&start=0'

    var config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    axios(config)
      .then((res) => {
        var Data = { ...data };
        Data.dataSet = res?.data?.message?.maintenance_list;
        setdata(Data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    getData();
    setTimeout(() => {
      console.log('Async operation has ended');
      event.detail.complete();
    }, 2000);
  }

  const { t } = useTranslation();

  return (
    <IonPage>
      <Header enableBackButton='' title="" />
      <IonContent>
        <div style={{ display: 'flex', margin: 'auto', paddingTop: '2rem', marginLeft: '10vw', marginRight: '10vw' }}>
          <Link to="/createMainten1" style={{ textDecoration: 'none' }}>
            <section
              style={{
                width: '24vw',
                height: '7rem',
                marginLeft: '5px',
                marginRight: '5px',
                textAlign: 'center',
                boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
              }}
            >
              <img src={CreateAsset} alt="scan" style={{ paddingTop: '1rem' }} />
              <p style={{ margin: 'auto', color: 'rgba(29, 80, 141, 1)', marginTop: '2px', fontSize: '11px' }}>
                {t('Maintenance.Create')}
              </p>
            </section>
          </Link>

          <Link to="/maintenanceHistory" style={{ textDecoration: 'none' }}>
            <section
              style={{
                width: '24vw',
                height: '7rem',
                marginLeft: '5px',
                marginRight: '5px',
                textAlign: 'center',
                boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
              }}
            >
              <img src={Clock} alt="scan" style={{ paddingTop: '1rem' }} />
              <p style={{ margin: 'auto', color: 'rgba(29, 80, 141, 1)', marginTop: '15px', fontSize: '11px' }}>
                {t('Maintenance.History.History')}
              </p>
            </section>
          </Link>
        </div>

        <IonItem className="heading data-list" style={{ marginTop: '1rem' }}>
          <p style={{ marginBottom: '0.5rem' }}>{t('Maintenance.Recent')}</p>

        </IonItem>
        {isLoading &&
          [1, 2, 3, 4, 5].map((element, key) => {
            return (
              <div key={key} style={{ padding: '1rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '60%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '30%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
              </div>
            );
          })}
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={t('Utils.pullRefresh')}
            refreshingSpinner="circles"
            refreshingText={t('Utils.refreshing')}
          ></IonRefresherContent>
        </IonRefresher>
        {data.dataSet.length > 0 &&
          data.dataSet.map((item: any, key) => {
            return (
              <div
                key={key}
                className="ion-margin data-list"
                style={{ boxShadow: '2px 8px 48px -16px rgba(21, 70, 160, 0.1)', borderRadius: '20px' }}
              >

                <section style={{ display: 'flex' }}>
                  <section style={{ flex: 'column' }}>
                    <h4
                      style={{
                        marginLeft: '0.4rem',
                        marginTop: '1.3rem',
                        color: 'rgba(29, 80, 141, 1)',
                        fontSize: 'large',
                      }}
                    >
                      {item.name}
                    </h4>
                    <p
                      style={{
                        marginLeft: '0.5rem',
                        color: 'rgba(146, 155, 171, 1)',
                        marginTop: '-0.5rem',
                        fontSize: '11px',
                        maxWidth: '200px',
                        wordWrap: 'break-word',
                      }}
                    >
                      {t('Assets.Details.AID')} : {item.asset}
                    </p>
                  </section>
                </section>

                <section style={{ flex: 'column', paddingBottom: '10px' }}>
                  <Link to={{ pathname: '/maintainanceDetail', state: item.name, search: 'back=maintenanceHome' }}>
                    <p
                      style={{
                        color: 'rgba(29, 80, 141, 1)',
                        float: 'right',
                        fontSize: '13px',
                        flex: 'column',
                        marginTop: '-2.9rem',
                        paddingRight: '1rem',
                      }}
                    >
                      {t('Assets.Details.Details')}
                    </p>
                  </Link>

                  <section style={{ display: 'flex', float: 'right' }}>
                    {/* <img src={red} alt="Red" style={{ marginTop: '-2.2rem', marginRight: '5px' }} /> */}
                    <p
                      style={{
                        color: 'rgba(146, 155, 171, 1)',
                        float: 'right',
                        marginTop: '-1.5rem',
                        fontSize: '10px',
                        paddingRight: '1rem',
                      }}
                    >
                      {item.men_status}
                    </p>
                  </section>
                  {item.target_date && (new Date(item.target_date).toLocaleDateString() < new Date().toLocaleDateString() ?
                    <div className='overdue'>overdue</div> : '')}
                </section>
              </div>
            );
          })}
        {data && data.dataSet.length > 0 ? <Link to="/maintenanceHistory" style={{ margin: 'auto', display: 'block' }} className="text-center underline pointer">{t('Utils.viewAll')}</Link> : ''}

        <IonToolbar></IonToolbar>
        {!isLoading && data.dataSet.length === 0 ? (
          <IonRow>
            <NoRecords width="30%" smallText={true} />
          </IonRow>
        ) : (
          <div></div>
        )}

        <AssetButton />
      </IonContent>
    </IonPage>
  );
};

export default MaintenanceHome;
