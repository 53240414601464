import { IonContent, IonPage, useIonRouter, withIonLifeCycle } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Device } from '@capacitor/device';

import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import QrReader from 'react-qr-reader';
import Close from '../../assets/Close.svg';

const Home: React.FC = () => {
  const [platform, setPlatform] = useState('');
  const [result, setResult] = useState('No result');
  const router = useIonRouter();

  useEffect(() => {
    (async () => {
      const info = await Device.getInfo();
      setPlatform(info.platform);

      if (info.operatingSystem === 'unknown') {
        alert('App is not supported!');
      }
    })();
  }, []);

  const back = () => {
    router.push('/createAsset');
  };

  const handleScan = (data: any) => {
    if (data) {
      setResult(data);
    }
  };

  const handleError = (err: any) => {
    console.error(err);
  };

  const startScan = async () => {
    BarcodeScanner.hideBackground();
    const result = await BarcodeScanner.startScan();
    if (result.hasContent) {
      console.log(result.content);
    }
  };

  return (
    <IonPage>
      <IonContent>
        <div id="qr-scanner">
          <QrReader delay={300} onError={handleError} onScan={handleScan} style={{ width: '100%' }} />
          <img
            onClick={back}
            src={Close}
            alt="sync"
            style={{ position: 'absolute', top: '20px', right: '25px', width: '20px', zIndex: 999 }}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default withIonLifeCycle(Home);
