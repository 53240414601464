/* eslint-disable array-callback-return */
import {
  IonContent,
  IonPage,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import axios from '../../axios';

import { createTaskField } from '../../models/createTaskFields';

const ViewTask: React.FC = () => {
  const [data, setData] = useState([]);
  const options = ["Open",
    "Working",
    "Pending Review",
    "Overdue",
    "Template",
    "Completed",
    "Cancelled"];
  const { t } = useTranslation();

  const history = useHistory();
  const getData = async () => {
    var res: any = history.location.state;
    try {
      const response = await axios({
        method: 'get',
        url: `/resource/Task/${res}`,
        headers: {
          Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
        },
      })
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const filterByStatus = async(status:any) => {
  //   try {
  //     const temp = await axios({
  //       method: 'get',
  //       url: `/method/preflet_asset.api.get_task_list?id=${name}&status=${status}`,
  //       headers: {
  //         Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
  //       },
  //     })
  //     console.log(temp);
  //     setTask(temp.data.message);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // const updateStatus =  async(status:any,item: any) => {
  //   const url = `/method/preflet_asset.api.get_task_list/${item.name}`;

  //   var config: any = {
  //     method: 'put',
  //     url,
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Accept: 'application/json',
  //       Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
  //     },
  //     data: {
  //       status: status,
  //     },
  //   };
  //   try {
  //     const res = await axios(config)
  //     console.log(res);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  return (
    <IonPage>
      <Header enableBackButton='goBack' title={t('Task.details')} />
      <IonContent>
        <div className="preview-header">
          <p className="preview-title" style={{ width: '90%' }}>{
            // @ts-ignore
            data['name']}</p>
        </div>
        {createTaskField.map((item: any, index) => {
          if (item.title) {
            return (
              <p key={index} className="form-title">
                {t(item.title)}
              </p>
            );
          }
          return (
            <div key={index} className="data-list">
              {item.field && item.field !== 'task_assign_user' ? (
                <div className="preview-container">
                  <p className="preview-key">{t(`Task.${item.field}`)}</p>
                  <p className="preview-value">
                    {
                      // @ts-ignore
                      item.field ? data[item.field] : '-'
                    }
                  </p>
                </div>
              ) : (
                ''
              )}
              {item.field && item.field === 'task_assign_user' ? (
                <div className="preview-container">
                  <p className="preview-key">{t(`Task.${item.field}`)}</p>
                  <p className="preview-value">
                    {
                      //@ts-ignore
                      data[item.field]?.length > 0 ? data[item.field].map((item: any) => {
                        return item.user;
                      }).join(', ') : '-'
                    }
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
          );
        })}
      </IonContent>
    </IonPage>
  );
};

export default ViewTask;
