/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import {
  IonContent,
  IonPage,
  IonItem,
  IonSkeletonText,
  IonRefresher,
  IonRefresherContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
} from '@ionic/react';
import Header from '../../components/Header';
import CreateAsset from '../../assets/CreateAsset.svg';
import { RefresherEventDetail } from '@ionic/core';
import { chevronDownCircleOutline, rocketOutline } from 'ionicons/icons';
import Clock from '../../assets/Clock.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import { useHistory } from 'react-router';
import { createServiceFields, updateServiceRequest } from '../../models/createServiceFields';

const ModelHome: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setdata] = useState({
    dataSet: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [_data] = useState(createServiceFields);


  const getData = () => {
    let url = `/method/preflet_asset.apis.model937.routes.get_model937?limit=5&start=0`;
    var config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    axios(config)
      .then((res) => {
        console.log(res);
        var Data = { ...data };
        Data.dataSet = res?.data?.message;
        setdata(Data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [history?.location?.state]);

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    getData();
    setTimeout(() => {
      event.detail.complete();
    }, 2000);
  }

  return (
    <IonPage>
      <Header enableBackButton="" title="" />
      <IonContent>
        <div style={{ display: 'flex', margin: 'auto', paddingTop: '2rem', marginLeft: '10vw', marginRight: '10vw' }}>
          <Link to="./create-model" style={{ textDecoration: 'none' }}>
            <section
              style={{
                width: '25vw',
                height: '7rem',
                marginLeft: '5px',
                marginRight: '5px',
                textAlign: 'center',
                boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
              }}
            >
              <img src={CreateAsset} alt="scan" style={{ paddingTop: '1rem' }} />
              <p style={{ margin: 'auto', color: 'rgba(29, 80, 141, 1)', marginTop: '2px', fontSize: '11px' }}>
                {t('Model937.createForm')}
              </p>
            </section>
          </Link>

          <Link to="./model-history" style={{ textDecoration: 'none' }}>
            <section
              style={{
                width: '25vw',
                height: '7rem',
                marginLeft: '5px',
                marginRight: '5px',
                textAlign: 'center',
                boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
              }}
            >
              <img src={Clock} alt="scan" style={{ paddingTop: '1rem' }} />
              <p style={{ margin: 'auto', color: 'rgba(29, 80, 141, 1)', marginTop: '15px', fontSize: '11px' }}>
                {t('Model937.history')}
              </p>
            </section>
          </Link>
        </div>

        <IonItem className="heading data-list" style={{ marginTop: '1rem' }}>
          <p style={{ marginBottom: '0.5rem' }}>{t('Model937.recent')}</p>
        </IonItem>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={t('Utils.pullRefresh')}
            refreshingSpinner="circles"
            refreshingText={t('Utils.refreshing')}
          ></IonRefresherContent>
        </IonRefresher>
        {isLoading &&
          [1, 2, 3, 4, 5].map((element, key) => {
            return (
              <div key={key} style={{ padding: '1rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '60%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '30%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
              </div>
            );
          })}
        {data.dataSet.length > 0 && data.dataSet.map((item: any, key) => {
          return (
            <IonCard id="card-container" key={key}>
              <IonCardHeader>
                <IonCardTitle className="card-heading">
                  <div className="flex justify-between items-center">
                    <span>{item.nome || item.name}</span>
                    <Link to={{ pathname: '/model-detail', state: item.name, search: 'back=model-home' }}>
                      <p
                        style={{
                          color: 'rgba(29, 80, 141, 1)',
                        }}
                      >
                        {t('Model937.viewDetails')}
                      </p>
                    </Link>
                  </div>
                </IonCardTitle>
                <IonCardSubtitle>
                  {t('Assets.Details.AID')} : {item.asset}
                </IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          );
        })}
         {data && data.dataSet.length > 0 ? <Link to="/model-history" style={{margin:'auto',display: 'block', marginBottom: '1rem'}} className="text-center underline pointer">{t('Utils.viewAll')}</Link> : ''}
      </IonContent>
    </IonPage>
  );
};
export default ModelHome;
