import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppContext } from '../providers/AppContext';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { state } = useContext(AppContext);
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to / page
    <Route {...rest} render={(props) => (state.isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />)} />
  );
};

export default PrivateRoute;
